import React, { FunctionComponent, useState } from 'react'
import { Link } from 'react-router-dom'
import firebase from '../firebase'
import moment from 'moment'
import Checkbox from './Checkbox'
import DeleteButton from './DeleteButton'
import { Eye, Pen } from '../icons'
import { formatAddress } from '../helpers/global';
import Order from '../interfaces/Order'

const deleteOrder = firebase.functions().httpsCallable('deleteOrder')

type OrdersTableProps = {
  entries: Order[],
  loading: boolean,
  onDeleteStart: any,
  onDeleteSuccess: any,
  onDeleteError: any,
  onChangeSelection: any
}

const OrdersTable: FunctionComponent<OrdersTableProps> = ({ entries, loading, onDeleteStart, onDeleteSuccess, onDeleteError, onChangeSelection }: OrdersTableProps) => {
	const [selectedEntries, setSelectedEntries] = useState<string[]>([]);

	return <table className="table">
		<thead>
			<tr>
				<th className="cell-checkbox">
					<Checkbox 
            checked={ selectedEntries.length === entries.length }
            onChange={() => {
            	const selection: string[] = selectedEntries.length === entries.length ? [] : entries.map(entry => entry.id);
            	setSelectedEntries(selection);
            	onChangeSelection(selection);
            }}
          />
				</th>
				<th>Order #</th>
				<th>Created</th>
				<th>Name</th>
				<th>Email</th>
				<th>Address</th>
				<th>Size</th>
				<th>Qty</th>
				<th>Price</th>
				<th>Code</th>
				<th>Printer</th>
				<th className="cell-action">Actions</th>
			</tr>
		</thead>
		<tbody>
			{
				entries.map(entry => <tr key={ entry.id }>
						<td className="cell-checkbox">
							<Checkbox 
                checked={ selectedEntries.indexOf(entry.id) !== -1 }
                onChange={() => {
                  if (selectedEntries.indexOf(entry.id) === -1) {
                    selectedEntries.push(entry.id);
                  } else {
                    selectedEntries.splice(selectedEntries.indexOf(entry.id), 1);
                  }

                  const selection = selectedEntries.map(entry => entry);
                  setSelectedEntries(selection);
                  onChangeSelection(selection);
                }}
              />
						</td>
						<td>
              <Link to={ '/admin/orders/' + entry.id } className="text-button nowrap">
              	{ entry.id }
            	</Link>
            </td>
						<td>
							{ entry.processedAt ? moment((entry.processedAt.seconds ? entry.processedAt.seconds : entry.processedAt._seconds) * 1000).format('MM/DD/YY') : moment((entry.updatedAt.seconds ? entry.updatedAt.seconds : entry.updatedAt._seconds) * 1000).format('MM/DD/YY') }
						</td>
						<td>
							{ entry.fullName }
						</td>
						<td>
							{ entry.email }
						</td>
						<td>
							{ formatAddress(entry.address) }
						</td>
						<td>
							{ entry.tileSize }
						</td>
						<td>
							{ entry.imageCollections.map(collection => collection.images.length).reduce((a, b) => a + b, 0) }
						</td>
						<td>
							{ entry.price ? ('$' + entry.price.total / 100) : '' }
						</td>
						<td></td>
						<td>
							{ ['imagesSent', 'shipped'].indexOf(entry.state) !== -1 ? 'Yes' : 'No' }
						</td>
						<td className="cell-action">
							<Link to={ '/order-confirmation/' + entry.id + '/' + entry.token } className="text-button">
                <Eye />
							</Link>
							<Link to={ '/admin/orders/' + entry.id } className="text-button">
                <Pen />
							</Link>
							<DeleteButton onClick={() => {
								onDeleteStart();
                deleteOrder({ id: entry.id }).then(result => {
                	onDeleteSuccess();
                }).catch(error => {
                  alert(JSON.stringify(error));
                  onDeleteError();
                });
							}} />
						</td>
					</tr>
			)}
			{
        !loading && entries.length === 0
        ? <tr>
          <td colSpan={11}>
            <em>No orders yet...</em>
          </td>
        </tr>
        : null
      }
		</tbody>
	</table>
}

OrdersTable.defaultProps = {
	loading: false
}

export default OrdersTable
