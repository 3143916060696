import React, { Component } from 'react'
import firebase from '../../firebase'
import { isUserAdmin, redirectToLogin, uploadFile } from '../../firebaseHelpers'
import { Link } from 'react-router-dom'
import PhotoGrid from '../../components/PhotoGrid'
import LoadingWheel from '../../components/LoadingWheel'
import DragAndDropUploader from '../../components/DragAndDropUploader'
import { Back } from '../../icons'

const createOrganization = firebase.functions().httpsCallable('createOrganization');
const modifyOrganization = firebase.functions().httpsCallable('modifyOrganization');
const addLogoImageToOrganization = firebase.functions().httpsCallable('addLogoImageToOrganization');
const removeLogoImageFromOrganization = firebase.functions().httpsCallable('removeLogoImageFromOrganization');
const getOrganizationLogoImageDownloadURLs = firebase.functions().httpsCallable('getOrganizationLogoImageDownloadURLs');

export default class AdminOrganizationScreen extends Component {
	constructor() {
  	super();

  	this.state = {
      isEdit: false,
      loading: false,
      showUploadBox: false,
      currentEntryTitle: '',

      form: {
        businessName: '',
        domains: ''
      },

  		logos: []
  	};
  }

  componentDidMount = async () => {
    const isAdmin = await isUserAdmin();
    const { match } = this.props;

    if (isAdmin) {
      if (match.params.id !== 'new') {
        this.setState({
          isEdit: match.params.id,
          loading: true 
        });

        this.loadEntry();
      }
    } else {
      redirectToLogin(this.props.history);
    }
  }

  loadEntry = () => {
    const { match } = this.props;

    firebase.firestore().collection('organizations').where('__name__', '==' , match.params.id).get().then(results => {
      results.forEach(result => {
        const data = Object.assign({}, result.data(), { id: result.id });

        this.setState({
          currentEntryTitle: data.businessName,
          form: {
            businessName: data.businessName,
            domains: data.domainsLowercase.join(', ')
          }
        });

        getOrganizationLogoImageDownloadURLs({ id: data.id }).then(results => {
          const logos = [];

          for (let i in results.data) {
            logos.push({
              id: i,
              url: results.data[i]
            });
          }

          this.setState({ 
            logos,
            loading: false 
          });
        }).catch(error => {
          this.setState({ loading: false });
          alert(error.message);
        });
      });
    }).catch(error => {
      alert(JSON.stringify(error));
      this.setState({
        loading: false
      });
    });
  }

  onUpdateForm = event => {
    const { form } = this.state;
    const { target } = event;
    form[target.name] = target.value;
    this.setState({ form })
  }

  onSave = () => {
    const { form } = this.state;

    this.setState({ loading: true });

    createOrganization({
      businessName: form.businessName,
      domains: form.domains.replace(/\s/g, '').split(',').filter(domain => domain.trim())
    }).then(result => {
      this.setState({ loading: false });
      this.props.history.push('/admin/organizations');
    }).catch(error => {
      this.setState({ loading: false });
      alert(error.message);
    });
  }

  onUpdate = () => {
    const { form, isEdit } = this.state;

    this.setState({ loading: true });

    modifyOrganization({
      id: isEdit,
      businessName: form.businessName,
      domains: form.domains.replace(/\s/g, '').split(',').filter(domain => domain.trim())
    }).then(result => {
      this.setState({ loading: false });
      this.props.history.push('/admin/organizations');
    }).catch(error => {
      this.setState({ loading: false });
      alert(error.message);
    });
  }

  onUpload = files => {
    const { isEdit } = this.state;
    this.setState({ loading: true });

    for (let i in files) {
      const file = files[i];

      addLogoImageToOrganization({
        id: isEdit,
        name: file.lastModified + '_' + file.name
      }).then(result => {
        uploadFile(result.data.uploadURL, file).then(response => {
          this.setState({ loading: false });
          window.location.reload();
        }).catch(error => {
          alert(JSON.stringify(error));
        });
      }).catch(error => {
        this.setState({ loading: false });
        alert(error.message);
      });
    }
  }

  onAddMore = () => {
    const { isEdit, showUploadBox } = this.state;

    if (!isEdit) {
      alert('Please save the organization first');
    } else {
      this.setState({ showUploadBox: !showUploadBox })
    }
  }

  render() {
    const { logos, isEdit, loading, showUploadBox, form, currentEntryTitle } = this.state;

  	return (
    	<div className="panel-screen">
    		<div className="panel-main justify-top">
    			<div className="table-wrapper">
    				<div className="admin-action">
    					<Link to="/admin/organizations" className="text-button">
    						<Back />
    						Back
  						</Link>
    					<div className="admin-action-buttons">
    						<Link to="/admin/organizations" className="sp-button bordered faded">Cancel</Link>
                {
                  isEdit
                  ? <button className="sp-button" onClick={ this.onUpdate }>Save</button>
                  : <button className="sp-button" onClick={ this.onSave }>Save</button>
                }
    					</div>
    				</div>

            <div className="section-entity">
              {
                isEdit
                ? <>
                  ORGANIZATION: <strong>{ currentEntryTitle }</strong>
                </>
                : <>NEW ORGANIZATION</>
              }
            </div>

    				<div className="form-section form-section-cols">
	            <div className="formfield">
	            	<label>Business name</label>
	            	<input type="text" value={ form.businessName } name="businessName" onChange={ this.onUpdateForm } />
	            </div>
	            <div className="formfield label-aligned">
	            	<label>Domains</label>
	            	<div className="input-holder">
	            		<input type="text" value={ form.domains } name="domains" onChange={ this.onUpdateForm } />
	            		<div className="input-note">Please separate domains by comma</div>
            		</div>
	            </div>
            </div>

            <div className="gallery-holder">
              <div className="subtitle-bar">
                <div className="section-subtitle">LOGO IMAGE</div>
                <button className="sp-button bordered blue" onClick={ this.onAddMore }>
                  {
                    showUploadBox
                    ? 'Cancel'
                    : '+ Add More'
                  }
                </button>
              </div>

              {
                showUploadBox
                ? <DragAndDropUploader 
                  isStatic={true} 
                  onUpload={this.onUpload}
                />
                : null
              }

              <PhotoGrid 
                images={logos} 
                showLink={true}
                showDeleteButton={true}
                onDelete={logo => {
                  const { isEdit } = this.state;

                  this.setState({ loading: true });

                  removeLogoImageFromOrganization({
                    id: isEdit,
                    imageId: logo.id
                  }).then(result => {
                    this.loadEntry();
                  }).catch(error => {
                    this.setState({ loading: false });
                    alert(error.message);
                  });
                }}
              />
            </div>

            {
              loading
              ? <LoadingWheel />
              : null
            }
    			</div>
    		</div>
			</div>
		)
  }
}
