import React, { Component } from 'react'
import EditorContext from '../contexts/EditorContext'

class GeneratorScreen extends Component {
  constructor() {
    super();

    this.state = {
      preview: ''
    };
  }

  render() {
  	const { preview } = this.state;

  	return (    	
      <EditorContext.Consumer>
        {context => {
          return (
            <div className="panel-screen">
              <div className="panel-main">
                <div className="editor-frame">
                  <div className="editor-image no-mask">
                  	{
                  		preview
                  		? <img className="static-image" src={preview} alt="Gallery item" />
                  		: null
                  	}
                  </div>
                </div>
                <button className="sp-button" onClick={async () => {
            			const { match } = this.props;
            			const selectedImage = context.images.find(image => image.id === match.params.id);
            			const { url } = await context.generateImage(selectedImage);

            			this.setState({ preview: url });
            		}}>Generate preview</button>
              </div>
            </div>
          )
        }}
      </EditorContext.Consumer>
    )
  }
}

GeneratorScreen.contextType = EditorContext;

export default GeneratorScreen;
