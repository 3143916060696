import React, { Component } from 'react';
import icon from '../img/sp-icon.png';

export default class IosRedirect extends Component {
	render() {
		const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

		if (!iOS)
			return null;

		return (
			<div className="ios-redirect">
				<div className="inside-wrapper">
					<figure>
						<img src={icon} alt="SweetPix" />
					</figure>
					<h3>Use our iOS app</h3>
					<p>
						We've noticed you are using an iPhone. 
						Please download our iOS app in the App Store
						if you would like to place an order using your device.
					</p>
					<p>
						For orders over 40 tiles please use 
						our website via a desktop computer.
					</p>
					<a href="https://apps.apple.com/us/app/sweet-pix-photo-tiles/id839262104" className="action-button size-x">DOWNLOAD APP</a>
					<a href="https://sweetpixapp.com" className="action-button inverted size-x">NO THANKS</a>
				</div>
			</div>
		)
  	}
}
