import React, { Component } from 'react'
import moment from 'moment'
import firebase from '../../firebase'
import { isUserAdmin, redirectToLogin, fetchImageCollections } from '../../firebaseHelpers'
import SearchBox from '../../components/SearchBox'
import Checkbox from '../../components/Checkbox'
import LoadingWheel from '../../components/LoadingWheel'
import DeleteButton from '../../components/DeleteButton'
import { Eye, Pen } from '../../icons'
import { Link } from 'react-router-dom'

const searchImageCollections = firebase.functions().httpsCallable('searchImageCollections');
const deleteImageCollection = firebase.functions().httpsCallable('deleteImageCollection');
const deleteImageCollections = firebase.functions().httpsCallable('deleteImageCollections');

export default class AdminImageCollectionsScreen extends Component {
  constructor() {
  	super();

  	this.state = { 
  		entries: [],
      loading: false,
      search: '',
      selectedEntries: []
  	};
  }

  componentDidMount = async () => {
    const isAdmin = await isUserAdmin();

    if (isAdmin) {
      this.loadEntries();
    } else {
      redirectToLogin(this.props.history);
    }
  }

  loadEntries() {
    const { search } = this.state;

    this.setState({ loading: true });

    if (search.trim()) {
      searchImageCollections({ search }).then(({data}) => {
        this.setState({ entries: data, loading: false });
      }).catch(error => {
        this.setState({ loading: false });
        alert(JSON.stringify(error));
      });
    } else {
      fetchImageCollections().then(results => {
        const entries = [];
        results.forEach(result => {
            entries.push(Object.assign({}, result.data(), { id: result.id }));
        });
        this.setState({ entries, loading: false });
      }).catch(error => {
        this.setState({ loading: false });
        alert(JSON.stringify(error));
      });
    }
  }

  render() {
  	const { entries, search, loading, selectedEntries } = this.state;

    return (
    	<div className="panel-screen">
    		<div className="panel-main justify-top">
    			<div className="table-wrapper">
            <div className="title-bar">
              <div className="section-title">Image Collections</div>
              <SearchBox 
                value={ search }
                onChange={search => this.setState({ search })}
                onSubmit={() => this.loadEntries()}
              />
              <div className="title-bar-buttons">
                <button 
                  className="sp-button bordered faded" 
                  onClick={() => {
                    if (selectedEntries.length === 0) {
                      alert('Please select at least one row');
                    } else {
                      this.setState({ loading: true });
                      deleteImageCollections({ ids: selectedEntries }).then(result => {
                        this.loadEntries();
                      }).catch(error => {
                        alert(JSON.stringify(error));
                      });
                    }
                  }}
                >Delete</button>
                <Link className="sp-button bordered blue" to="/admin/image-collections/new">Add New</Link>
              </div>
            </div>

    				<table className="table">
    					<thead>
    						<tr>
	    						<th className="cell-checkbox">
	    							<Checkbox 
                      checked={ selectedEntries.length === entries.length }
                      onChange={() => {
                        this.setState({ selectedEntries: selectedEntries.length === entries.length ? [] : entries.map(entry => entry.id) });
                      }}
                    />
	    						</th>
	    						<th>Name</th>
	    						<th>Qty</th>
	    						<th>Created</th>
	    						<th className="cell-action">Actions</th>
    						</tr>
    					</thead>
    					<tbody>
    						{
    							entries.map(entry => <tr key={entry.id}>
    									<td className="cell-checkbox">
    										<Checkbox 
                          checked={ selectedEntries.indexOf(entry.id) !== -1 }
                          onChange={() => {
                            if (selectedEntries.indexOf(entry.id) === -1) {
                              selectedEntries.push(entry.id);
                            } else {
                              selectedEntries.splice(selectedEntries.indexOf(entry.id), 1);
                            }

                            this.setState({ selectedEntries });
                          }}
                        />
    									</td>
    									<td>
                        <Link to={ '/admin/image-collections/' + entry.id } className="text-button">
                          { entry.name }
                        </Link>
    									</td>
    									<td>{ entry.images.length }</td>
                      <td>{ moment(entry.createdAt.seconds * 1000).format('MM/DD/YY') }</td>
    									<td className="cell-action">
    										<Link to={ '/admin/image-collections/' + entry.id } className="text-button">
                          <Eye />
  											</Link>
  											<Link to={ '/admin/image-collections/' + entry.id } className="text-button">
                          <Pen />
												</Link>
												<DeleteButton onClick={() => {
                          this.setState({ loading: true });
                          deleteImageCollection({ id: entry.id }).then(result => {
                            this.loadEntries();
                          }).catch(error => {
                            alert(JSON.stringify(error));
                          });
                        }} />
    									</td>
    								</tr>
    						)}

                {
                  !loading && entries.length === 0
                  ? <tr>
                    <td colSpan="5">
                      <em>No image collections yet...</em>
                    </td>
                  </tr>
                  : null
                }
    					</tbody>
    				</table>
    			</div>
    		</div>

        {
          loading
          ? <LoadingWheel />
          : null
        }
			</div>
		)
  }
}
