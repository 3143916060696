import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import LearnMoreModal from './LearnMoreModal'
import EditorContext from '../contexts/EditorContext'
import LoadingWheel from './LoadingWheel'
import photoTilesCollectionMenuIcon from '../img/BTOVyMMrcpspRAmGJLCF'
import nameBadgeTilesCollectionMenuIcon from '../img/4j8gDXusidn0CYgCvICo'
import { APP_VERSION } from '../config.js'

export default function CollectionsSideBar({ onToggleAuthModal }) {
	const { pathname } = useLocation();
	const history = useHistory();

	if (['/', '/cart'].indexOf(pathname) === -1 && pathname.indexOf('/editor') === -1 && pathname.indexOf('/gallery') === -1) {
		return null;
	}

  return <aside className="side-collections">
		<EditorContext.Consumer>
			{context => (
				<>
					<ul className="collections-list">
  					{
							context.collections.map(collection => <li key={collection.menuName} className={ context.selectedCollection && collection.id === context.selectedCollection ? 'active' : '' } onClick={() => {
								const image = context.images.find(image => !image.approved && image.collectionId === collection.id);

								if (image && context.isOrderSpecificCollection(collection.id)) {
									context.setState({ 
                    selectedCollection: image.collectionId,
                    selectedImage: image.id 
                  });
                  history.push('/editor/' + image.collectionId + '/' + image.id);
								} else {
									context.setState({selectedCollection: collection.id});
									history.push('/gallery/' + collection.id);	
								}
							}}>
								<figure>
									{
										collection.menuIconDownloadURL && collection.loaded
										? <img src={ collection.menuIconDownloadURL } alt="Collection icon" />
										: <>
											{
												context.isPhotoTilesCollection(collection.id)
												? <img src={ photoTilesCollectionMenuIcon } alt="Collection icon" />
												: null
											}
											{
												context.isNameBadgeTilesCollection(collection.id)
												? <img src={ nameBadgeTilesCollectionMenuIcon } alt="Collection icon" />
												: null
											}
										</>
									}
									<span className="bubble">
										{ context.getCollectionCartImagesCount(collection.id) }
									</span>
								</figure>
								<div dangerouslySetInnerHTML={{ __html: collection.menuName.replace(' ', '<br/>') }}></div>
							</li>)
			  		}

			  		{
							context.loadingSidebarCollections
							? <li>
								<LoadingWheel inline={true} />
							</li>
							: null
						}
	  			</ul>

			  	<ul className="links-list">
			  		<li>
			  			<a href="mailto:support@sweetpixapp.com">Help</a>
						</li>
			  		<li className="blue">
			  			<span onClick={() => context.setState({ showLearnMoreModal: true })}>How to</span>
						</li>
						<li>
							<small>v.{ APP_VERSION }</small>
						</li>
			  	</ul>

			  	{
            context.showLearnMoreModal
            ? <LearnMoreModal />
            : null
          }
		  	</>
  		)}
		</EditorContext.Consumer>
  </aside>;
}
