import React, { useState } from 'react'
import classNames from 'classnames'
import { useLocation, useHistory } from 'react-router-dom'
import EditorContext from '../contexts/EditorContext'
import Switcher from './Switcher'
import Combobox from './Combobox'
import Checkbox from './Checkbox'
import Colorbox from './Colorbox'
import EditorPreview from './EditorPreview';
import DeleteImageConfirmation from './DeleteImageConfirmation';
import LoadingWheel from './LoadingWheel'
import { Copy, TrashGray } from '../icons';
import iconCheckmark from '../img/icon-checkmark-disc.png'

export default function ConfiguratorSideBar({ onToggleAuthModal }) {
	const [imageForDeleteId, setImageForDeleteId] = useState('');
	const location = useLocation();
	const history = useHistory();

  if (location.pathname !== '/' && location.pathname.indexOf('/gallery') === -1 && location.pathname.indexOf('/editor') === -1) {
		return null;
	}

	return <aside className="side-configurator">
		<EditorContext.Consumer>
			{context => {
				const galleryImages = context.images.filter(image => context.selectedCollection && image.collectionId === context.selectedCollection);
				const collectionSettings = context.selectedCollection && context.collectionsSettings[context.selectedCollection] ? context.collectionsSettings[context.selectedCollection] : {};
				const collectionImageStyle = collectionSettings.imageStyle ? collectionSettings.imageStyle : (context.isNameBadgeTilesCollection(context.selectedCollection) ? 'grayscale(100%)' : 'none');
				const isNameBadgeTilesCollection = context.isNameBadgeTilesCollection(context.selectedCollection);

				return (
					<>
						{
							!context.isOrderSpecificCollection(context.selectedCollection)
							? null
							: <div className="inner-box">
								<div className="title-line">
									<div className="panel-title">
						  			{ !isNameBadgeTilesCollection ? 'IMAGE SETTINGS' : 'NAME BADGE SETTINGS' }
						  		</div>

						  		{
						  			isNameBadgeTilesCollection
						  			? <div className="sp-formfield">
							  			<Switcher 
							  				isActive={context.showSettings} 
							  				onToggle={() => context.setState({showSettings: !context.showSettings}, true)}
						  				/>
							  		</div>
							  		: null
						  		}
					  		</div>

					  		<div className="sp-formfield">
						  		<label className="field-label">IMAGE STYLE</label>
						  		<Combobox 
						  			value={collectionImageStyle}
						  			options={[
						  				{key: 'none', value: 'Full Color'}, 
						  				{key: 'grayscale(100%)', value: 'Black & White'}
					  				]} 
					  				customUI={true}
					  				onChange={({target}) => {
					  					const collectionsSettings = context.collectionsSettings;
					  					const images = context.images;
					  					
					  					if (typeof collectionsSettings[context.selectedCollection] === 'undefined') {
					  						collectionsSettings[context.selectedCollection] = {};
					  					}
					  					collectionsSettings[context.selectedCollection].imageStyle = target.value;
					  					
					  					for (let i in images) {
					  						if (images[i].collectionId === context.selectedCollection) {
				  								images[i].imageStyle = target.value;
					  						}
					  					}

					  					context.setState({
					  						images,
					  						collectionsSettings
					  					}, true);
					  				}}
					  			/>
					  		</div>

								{
									isNameBadgeTilesCollection && context.showSettings
									?	<>
							  		<div className="sp-formfield mb-s">
								  		<label className="field-label">TEXT &amp; COLOR</label>
								  		{/*key: 'Open Sans Condensed:700', value: 'Open Sans Condensed Bold'*/}
							  			{/*key: 'Lato:700', value: 'Lato Bold'*/}
								  		<Combobox 
								  			value={context.fontFamily}
								  			options={[		
									  			{key: 'Libre Baskerville:400', value: 'Libre Baskerville Regular'},
									  			{key: 'HelveticaNeueLTStd-BdCn:700', value: 'Helvetica Neue Bold 77'}
								  			]} 
								  			customUI={true}
								  			onChange={event => context.setState({fontFamily: event.target.value}, true)}
							  			/>
							  		</div>

							  		<div className="sp-formfield">
								  		<div className="sp-cols sp-cols-2">
									  		<div>
										  		<Colorbox 
										  			color={ context.color }
										  			onChange={ color => context.setState({color: color.hex}, true) } 
										  		/>
									  		</div>
									  		<div>
										  		<Combobox 
										  			value={context.fontSize}
										  			options={[12,14,16,18,20,22,24,26,28,30,31].map(value => {
										  				return { key: value, value: value }
										  			})} 
										  			customUI={true}
										  			onChange={event => context.setState({fontSize: event.target.value}, true)}
										  		/>
									  		</div>
								  		</div>

								  		<div className="sp-cols sp-cols-2 fw-mob">
									  		<div>
										  		<Checkbox 
										  			checked={ context.textTransform === 'uppercase' }
										  			label="Uppercase" 
										  			onChange={event => {
										  				if (event.target.checked) {
										  					context.setState({textTransform: 'uppercase'}, true)
										  				} else {
										  					context.setState({textTransform: 'none'}, true)
										  				}
										  			}}
									  			/>
									  		</div>
									  		<div>
										  		<Checkbox 
										  			checked={ context.textTransform === 'lowercase' }
										  			label="Lowercase" 
										  			onChange={event => {
										  				if (event.target.checked) {
										  					context.setState({textTransform: 'lowercase'}, true)
										  				} else {
										  					context.setState({textTransform: 'none'}, true)
										  				}
										  			}}
									  			/>
									  		</div>
								  		</div>
							  		</div>
							  		
							  		<div>
								  		<label className="field-label">BADGE STYLE &amp; POSITION</label>
								  		<div className="sp-cols sp-cols-2">
								  			<div>
										  		<Colorbox 
										  			color={ context.backgroundColor }
										  			onChange={ color => context.setState({backgroundColor: color.hex}, true) } 
										  		/>
									  		</div>
												<div>
								  				<Combobox 
								  					value={context.align}
								  					options={[
											  			{key: 'left', value: 'Left'}, 
											  			{key: 'center', value: 'Center'},
											  			{key: 'right', value: 'Right'}
										  			]}
										  			customUI={true}
										  			onChange={event => context.setState({align: event.target.value}, true)} 
									  			/>
												</div>
							  			</div>
							  			<Checkbox 
							  				checked={context.extended}
							  				onChange={event => context.setState({extended: event.target.checked}, true) }
							  				label="Extend to edge" 
						  				/>
							  		</div>
						  		</>
									: null
								}
							</div>
						}

		  			<div className="grid-head">
				  		<div>
				  			Image count: { context.getCollectionCartImagesCount(context.selectedCollection) }
			  			</div>

			  			{
			  				context.isOrderSpecificCollection(context.selectedCollection)
			  				? <div 
			  					className="text-button" 
			  					onClick={() => {
			  						if (context.isNameBadgeTilesCollection(context.selectedCollection) && !context.isUserConnected) {
			  							onToggleAuthModal();
			  						} else {
			  							context.setState({ showUploadModal: true })	
			  						}
			  					}}
		  					>
			  					+ Add More
		  					</div>
			  				: null
			  			}
						</div>

			  		{
			  			galleryImages.length || !context.isOrderSpecificCollection(context.selectedCollection)
			  			? null
			  			: <div 
			  				className="upload-cta" 
			  				onClick={() => {
		  						if (context.isNameBadgeTilesCollection(context.selectedCollection) && !context.isUserConnected) {
		  							onToggleAuthModal();
		  						} else {
		  							context.setState({ showUploadModal: true })	
		  						}
		  					}}
		  				>
		  					<div className="upload-cta-plus"></div>
		  				</div>
			  		}

			  		<div className="photo-grid-holder">
			  			<div className="photo-grid-inner">
						  	<div className="photo-grid duo size-s centered">
									{
										galleryImages.map(image => {
											return (<div className="tile" key={image.id}>
												<div className={ classNames('photo', { active: image.id === context.selectedImage }) } onClick={() => {
													if (context.isOrderSpecificCollection(image.collectionId)) {
														context.setState({
			                        selectedCollection: image.collectionId,
			                        selectedImage: image.id 
			                      });

			                      history.push('/editor/' + image.collectionId + '/' + image.id);
													}
												}}>
													<EditorPreview image={image} />
													{
														/*
														context.previews[image.id]
														? <img 
															src={ context.previews[image.id].url } 
															alt="User upload" 
														/>
														: null
														*/
													}
													<div className="overlay">
														<div className="overlay-buttons">
															<div onClick={event => {
																event.stopPropagation();
																context.copyImage(image.id);
															}}>
																<Copy />
																<span>Copy</span>
															</div>
															<div onClick={event => {
																event.stopPropagation();
																setImageForDeleteId(image.id);
															}}>
																<TrashGray />
															</div>
														</div>
													</div>
													{
														image.approved
														? <>
															<div className="approved-overlay"></div>
															<div className="approved-icon">
																<img src={ iconCheckmark } alt="Checkmark" />
															</div>
														</>
														: null
													}
													{
														image.loading
														? <LoadingWheel inline={true} />
														: null
													}
												</div>
											</div>)
										})
									}
								</div>
							</div>
						</div>

						{
							imageForDeleteId
							? <DeleteImageConfirmation 
								onCancel={() => setImageForDeleteId('')}
								onDelete={() => {
									setImageForDeleteId('');

									if (location.pathname.indexOf('/editor/') !== -1) {
										const {images} = context;
										const index = images.findIndex(({id}) => id === imageForDeleteId);

										if (index < images.length - 1) {
											const nextImage = images[index+1];

											if (nextImage) {
												context.setState({
										      selectedCollection: nextImage.collectionId,
										      selectedImage: nextImage.id
										    });
												history.push('/editor/' + nextImage.collectionId + '/' + nextImage.id);
											}
										}
									}

									context.popImage(imageForDeleteId);
								}}
							/>
							: null
						}
					</>
  			)
  		}}
		</EditorContext.Consumer>
  </aside>;
}
