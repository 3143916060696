import React, { Component } from 'react'
import classNames from 'classnames'
import firebase from '../firebase'
import { getConnectedUser } from '../firebaseHelpers'
import Draggable from 'react-draggable'
import LoadingWheel from '../components/LoadingWheel'
import DeleteImageConfirmation from '../components/DeleteImageConfirmation'
import EditorContext from '../contexts/EditorContext'
import Badge from '../components/Badge'
import Switcher from '../components/Switcher'
import Combobox from '../components/Combobox'
import { Flip, Move, Rotate, ZoomIn, ZoomOut, TrashGray, Cancel } from '../icons'

const editorSize = 564;
const getCustomerOrganizations = firebase.functions().httpsCallable('getCustomerOrganizations');

class EditorScreen extends Component {
  constructor() {
    super();

    this.state = {
      loading: false,
      imageForDeleteId: ''
    };
  }

  componentDidMount = async () => {
    const { match } = this.props;
    const connectedUser = await getConnectedUser();

    this.context.setState({
      selectedCollection: match.params.collectionId,
      selectedImage: match.params.id
    });

    if (connectedUser) {
      this.setState({ loading: true });
      getCustomerOrganizations({ includeLogoImageURLs: true }).then(async (data) => {
        const organizations = data.data;
        const logos = [{ id: '', name: 'None' }];

        for (let i in organizations) {
          for (let j in organizations[i].logoImages) {
            logos.push({
              id: organizations[i].logoImages[j].id,
              name: organizations[i].logoImageURLs[j]
            });
          }          
        }

        this.context.setState({ logos });
        this.setState({ loading: false });
      });
    }
  }

  render() {
    const { loading, imageForDeleteId } = this.state;
    const { history } = this.props;

  	return (
      <EditorContext.Consumer>
        {context => {
          const selectedImage = context.images.find(image => image.id === context.selectedImage);
          const selectedImageIndex = context.images.findIndex(image => image.id === context.selectedImage);
          const fontFamily = context.fontFamily.split(':')[0];
          const fontWeight = context.fontFamily.split(':')[1];

          if (!selectedImage) {
            return null;
          }

          const imageStyle = {
            transform: 'rotate(' + selectedImage.angle + 'deg) ' + 
              'scaleX(' + ((selectedImage.isFlipped?-1:1)*selectedImage.zoom/100) + ') ' + 
              'scaleY(' + (selectedImage.zoom/100) + ') ',
            filter: selectedImage.imageStyle
          };

          if (selectedImage.width >= selectedImage.height) {
            imageStyle.marginLeft = ((selectedImage.width*editorSize/selectedImage.height)/-2) + 'px';
          } else {
            imageStyle.marginTop = ((selectedImage.height*editorSize/selectedImage.width)/-2) + 'px';
          }

          return (
            <div className="panel-screen">
              <header className="editor-configurator">
                <div className="image-controls">
                  {
                    context.isNameBadgeTilesCollection(selectedImage.collectionId)
                    ? <>
                      <div className="panel-title">
                        IMAGE SETTINGS
                      </div>

                      <div className="image-controls-inner">
                        <div>
                          <label className="field-label">NAME BADGE</label>
                          <Switcher 
                            isActive={selectedImage.nameBadge} 
                            onToggle={() => context.updateImage(selectedImage.id, 'nameBadge', !selectedImage.nameBadge)}
                          />
                        </div>
                        <div>
                          <label className="field-label">ADD TITLE</label>
                          <Switcher 
                            isActive={selectedImage.titleBadge} 
                            onToggle={() => context.updateImage(selectedImage.id, 'titleBadge', !selectedImage.titleBadge)}
                          />
                        </div>
                        <div>
                          <label className="field-label">IMAGE STYLE</label>
                          <Combobox 
                            value={selectedImage.imageStyle}
                            options={[
                              {key: 'none', value: 'Full Color'}, 
                              {key: 'grayscale(100%)', value: 'Black & White'}
                            ]} 
                            customUI={true}
                            onChange={event => context.updateImage(selectedImage.id, 'imageStyle', event.target.value)}
                          />
                        </div>
                        <div>
                          <label className="field-label">ADD LOGO</label>
                          {
                            loading
                            ? <LoadingWheel inline={true} />
                            : <Combobox 
                              value={selectedImage.logo}
                              options={this.context.logos.map(logo => {
                                return { key: logo.id, value: logo.name }
                              })} 
                              customUI={true}
                              onChange={event => {
                                const { value } = event.target;
                                context.updateImage(selectedImage.id, 'logo', value);
                              }}
                            />
                          }
                        </div>
                      </div>
                    </>
                    : null
                  }
                </div>
                <div className="buttons">
                  <div>
                    <button className="sp-button bordered" onClick={() => this.setState({ imageForDeleteId: selectedImage.id })}>
                      Remove
                    </button>
                    {/*
                    <button className="sp-button bordered" onClick={() => this.context.setState({ 
                      showUploadModal: true,
                      changeImageId: selectedImage.id
                    })}>
                      Change Image
                    </button>
                    */}
                  </div>
                  <div className="step-changer">
                    {
                      selectedImageIndex > 0
                      ? <button onClick={() => {
                        const previousImage = context.images[selectedImageIndex-1];
                        history.push('/editor/' + previousImage.collectionId + '/' + previousImage.id);
                        context.setState({ 
                          selectedCollection: previousImage.collectionId,
                          selectedImage: previousImage.id 
                        });
                      }} className="sp-button">
                        Back
                      </button>
                      : <span className="sp-button secondary disabled">Back</span>
                    }

                    <button onClick={async () => {
                      context.updateImage(context.selectedImage, 'approved', true);
                      const nextImage = context.images[selectedImageIndex+1];

                      if (nextImage) {
                        history.push('/editor/' + nextImage.collectionId + '/' + nextImage.id);
                        context.setState({
                          selectedCollection: nextImage.collectionId,
                          selectedImage: nextImage.id 
                        });
                      } else {
                        history.push('/cart');
                      }
                    }} className="sp-button">
                      Save
                    </button>
                  </div>
                </div>
              </header>
              <div className="panel-main">
                <div className="editor-frame">
                  {
                    context.extended
                    ? null
                    : <>
                      <div className="editor-border editor-border-left"></div>
                      <div className="editor-border editor-border-right"></div>
                      <div className="editor-border editor-border-top">
                        {
                          selectedImage.approved
                          ? null
                          : <span>Trim line</span>
                        }
                      </div>
                      <div className="editor-border editor-border-bottom"></div>
                    </>
                  }

                  <Draggable 
                    position={{
                      x: selectedImage.positionLeft, 
                      y: selectedImage.positionTop
                    }} 
                    onStop={(event, { x, y }) => {
                      const { width, height, zoom } = selectedImage;
                      const ratio = width / height;
                      const maxOffset = editorSize * (zoom / 100 - 1) / 2;
                      const position = { 
                        x: x > maxOffset ? maxOffset : (x < -maxOffset ? -maxOffset : x),
                        y: y > maxOffset ? maxOffset : (y < -maxOffset ? -maxOffset : y)
                      };

                      if (ratio > 1) {
                        const maxOffsetX = (editorSize * width / height * zoom / 100 - editorSize) / 2;

                        position.x = x > maxOffsetX ? maxOffsetX : (x < -maxOffsetX ? -maxOffsetX : x);
                      } else if (ratio < 1) {
                        const maxOffsetY = (editorSize * height / width * zoom / 100 - editorSize) / 2;

                        position.y = y > maxOffsetY ? maxOffsetY : (y < -maxOffsetY ? -maxOffsetY : y);
                      }

                      context.updateImage(selectedImage.id, 'position', position);
                    }}
                  >
                    <div className="editor-image">
                      <img 
                        className={classNames('image', {
                          'wider': selectedImage.width >= selectedImage.height,
                          'higher': selectedImage.width < selectedImage.height
                        })}
                        src={selectedImage.data}
                        alt="Gallery item"
                        style={imageStyle}
                      />
                    </div>
                  </Draggable>

                  <div className={classNames({
                    'badge-holder': true,
                    'position-left': context.align === 'left',
                    'position-center': context.align === 'center',
                    'position-right': context.align === 'right',
                    'to-edge': context.extended
                  })}>
                    {
                      selectedImage.nameBadge && context.showSettings
                      ? <Badge 
                          text={context.text} 
                          style={{
                            backgroundColor: context.backgroundColor,
                          }}
                          inputStyle={{
                            color: context.color,
                            //backgroundColor: context.backgroundColor,
                            fontSize: context.fontSize + 'px',
                            fontFamily: fontFamily,
                            fontWeight: fontWeight,
                            textTransform: context.textTransform
                          }}
                          trim={7 + (30 - context.fontSize) / 3}
                          name={selectedImage.nameBadgeText}
                          name2={selectedImage.nameBadgeText2}
                          title={selectedImage.titleBadgeText}
                          showTitle={selectedImage.titleBadge}
                          logo={context.logos.find(logo => logo.id === selectedImage.logo)}
                          onChangeName={value => context.updateImage(selectedImage.id, 'nameBadgeText', value, true)}
                          onBlurName={value => context.updateImage(selectedImage.id, 'nameBadgeText', value)}
                          onChangeName2={value => context.updateImage(selectedImage.id, 'nameBadgeText2', value, true)}
                          onBlurName2={value => context.updateImage(selectedImage.id, 'nameBadgeText2', value)}
                          onChangeTitle={value => context.updateImage(selectedImage.id, 'titleBadgeText', value, true)}
                          onBlurTitle={value => context.updateImage(selectedImage.id, 'titleBadgeText', value)}
                      />
                      : null
                    }
                  </div>
                </div>
                <div className="editor-controls">
          				<div>
                    <figure>
                      <Move />
                    </figure>
          					<span>Move</span>
      						</div>

                  <div onClick={() => context.updateImage(selectedImage.id, 'zoom', selectedImage.zoom + 10)}>
                    <figure>
                      <ZoomIn/>
                    </figure>
                    <span>Zoom In</span>
        					</div>
        					<div onClick={() => context.updateImage(selectedImage.id, 'zoom', selectedImage.zoom === 100 ? selectedImage.zoom : (selectedImage.zoom - 10) )}>
                    <figure>
                      <ZoomOut/>
                    </figure>
                    <span>Zoom Out</span>
        					</div>
        					<div onClick={() => context.updateImage(selectedImage.id, 'angle', selectedImage.angle === 270 ? 0 : (selectedImage.angle + 90))}>
                    <figure>
                      <Rotate/>
                    </figure>
                    <span>Rotate</span>
        					</div>
        					<div onClick={() => context.updateImage(selectedImage.id, 'isFlipped', !selectedImage.isFlipped)}>
                    <figure>
                      <Flip/>
                    </figure>
                    <span>Flip</span>
        					</div>
                  <div onClick={() => context.resetImage(selectedImage.id) }>
                    <figure>
                      <Cancel/>
                    </figure>
                    <span>Reset</span>
                  </div>
                  <div onClick={() => this.setState({ imageForDeleteId: selectedImage.id })}>
                    <figure>
                      <TrashGray/>
                    </figure>
                    <span>Remove</span>
        					</div>
          			</div>
              </div>

              {
                imageForDeleteId
                ? <DeleteImageConfirmation 
                  onCancel={() => this.setState({ imageForDeleteId: '' })}
                  onDelete={() => {
                    const {images} = this.context;
                    const index = images.findIndex(({id}) => id === imageForDeleteId);
                    this.setState({ imageForDeleteId: '' });

                    if (index < images.length - 1) {
                      const nextImage = images[index+1];

                      if (nextImage) {
                        this.context.setState({
                          selectedCollection: nextImage.collectionId,
                          selectedImage: nextImage.id
                        });
                        history.push('/editor/' + nextImage.collectionId + '/' + nextImage.id);
                      }
                    }

                    this.context.setState({ loading: true });
                    this.context.popImage(imageForDeleteId, true);
                  }}
                />
                : null
              }
            </div>
          )
        }}
      </EditorContext.Consumer>
    )
  }
}

EditorScreen.contextType = EditorContext;

export default EditorScreen;
