import firebase from './firebase'
import axios from 'axios'

const fetchCustomers = () => {
  return firebase
    .firestore()
    .collection('customers')
    .where('deletedAt', '==', null)
    .orderBy('createdAt', 'desc')
    .get();
}

const fetchOrganizations = () => {
  return firebase
    .firestore()
    .collection('organizations')
    .where('deletedAt', '==', null)
    .orderBy('createdAt', 'desc')
    .get();
}

const fetchImageCollections = () => {
  return firebase
    .firestore()
    .collection('imageCollections')
    .where('deletedAt', '==', null)
    .orderBy('createdAt', 'desc')
    .get();
}

const getConnectedUser = () => {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged(user => {
      resolve(user);
    })
  })
}

const isUserConnected = () => {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged(user => {
      resolve(!!user);
    })
  })
}

const isUserAdmin = () => {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        firebase.firestore().collection('admins').where('__name__', '==' , user.uid).get().then(results => {
          results.forEach(result => {
            resolve(true);
          });
        }).catch(error => {
          resolve(false);
        });
      } else {
        resolve(false);
      }
    })
  })
}

const redirectToLogin = history => {
  localStorage.setItem('redirectTo', history.location.pathname);
  history.push('/auth');
}

const redirectFromLogin = history => {
  const redirectTo = localStorage.getItem('redirectTo');

  if (redirectTo) {
    localStorage.removeItem(redirectTo);
    history.push(redirectTo);
  } else {
    window.location.reload();
  }
}

const uploadFile = async (url, file) => {
  if (typeof file.data !== 'undefined') {
    const { data } = await axios({ url: file.data, method: 'GET', responseType: 'blob' });  
    file = data;
  }
  
  return axios.put(url, file, { headers: { 'Content-Type': 'image/png' }});
}

export {
  fetchCustomers,
  fetchOrganizations,
  fetchImageCollections,
  getConnectedUser,
  isUserConnected,
  isUserAdmin,
  redirectToLogin,
  redirectFromLogin,
  uploadFile
}
