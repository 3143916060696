import React, { FunctionComponent } from 'react'
import { ArrowDown } from '../icons'

type ComboboxOption = {
	key: any,
	value: string
}

type ComboboxProps = {
	name?: string,
	value: any,
	options: ComboboxOption[],
	multiple?: boolean,
	onChange: any,
	className?: string,
	customUI?: boolean
}

const getValueByKey = (options: any[], key: string) => {
	const selection: any|null = options.find(option => option.key.toString() === key.toString());

	if (selection) {
		if (selection.value.toString().indexOf('https://') !== -1) {
			return '<img src="' + selection.value + '" alt="Organization logo" /><div class="custom-ui-mask"></div>';
		}

		return selection.value;
	}

	return '';
}

const Combobox: FunctionComponent<ComboboxProps> = ({ name, value, options, multiple, onChange, className, customUI }: ComboboxProps) => {
	if (customUI) {
		return <div className={ 'field-select custom-ui ' + (className ? className : '') }>
			<div className="selected-value">
				<span dangerouslySetInnerHTML={{ __html: getValueByKey(options, value) }}></span>
				<ArrowDown />
				<div className="custom-ui-mask"></div>
			</div>
			<div className="options-list">
				{
					options.map((option: ComboboxOption) => <div className="option-item" key={ option.key } onClick={() => onChange({ target: { value: option.key, name } })}>
						{
							option.value.toString().indexOf('https://') !== -1
							? <img src={option.value} alt="Organization logo" />
							: option.value
						}
					</div>)
				}
			</div>
		</div>
	}

	if (multiple) {
		return <select className={ 'field-select multiple ' + className } onChange={event => onChange(event)} multiple>
			{
				options.map((option: ComboboxOption) => <option key={ option.key }>{ option.value }</option>)
			}
		</select>
	}

	return <select className={ 'field-select ' + className } name={name} onChange={event => onChange(event)} value={value}>
		{
			options.map((option: ComboboxOption) => <option key={ option.key } value={ option.key }>{ option.value }</option>)
		}
	</select>
}

export default Combobox
