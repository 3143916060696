export const APP_VERSION = '3.9.2.p';

export const API_URL = 'https://sweetpix.herokuapp.com';
export const API_URL2 = 'https://us-central1-sweet-pix.cloudfunctions.net';

export const AWS_BUCKET_REGION = 'us-east-1';
export const AWS_IDENTITY_POOL_ID = 'us-east-1:b3250c1b-de70-4d16-8161-84225eda6759';
export const AWS_ALBUM_BUCKET_NAME = 'sweetpix2';

// export const BRAINTREE_TOKEN = 'sandbox_4th59y9w_bchcx22yj83wwbkj';
export const BRAINTREE_TOKEN = 'production_5rjhq3ry_jppk9yj6c9fbdk34';
