import { combineReducers } from 'redux';
import address from './addressReducer';
import photos from './photosReducer';
import promoCode from './promoCodeReducer';

const rootReducer = combineReducers({
	address,
	photos,
	promoCode
});

export default rootReducer;
