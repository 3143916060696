import React, { FunctionComponent } from 'react';
import { RouteComponentProps, Link, withRouter } from 'react-router-dom';

interface SideMenuProps extends RouteComponentProps {
  
}

const SideMenu: FunctionComponent<SideMenuProps> = ({ location }: SideMenuProps) => {
  if (location.pathname.indexOf('/account') === -1 && location.pathname.indexOf('/admin') === -1) {
    return null;
  }

  return <aside className="side-menu">
    <div className="inner-box">
      {
        location.pathname.indexOf('/account') !== -1
        ? <div>
          <div className="section-title">MY ACCOUNT</div>
          <ul>
            <li>
              <Link to="/account" className={ location.pathname === '/account' ? 'active' : '' }>
                SAVED ORDERS
              </Link>
            </li>
            <li>
              <Link to="/account/orders" className={ location.pathname === '/account/orders' ? 'active' : '' }>
                Order history
              </Link>
            </li>
            <li>
              <Link to="/account/contact" className={ location.pathname === '/account/contact' ? 'active' : '' }>
                CONTACT INFO
              </Link>
            </li>
            <li>
              <a href="mailto:support@sweetpixapp.com">
                NEED HELP
              </a>
            </li>
          </ul>
        </div>
        : null
      }

      {
        location.pathname.indexOf('/admin') !== -1
        ? <div>
          <div className="section-title">Admin</div>
          <ul>
            <li>
              <Link to="/admin" className={ location.pathname === '/admin' ? 'active' : '' }>
                Orders
              </Link>
            </li>
            <li>
              <Link to="/admin/customers" className={ location.pathname === '/admin/customers' ? 'active' : '' }>
                Customers
              </Link>
            </li>
            <li>
              <Link to="/admin/promo-codes" className={ location.pathname === '/admin/promo-codes' ? 'active' : '' }>
                Promo Codes
              </Link>
            </li>
            <li>
              <Link to="/admin/image-collections" className={ location.pathname === '/admin/image-collections' ? 'active' : '' }>
                Image Collections
              </Link>
            </li>
            <li>
              <Link to="/admin/organizations" className={ location.pathname === '/admin/organizations' ? 'active' : '' }>
                Organizations
              </Link>
            </li>
            <li>
              <Link to="/admin/reports" className={ location.pathname === '/admin/reports' ? 'active' : '' }>
                Reports
              </Link>
            </li>
          </ul>
        </div>
        : null
      }
    </div>
  </aside>
}

export default withRouter(SideMenu);
