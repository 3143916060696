import React from 'react'
import classNames from 'classnames'
import EditorContext from '../contexts/EditorContext'
import Badge from '../components/Badge'

const editorSize = 564;

export default function EditorPreview({image}) {
	const zoom = image.zoom * 1.05;
	const imageStyle = {
    transform: 'rotate(' + image.angle + 'deg) ' + 
      'scaleX(' + ((image.isFlipped?-1:1)*zoom/100) + ') ' + 
      'scaleY(' + (zoom/100) + ') ',
    filter: image.imageStyle
  };

  if (image.width >= image.height) {
    imageStyle.marginLeft = ((image.width*editorSize/image.height)/-2) + 'px';
  } else {
    imageStyle.marginTop = ((image.height*editorSize/image.width)/-2) + 'px';
  }

	return <EditorContext.Consumer>
        {context => {
        	const fontFamily = context.fontFamily.split(':')[0];
          const fontWeight = context.fontFamily.split(':')[1];

	 				return <div className="editor-frame">
	          <div className="editor-image" style={{ transform: 'translate(' + image.positionLeft + 'px, ' + image.positionTop + 'px)' }}>
	            <img 
	              className={classNames('image', {
	                'wider': image.width >= image.height,
	                'higher': image.width < image.height
	              })}
	              src={image.data}
	              alt="Gallery item"
	              style={imageStyle}
	            />
	          </div>

		        <div className={classNames({
		          'badge-holder': true,
		          'position-left': context.align === 'left',
		          'position-center': context.align === 'center',
		          'position-right': context.align === 'right',
		          'to-edge': context.extended
		        })}>
		          {
		            image.nameBadge && context.showSettings
		            ? <Badge 
		                text={context.text} 
		                style={{
		                  backgroundColor: context.backgroundColor,
		                }}
		                inputStyle={{
		                  color: context.color,
		                  fontSize: context.fontSize + 'px',
		                  fontFamily: fontFamily,
		                  fontWeight: fontWeight,
		                  textTransform: context.textTransform
		                }}
		                trim={7 + (30 - context.fontSize) / 3}
		                name={image.nameBadgeText}
		                name2={image.nameBadgeText2}
		                title={image.titleBadgeText}
		                showTitle={image.titleBadge}
		                logo={context.logos.find(logo => logo.id === image.logo)}
		                onChangeName={event => context.updateImage(image.id, 'nameBadgeText', event.target.value)}
		                onChangeTitle={event => context.updateImage(image.id, 'titleBadgeText', event.target.value)}
		            />
		            : null
		          }
		        </div>
	      	</div>
      	}}
    	</EditorContext.Consumer>
}
