import initialState from './initialState';
import { RECEIVE_PROMO_CODE } from '../actions/actionTypes';

export default function promoCode(state = initialState.promoCode, action) {
  let newState;

  switch (action.type) {
    case RECEIVE_PROMO_CODE:
      newState = action.promoCode;

      return newState;

    default:
      return state;
  }
}
