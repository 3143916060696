import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { prepend, prepare, update } from '../helpers/cropping';
import Cropper from 'react-easy-crop';
import LoadingWheel from '../components/LoadingWheel';
import LowResolutionWarning from '../components/LowResolutionWarning';
import * as photosActions from '../actions/photosActions';
import iconClose from '../img/icon-close.png';

class PhotosScreen extends Component {
	constructor() {
		super();

		this.state = {
			index: null,
			cropperConfig: null,
			cropperSelection: null
	  	};
	}

	componentDidMount() {
		const { photos } = this.props;

		if (photos.length < 1) {
			// this.props.history.push('/upload');
		} else {
			this.onPrepare();
		}

		if (window.ga)
			window.ga('send', 'pageview', '/photos');
		
		document.getElementsByTagName('body')[0].className = 'photos';
	}

	componentWillUnmount() {
		const body = document.getElementsByTagName('body')[0];
		body.className = body.className.replace('photos', '');
	}

	onPrepare = (e) => {
		for (let i in this.props.photos) {
			if (typeof this.props.photos[i] === 'object' && this.props.photos[i].loading) {
				prepare(this.props.photos[i]).then((photo) => {
					const { photos } = this.props;
					const index = photos.findIndex(p => p.id === photo.id);

					if (index > -1) {
						photos[index] = photo;
					}

					this.props.photosActions.receivePhotos(photos);
					this.forceUpdate();
				});
			}
		}
  	}

	onDelete = (e) => {
		const { target } = e;
		const { attributes } = target;
		const { value } = attributes['data-idx'];
		const { photos, onChangeHeaderState } = this.props;

		photos.splice(value, 1);

		this.props.photosActions.receivePhotos(photos);
		this.forceUpdate();
		onChangeHeaderState();
	}

 	onAdd = (e) => {
	  	const { target } = e;
	  	const { files } = target;
		const { photos, onChangeHeaderState } = this.props;

		window.fbq('track', 'AddToCart', { count: files.length });

		for (let i in files) {
			if (typeof files[i] === 'object') {
				const photo = prepend(files[i]);
				photos.push(photo);
			}
		}

		this.onPrepare();
		this.props.photosActions.receivePhotos(photos);
		this.forceUpdate();
		onChangeHeaderState();
  	}

  	onModalChange = (index) => {
  		this.setState({ 
  			index: index,
  			cropperConfig: index !== null ? Object.assign({}, this.props.photos[index]) : null 
  		});
  	}

  	onCropChange = crop => {
  		const { cropperConfig } = this.state;
  		cropperConfig.crop_x = crop.x;
  		cropperConfig.crop_y = crop.y;
	    
	    this.setState({ cropperConfig });
  	}
	 
  	onZoomChange = zoom => {
	    const { cropperConfig } = this.state;
  		cropperConfig.zoom = zoom;
	    
	    this.setState({ cropperConfig });
  	}

  	onCropComplete = (cropperSelection, croppedAreaPixels) => {
  		this.setState({ cropperSelection });
  	}

  	onModalFinish = async () => {
  		const { index, cropperConfig, cropperSelection } = this.state;
  		const { photos } = this.props;
  		const newImage = await update(cropperConfig, cropperSelection);

  		photos[index] = newImage;

  		this.onModalChange(null);
  		this.props.photosActions.receivePhotos(photos);
		this.forceUpdate();
  	}

  	onKeep = (i) => {
  		const { photos } = this.props;
  		photos[i].warning = false;
  		this.props.photosActions.receivePhotos(photos);
  		this.forceUpdate();
  	}

  	render() {
  		const { photos } = this.props;
  		const { cropperConfig } = this.state;
  		const showWarning = photos.filter(photo => photo.warning && !photo.loading).length > 0;

    	return (
    		<div>
	    		<div className={ 'content' + (cropperConfig || showWarning ? ' content-modal' : '') }>
		    		<div className={ 'photo-grid photo-grid-' + photos.length }>
		    			{
		    				photos.map((photo, i) => {
		    					return (<div className="tile" key={i}>
		    								<div className="photo">
		    									{ photo.loading ? <LoadingWheel version={2} /> : <img src={ photo.selection } alt="Tile" /> }
		    								</div>
		    								<div className="tools">
		    									<button className="edit" onClick={ () => { this.onModalChange(i) } }>Edit</button>
		    									<button className="delete" data-idx={ i } onClick={ this.onDelete }>Delete</button>
		    								</div>
										</div>)
		    				})
		    			}
		    			<div className="add-more" onClick={ () => { this.refs.input.click(); } }></div>
					</div>
					<input type="file" className="file-uploader" onChange={ this.onAdd } ref="input" multiple="multiple" accept="image/jpeg, image/png, image/raw" />

					{
						showWarning
						? (<div className="global-modal">
							{
								photos.map((photo, i) => {
									return photo.warning && !photo.loading
										? <LowResolutionWarning key={photo.id} photo={photo} idx={i} onKeep={() => { this.onKeep(i) }} onRemove={ this.onDelete } /> 
										: null;
								})
							}
						</div>)
						: null
					}

					{
						cropperConfig ?
						(<div className="global-modal">
							<div className="outer">
								<span className="close" onClick={ () => { this.onModalChange(null) } }>
									<img src={ iconClose } alt="Close" />
								</span>
								<div className="inner inner-crop">
									<div className="title has-subtitle">Adjust Photo</div>
									<div className="subtitle">Pinch or Zoom</div>
									<Cropper
										classes={{ containerClassName: 'cropper-container', imageClassName: 'cropper-image', cropAreaClassName: 'cropper-area' }}
								        image={ cropperConfig.image }
								        crop={{ x: cropperConfig.crop_x, y: cropperConfig.crop_y }}
								        zoom={ cropperConfig.zoom }
								        aspect={ cropperConfig.aspect }
								        onCropChange={ this.onCropChange }
								        onCropComplete={ this.onCropComplete }
								        onZoomChange={ this.onZoomChange }
							      	/>
									<button className="action-button size-x white" onClick={ () => { this.onModalChange(null) } }>CANCEL</button>
									<button className="action-button size-x" onClick={ this.onModalFinish }>DONE</button>
								</div>
							</div>
						</div>)
						: null
					}
				</div>
			</div>
		)
  	}
}

function mapStateToProps(state, action) {
  return {
    photos: state.photos
  }
}
function mapDispatchToProps(dispatch) {
  return {
    photosActions: bindActionCreators(photosActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhotosScreen);
