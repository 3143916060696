import React, { Component } from 'react';

export default class LoadingWheel extends Component {
	render() {
		const { version, inline, width } = this.props;

		if (version === 2) {
			return (
				<div className="loading-wheel">
					<div className="loading-progress-bar">
						<div className="progress-bar"></div>
					</div>
				</div>
			)
		}

		return (
			<div className={ 'loading-wheel ' + width + (inline ? ' inline' : '') }>
				<div className="loading-bundle">
					<div className="loading-bundle-inner"></div>
				</div>
			</div>
		)
	}
}

LoadingWheel.defaultProps = {
  version: 1,
  width: 'regular'
}
