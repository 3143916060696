import React, { Component } from 'react'
import axios from 'axios'
import firebase from '../firebase'
import EditorContext from '../contexts/EditorContext'
import PhotoGrid from '../components/PhotoGrid'
import InfoBanner from '../components/InfoBanner'
import DragAndDropUploader from '../components/DragAndDropUploader'
import LoadingWheel from '../components/LoadingWheel'

const getImageCollectionImageByName = firebase.functions().httpsCallable('getImageCollectionImageByName')

class GalleryScreen extends Component {
  constructor() {
    super();

    this.state = {
      loading: false
    };
  }

  componentDidMount() {
    const { match } = this.props;

    if (match.params.id) {
      this.context.setState({ selectedCollection: match.params.id }, false, true);
    }
  }

  render() {
    const { loading } = this.state;
    const { onToggleAuthModal } = this.props;

    return (
    	<div className="panel-screen">
        <EditorContext.Consumer>
          {context => {
            const selectedCollection = context.collections.find(collection => collection.id === context.selectedCollection);

            if (!selectedCollection) {
              return null;
            }

            return (
              <div className="panel-main justify-top">
          			<div className="gallery-holder">
      			    	{
                    !context.isOrderSpecificCollection(context.selectedCollection) 
                    ? <>
                      <div className="gray-bar-title">
                        <div className="main-title">{ selectedCollection.name }</div>
                        <div>Click "Add" to include to your order, view in your cart.</div>
                      </div>
                      <div className="gallery-info">Available Images: { selectedCollection.images.length }</div>
                    </>
                    : null
                  }

                  <PhotoGrid 
                    images={context.selectedCollectionImages} 
                    showTitle={true}
                    showAddButton={true} 
                    onAdd={image => {
                      this.setState({ loading: true });

                      getImageCollectionImageByName({
                        id: selectedCollection.id, 
                        name: image.name
                      }).then(result => {
                        axios({ url: result.data.downloadURL, method: 'GET', responseType: 'blob' }).then(({data}) => {
                          context.pushImages([data]);
                          this.setState({ loading: false })
                        }).catch(error => this.setState({ loading: false }));
                      });
                    }}
                  />

                  {
                    selectedCollection.images.length < 1
                    ? <div className="uploader-holder">
                      <DragAndDropUploader 
                        isStatic={true} 
                        onUpload={files => context.pushImages(files)}
                      />

                      {
                        context.isNameBadgeTilesCollection(context.selectedCollection) && !context.isUserConnected
                        ? <div className="uploader-mask" 
                          onClick={() => onToggleAuthModal()}
                          onDragOver={event => {
                            event.preventDefault();
                            event.stopPropagation();
                          }}
                          onDrop={event => {
                            event.preventDefault();
                            event.stopPropagation();
                            onToggleAuthModal();
                          }}></div>
                        : null
                      }
                    </div>
                    : null
                  }

                  {
                    context.isNameBadgeTilesCollection(context.selectedCollection)
                    ? <InfoBanner 
                      onToggleAuthModal={onToggleAuthModal}
                    />
                    : null
                  }

                  {
                    loading
                    ? <LoadingWheel />
                    : null
                  }
      					</div>
              </div>
            )
          }}
        </EditorContext.Consumer>
			</div>
		)
  }
}

GalleryScreen.contextType = EditorContext;

export default GalleryScreen;
