import React, {  } from 'react'
import { Close } from '../icons'
import EditorContext from '../contexts/EditorContext'

export default function LowResolutionWarning() {
	return (
		<EditorContext.Consumer>
      {context => {
      	if (context.lowResolutionImages.length < 1) {
      		return null;
      	}

      	return (
      		<div className="modal-overlay on-top">
      			{
      				context.lowResolutionImages.map((image, i) => {
      					return i > 0 ? null : <div key={image.image.lastModified + '_' + image.image.name} className="modal-content">
									<span className="close-btn" onClick={() => {
										context.pushImages(context.lowResolutionImages.map(({image}) => image), true);
										context.setState({ lowResolutionImages: [] });
									}}>
						  			<Close />
					  			</span>
									<div className="module-inner">
										<figure>
											<svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
												<circle cx="37" cy="37" r="37" fill="#E96071"/>
												<circle cx="37.1616" cy="55.2573" r="4.84716" fill="#FAFAFA"/>
												<rect x="32.6377" y="13.8955" width="9.04803" height="32.9607" fill="#FAFAFA"/>
											</svg>
										</figure>
										<div className="headline">LOW RESOLUTION WARNING</div>
										<figure className="image-preview">
											<img src={image.url} alt="Preview" />
										</figure>
										<div className="below-headline">This image is of lower resolution and may print blurry.</div>
										<div className="buttons">
											<button className="sp-button bordered" onClick={() => {
												context.setState({ lowResolutionImages: context.lowResolutionImages.slice(1) });
											}}>
												Remove
											</button>
											<button className="sp-button bordered" onClick={() => {
												context.pushImages([image.image], true);
												context.setState({ lowResolutionImages: context.lowResolutionImages.slice(1) });
											}}>
												Keep Anyway
											</button>
										</div>
										<div className="note">Note: Sweet Pix is not resposible for images that print blurry.</div>
									</div>
								</div>
							})
      			}
					</div>
	      )
      }}
    </EditorContext.Consumer>
	)
}
