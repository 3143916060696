import initialState from './initialState';
import { RECEIVE_PHOTOS } from '../actions/actionTypes';

export default function photos(state = initialState.photos, action) {
  let newState;

  switch (action.type) {
    case RECEIVE_PHOTOS:
      newState = action.photos;

      return newState;

    default:
      return state;
  }
}
