import React, { Component } from 'react'
import EditorContext from '../contexts/EditorContext'
import PhotoGrid from '../components/PhotoGrid'
import LoadingWheel from '../components/LoadingWheel'
import firebase from '../firebase'

const getOrder = firebase.functions().httpsCallable('getOrder');
const getOrderImageDownloadURLs = firebase.functions().httpsCallable('getOrderImageDownloadURLs');

class OrderPreviewScreen extends Component {
  constructor() {
    super();

    this.state = {
      order: null,
      images: [],
      loading: true,
      localOrder: false
    };
  }

  componentDidMount() {
    const { match } = this.props;

    if (match.params.id.indexOf('preview') !== -1) {
      this.setState({ localOrder: true });
    }

    this.loadEntry();
  }

  loadEntry = () => {
    const { match } = this.props;

    this.setState({ 
      loading: true
    });

    getOrder({ id: match.params.id.replace('preview-', ''), token: match.params.token }).then(({data}) => {
      this.setState({
      	order: data
      });

      getOrderImageDownloadURLs({ id: data.id, token: data.token }).then(({data}) => {
        const ids = Object.keys(data)

        this.setState({
          loading: false,
          images: Object.values(data).map((image, index) => {
            return { 
              id: ids[index],
              url: image 
            }
          })
        });
      }).catch(error => {
        alert(JSON.stringify(error));
        this.setState({
          loading: false
        });
      });
      
    }).catch(error => {
      alert(JSON.stringify(error));
      this.setState({
        loading: false
      });
    });
  }

  render() {
    const { order, images, loading, localOrder } = this.state;

    if (!order) {
      return null;
    }

    return <div className="panel-screen">
      <div className="panel-main">
        <div className="full-panel">
          <div className="order-summary centered">
            <div className="large-title mb-xl">Order Preview</div>
            <div className="order-message">
              <div className="order-number">ORDER #: { order.id }</div>
            </div>
            <div className="small-title">SHIPPING TO</div>
            <div className="shipping-address">
              <strong>{ order.fullName }</strong>
              <br/>
              {
              	order.address
              	? <>
              		{ order.address.address }
		              <br/>
		              { order.address.city }, { order.address.state }, { order.address.zip }, { order.address.country }
		              <br/>
              	</>
              	: null
              }
              <a href={ 'mailto:' + order.email } target="_blank" rel="noopener noreferrer">{ order.email }</a>
            </div>
            <div className="small-title mb-m">ORDER SUMMARY</div>
            <table className="order-table" cellPadding="0" cellSpacing="0">
              <thead>
                <tr>
                  <th>QTY</th>
                  <th align="left">DESCRIPTION</th>
                  <th>SIZE</th>
                  <th className="width-s">PER TILE</th>
                  <th className="width-s" align="right">TOTAL</th>
                </tr>
              </thead>
              <tbody>
                {
                  order.imageCollections.map((collection, index) => (
                      <tr key={index}>
                        <td align="center">{ collection.images.length }</td>
                        <td>{ collection.name }</td>
                        <td align="center">{ order.tileSize } in.</td>
                        <td align="center">${ order.price.imageCollections[index].tilePrice / 100 }</td>
                        <td align="right">${ order.price.imageCollections[index].totalPrice / 100 }</td>
                      </tr>
                    )
                  )
                }
              </tbody>
              <tfoot>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="regular-text">Subtotal</td>
                  <td className="regular-text" align="right">${ order.price.subTotal / 100 }</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="regular-text">Shipping</td>
                  <td className="regular-text" align="right">${ order.price.shipping / 100 }</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="regular-text">Tax</td>
                  <td className="regular-text" align="right">${ order.price.tax / 100 }</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>TOTAL</td>
                  <td align="right">${ order.price.total / 100 }</td>
                </tr>
              </tfoot>
            </table>

            <EditorContext.Consumer>
              {context => (
                <>
                  {
                    localOrder
                    ? <>
                      {
                        context.collections.map(collection => {
                          const qty = context.getCollectionCartImagesCount(collection.id);
                          const imageIds = context.images.filter(image => image.collectionId === collection.id).map(image => image.id);
                          const previews = [];

                          for (let i in context.images) {
                            if (imageIds.indexOf(context.images[i].id) !== -1) {
                              previews.push({
                                id: i,
                                preview: context.images[i]
                              });
                            }
                          }

                          if (!qty) {
                            return null;
                          }

                          return (
                            <div className="photo-section" key={collection.id}>
                              <div className="photo-section-title">
                                <div>{collection.name} ({qty})</div>
                              </div>
                              <PhotoGrid 
                                images={previews} 
                              />
                            </div>
                          );
                        })
                      }
                    </>
                    : <>
                      {
                        order.imageCollections.map((collection, index) => (
                          <div className="photo-section" key={index}>
                            <div className="photo-section-title">
                              <div>{collection.name} ({collection.images.length})</div>
                            </div>
                            <PhotoGrid 
                              images={images.filter(image => collection.images.indexOf(image.id) !== -1)} 
                            />
                          </div>
                        ))
                      }
                    </>
                  }
                </>
              )}
            </EditorContext.Consumer>
          </div>
        </div>
      </div>

      {
        loading
        ? <LoadingWheel />
        : null
      }
    </div>
  }
}

export default OrderPreviewScreen;
