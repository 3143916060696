import React, { Component } from 'react'
import { SketchPicker as ColorPicker } from 'react-color'
import { ArrowDown } from '../icons'

export default class Colorbox extends Component {
	constructor() {
		super();

		this.state = {
			showPicker: false
		};
	}

	render() {
  	const { showPicker } = this.state;
  	const { color, onChange } = this.props;

    return (
    	<div className="color-box">
    		<div className="color-box-value" onClick={ () => this.setState({ showPicker: !showPicker }) }>
    			<div className="color-box-swatch" style={{ backgroundColor: color }}></div>
    			<ArrowDown />
    		</div>
    		{
    			showPicker
  				? <div className="color-box-picker">
	  				<ColorPicker
			      	disableAlpha={ true }
			        color={ color }
			        onChange={ onChange }
			        onChangeComplete={ onChange }
			      />
	    			<button 
	    				className="sp-button bolder expanded height-s" 
	    				onClick={ () => this.setState({ showPicker: false }) }
    				>
    					Close
  					</button>
		      </div>
		      : null
    		}
      </div>
    )
  }
}
