import React, { FunctionComponent } from 'react'
import { Copy, TrashGray } from '../icons'
import EditorPreview from './EditorPreview'

interface Image {
	id: string;
	name: string;
	url: string;
	downloadURL: string;
	data: string;
	preview?: any;
}

type PhotoGridProps = {
	images: Image[],
	showTitle?: boolean,
	showLink?: boolean,
	showOverlay?: boolean,
	showDeleteButton?: boolean,
	showCopyButton?: boolean,
	showAddButton?: boolean,
	onDelete?: any,
	onCopy?: any,
	onAdd?: any,
	onClick?: any
}

const PhotoGrid: FunctionComponent<PhotoGridProps> = ({ images, showTitle, showLink, showOverlay, showDeleteButton, showCopyButton, showAddButton, onDelete, onCopy, onAdd, onClick }: PhotoGridProps) => <div className="photo-grid flex">
		{
			images.map(image => {
				return (<div className="tile-wrapper" key={image.id} data-key={image.id}>
					<div className="tile">
						<div className="photo" onClick={() => {
							if (onClick)
								onClick(image);
						}}>
							{
								image.preview
								? <EditorPreview image={image.preview} />
								: <img crossOrigin="anonymous" src={ image.data ? image.data : (image.downloadURL ? image.downloadURL : image.url) } alt="Tile" />
							}
							{
								showOverlay
								? <div className="overlay">
									<div className="overlay-buttons">
										{
											showCopyButton
											? <div onClick={event => {
												event.preventDefault();
												event.stopPropagation();
												onCopy(image);
											}}>
												<Copy />
												<span>Copy</span>
											</div>
											: null
										}
										{
											showDeleteButton
											? <div onClick={event => {
												event.preventDefault();
												event.stopPropagation();
												onDelete(image);
											}}>
												<TrashGray />
											</div>
											: null
										}
									</div>
								</div>
								: null
							}
						</div>
					</div>
					{
						showTitle
						? <div className="title">{ image.name.replace('.jpg', '') }</div>
						: null
					}
					{
						showLink && image.name
						? <div className="link">{ image.name.replace('.jpg', '') }</div>
						: null
					}
					{
						showAddButton
						? <div className="button">
							<span onClick={() => {
								onAdd(image);
							}}>Add</span>
						</div>
						: null
					}
					{
						showDeleteButton && !showOverlay
						? <div className="button" onClick={() => {
							if (window.confirm('Are you sure?')) {
								onDelete(image);
							}
						}}>
							<span>Delete</span>
						</div>
						: null
					}
				</div>)
			})
		}
	</div>

export default PhotoGrid
