import React, { Component } from 'react'
import firebase from '../../firebase'
import { getConnectedUser, redirectToLogin } from '../../firebaseHelpers'
import Checkbox from '../../components/Checkbox'
import Combobox from '../../components/Combobox'
import LoadingWheel from '../../components/LoadingWheel'

const createCustomer = firebase.functions().httpsCallable('createCustomer');
const updateCustomer = firebase.functions().httpsCallable('updateCustomer');

const getContactObject = () => {
  return {
    firstName: '',
    lastName: '',
    email: '',
    title: '',
    phone: ''
  }
};

const getAddressObject = () => {
  return {
    address: '',
    unitOrSuite: '',
    companyName: '',
    state: '',
    city: '',
    zip: '',
    country: ''
  };
};

export default class AccountContactScreen extends Component {
  constructor() {
    super();

    this.state = {
      user: null,
      contacts: [
        getContactObject()
      ],
      addresses: [
        getAddressObject()
      ],
      loading: true
    };
  }

  componentDidMount = async () => {
    const connectedUser = await getConnectedUser();

    if (connectedUser) {
      this.setState({ user: connectedUser });
      this.loadEntry(connectedUser);
    } else {
      redirectToLogin(this.props.history);
    }
  }

  loadEntry = ({uid, email}) => {
    const { contacts, addresses } = this.state;

    this.setState({ loading: true });

    firebase.firestore().collection('customers').where('__name__', '==' , uid).get().then(results => {
      let customer = null;

      results.forEach(result => {
        customer = result.data();
      });

      if (customer) {
        this.setState({
          loading: false,
          contacts: customer.contactInfo && customer.contactInfo.contacts && customer.contactInfo.contacts.length > 0 ? customer.contactInfo.contacts : contacts,
          addresses: customer.contactInfo && customer.contactInfo.addresses && customer.contactInfo.addresses.length > 0 ? customer.contactInfo.addresses : addresses,
        });
      } else {
        createCustomer({ fullName: email }).then(result => {
          this.loadEntry({uid});
        }).catch(error => {
          this.setState({ loading: false });
          alert(error.message);
        });
      }
    }).catch(error => {
      alert(JSON.stringify(error));
      this.setState({
        loading: false
      });
    });
  }

  onUpdateForm = (type, index, event) => {
    const { contacts, addresses } = this.state;
    const { target } = event;
    const { name, value } = target;

    if (type === 'contact') {
      contacts[index][name] = value;
      this.setState({ contacts });
    } else if (type === 'address') {
      addresses[index][name] = value;
      this.setState({ addresses });
    }
  }

  onSave = () => {
    const { contacts, addresses } = this.state;

    this.setState({ loading: true });

    updateCustomer({
      contactInfo: {
        contacts,
        addresses
      }
    }).then(result => {
      this.setState({ loading: false });
    }).catch(error => {
      this.setState({ loading: false });
      alert(error.message);
    });
  }

  render() {
    const { contacts, addresses, loading } = this.state;

  	return (
    	<div className="panel-screen">
    		<div className="panel-main justify-top">
    			<div className="table-wrapper">
            <div className="section-title">CONTACT INFO</div>
            <div className="form-section">
              <div className="cols cols-2">
                <div>
                  {
                    contacts.map((contact, index) => <div key={index}>
                      <div className="main-label">
                        {
                          !index
                          ? <Checkbox
                              checked={true} 
                              label="Primary Contact" 
                              onChange={() => {}}
                            />
                          : <span></span>
                        }

                        {
                          index
                          ? <span className="text-button" onClick={() => {
                            contacts.splice(index, 1);
                            this.setState({ contacts });
                          }}>Delete</span>
                          : null
                        }
                      </div>
                      <div className="form-cols cols-2">
                        <div className="formfield">
                          <label>First Name</label>
                          <input 
                            type="text" 
                            name="firstName"
                            value={contact.firstName}
                            onChange={event => this.onUpdateForm('contact', index, event)}
                          />
                        </div>
                        <div className="formfield">
                          <label>Last Name</label>
                          <input 
                            type="text" 
                            name="lastName" 
                            value={contact.lastName}
                            onChange={event => this.onUpdateForm('contact', index, event)}
                          />
                        </div>
                      </div>
                      <div className="formfield">
                        <label>Email</label>
                        <input 
                          type="email" 
                          name="email" 
                          value={contact.email}
                          onChange={event => this.onUpdateForm('contact', index, event)}
                        />
                      </div>
                      <div className="formfield">
                        <label>Title</label>
                        <input 
                          type="text" 
                          name="title" 
                          value={contact.title}
                          onChange={event => this.onUpdateForm('contact', index, event)}
                        />
                      </div>
                      <div className="formfield">
                        <label>Phone</label>
                        <input 
                          type="tel" 
                          name="phone" 
                          value={contact.phone}
                          onChange={event => this.onUpdateForm('contact', index, event)}
                        />
                      </div>
                      {/*
                      <div className="formfield">
                        <label>Password</label>
                        <div className="action-input">
                          <input type="password" />
                          <span className="text-button">Update</span>
                        </div>
                      </div>
                      */}
                      <div className="buttons-bar">
                        {
                          index === contacts.length-1
                          ? <span className="text-button" onClick={() => {
                            contacts.push(getContactObject());
                            this.setState({ contacts });
                          }}>+ Add Contact</span>
                          : <span></span>
                        }
                        <div className="group">
                          <button className="sp-button bordered disabled">Cancel</button>
                          <button className="sp-button bordered" onClick={this.onSave}>Edit</button>
                        </div>
                      </div>
                    </div>)
                  }
                </div>
                <div>
                  {
                    addresses.map((address, index) => <div key={index}>
                      <div className="main-label">
                        {
                          index === 0
                          ? <label>
                            <Checkbox 
                              checked={true}
                              label="Primary Address" 
                              onChange={() => {}}
                            />
                          </label>
                          : null
                        }
                        
                        {
                          index === 1
                          ? <label>
                            <Checkbox 
                              checked={true}
                              label="Secondary Address" 
                              onChange={() => {}}
                            />
                          </label>
                          : null
                        }

                        {
                          index > 1
                          ? <span></span>
                          : null
                        }
                        
                        {
                          index > 0
                          ? <span className="text-button" onClick={() => {
                            addresses.splice(index, 1);
                            this.setState({ addresses });
                          }}>Delete</span>
                          : null
                        }
                      </div>
                      <div className="form-cols cols-2">
                        <div className="formfield">
                          <label>Address</label>
                          <input 
                            type="text" 
                            name="address" 
                            value={address.address}
                            onChange={event => this.onUpdateForm('address', index, event)}
                          />
                        </div>
                        <div className="formfield">
                          <label>Unit or Suite #</label>
                          <input 
                            type="text" 
                            name="unitOrSuite" 
                            value={address.unitOrSuite}
                            onChange={event => this.onUpdateForm('address', index, event)}
                          />
                        </div>
                      </div>
                      <div className="formfield">
                        <label>Company Name</label>
                        <input 
                          type="text" 
                          name="companyName" 
                          value={address.companyName}
                          onChange={event => this.onUpdateForm('address', index, event)}
                        />
                      </div>
                      <div className="form-cols cols-2">
                        <div className="formfield">
                          <label>State</label>
                          <input 
                            type="text" 
                            name="state" 
                            value={address.state}
                            onChange={event => this.onUpdateForm('address', index, event)}
                          />
                        </div>
                        <div className="formfield">
                          <label>City</label>
                          <input 
                            type="text" 
                            name="city" 
                            value={address.city}
                            onChange={event => this.onUpdateForm('address', index, event)}
                          />
                        </div>
                      </div>
                      <div className="form-cols cols-2">
                        <div className="formfield">
                          <label>Zip</label>
                          <input 
                            type="text" 
                            name="zip" 
                            value={address.zip}
                            onChange={event => this.onUpdateForm('address', index, event)}
                          />
                        </div>
                        <div className="formfield">
                          <label>Country</label>
                          <Combobox 
                            value={address.country}
                            options={[
                              {key: '', value: ''}, 
                              {key: 'United States', value: 'United States'}, 
                              {key: 'Canada', value: 'Canada'}
                            ]} 
                            name="country"
                            onChange={event => this.onUpdateForm('address', index, event)}
                          />
                        </div>
                      </div>
                      <div className="buttons-bar">
                        {
                          index === addresses.length-1
                          ? <span className="text-button"onClick={() => {
                            addresses.push(getAddressObject());
                            this.setState({ addresses });
                          }}>+ Add Address</span>
                          : <span></span>
                        }
                        <div className="group">
                          <button className="sp-button bordered disabled">Cancel</button>
                          <button className="sp-button bordered" onClick={this.onSave}>Edit</button>
                        </div>
                      </div>
                    </div>)
                  }
                </div>
              </div>
            </div>
    			</div>
    		</div>

        {
          loading
          ? <LoadingWheel />
          : null
        }
			</div>
		)
  }
}
