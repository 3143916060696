export function isWindowsOS() {
	return navigator.userAgent.toLowerCase().indexOf('windows') !== -1;
}

export function getEmailValidationRegex() {
	return /\S+@\S+\.\S+/;
}

export function formatAddress(address) {
	let addr = [];

	if (address) {
		['address', 'city', 'state', 'zip', 'country'].map(key => {
			if (address[key]) {
				addr.push(address[key]);
			}

			return addr;
		});

		if (address.unitOrSuite) {
			addr.push(address.unitOrSuite);
		}
	}

	return addr.join(', ');
}
