import React from 'react'
import classNames from 'classnames'
import { isWindowsOS } from '../helpers/global';

export default function Badge(props) {
	const { 
		name, 
		name2, 
		title, 
		style, 
		inputStyle, 
		showTitle, 
		onChangeName, 
		onChangeName2, 
		onBlurName2, 
		onChangeTitle, 
		onBlurTitle, 
		logo, 
		trim 
	} = props;
	const coef = 0.5;
	const titleFontSize = inputStyle.fontSize.replace('px', '') * coef + 'px';
	const hasLogo = logo && !!logo.id;

	return <div className={classNames('badge', {'has-logo': hasLogo})} style={style}>
  	<div className="input-holder input-holder-name">
  		<textarea style={inputStyle}></textarea>

	  	<input 
	  		type="text" 
	  		value={name}
	  		style={Object.assign({}, inputStyle, {
	  			paddingTop: !isWindowsOS() && inputStyle.fontFamily === 'HelveticaNeueLTStd-BdCn' ? (inputStyle.fontSize.replace('px', '') / 3) : 0
	  		})}
	  		onChange={event => {
  				const textarea = event.target.previousElementSibling;
  				const { value } = event.target;
  				textarea.value = value;
  				let lines = Math.round(textarea.scrollHeight / textarea.clientHeight);

  				if (lines > 1) {
  					const pos = value.indexOf(' ');

						onChangeName(value.substring(0, pos));
						onChangeName2(value.substring(pos).trim());

						event.target.nextElementSibling.focus();
  				} else {
  					onChangeName(event.target.value);
  				}
	  		}} 
	  		onBlur={event => {
	  			/* onBlurName(event.target.value); */
	  		}}
	  		onKeyUp={({target, keyCode}) => {
	  			if (keyCode === 13) {
	  				const { value, selectionStart } = target;
	  				const name2 = value.substring(selectionStart);
	  				onChangeName(value.substring(0, selectionStart));
  					onChangeName2(name2 ? name2 : ' ');
  					setTimeout(() => {
  						document.querySelector('.panel-screen .name-second').focus();
  					}, 100);
	  			}
	  		}}
	  		placeholder="Enter name..."
	  		className="name-first"
	  	/>

  		<input 
	  		type="text" 
	  		value={name2} 
	  		style={Object.assign({}, inputStyle, {
	  			paddingTop: !isWindowsOS() && inputStyle.fontFamily === 'HelveticaNeueLTStd-BdCn' ? (inputStyle.fontSize.replace('px', '') / 3) : 0
	  		})}
	  		onChange={({target}) => onChangeName2(target.value.length > 0 && target.value[0] === ' ' ? target.value.substring(1) : target.value)} 
	  		onBlur={event => onBlurName2(event.target.value)}
	  		onKeyUp={event => {
	  			if (event.keyCode === 8 && event.target.selectionStart === 0) {
  					setTimeout(() => {
  						document.querySelector('.panel-screen .name-first').focus();
  						onChangeName(name + name2);
							onChangeName2('');
  					}, 100);
	  			}
	  		}}
	  		placeholder="..."
	  		className={name2 ? 'name-second' : 'is-hidden'}
	  	/>

	  	<div className="input-border input-border-left" style={{ top: trim, bottom: trim }}></div>
      <div className="input-border input-border-right" style={{ top: trim, bottom: trim }}></div>
      <div className="input-border input-border-top" style={{ top: trim }}></div>
      <div className="input-border input-border-bottom" style={{ bottom: trim }}></div>
  	</div>

  	{
  		showTitle
  		? <div className="input-holder input-holder-title" style={{ marginTop: (22 - inputStyle.fontSize.replace('px', '') / 2) * -1 }}>
	  		<input 
	  			type="text" 
	  			value={title} 
	  			className="title-input"
	  			style={Object.assign({}, inputStyle, { 
	  				fontSize: titleFontSize,
	  				fontFamily: 'Helvetica Neue LT W1G',
	  				paddingTop: !isWindowsOS() && inputStyle.fontFamily === 'Helvetica Neue' ? (titleFontSize.replace('px', '') / 3) : 0
	  			})} 
	  			onChange={event => onChangeTitle(event.target.value)} 
	  			onBlur={event => onBlurTitle(event.target.value)}
	  		/>

	  		<div className="input-border input-border-left" style={{ top: trim * coef, bottom: trim * coef }}></div>
	      <div className="input-border input-border-right" style={{ top: trim * coef, bottom: trim * coef }}></div>
	      <div className="input-border input-border-top" style={{ top: trim * coef }}></div>
	      <div className="input-border input-border-bottom" style={{ bottom: trim * coef }}></div>
  		</div>
  		: null
  	}

  	{
  		logo && logo.id
  		? <img className="badge-logo" src={logo.name} alt="Badge logo" />
  		: null
  	}
	</div>
}
