import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import firebase from '../../firebase'
import { isUserAdmin, redirectToLogin } from '../../firebaseHelpers'
import PhotoGrid from '../../components/PhotoGrid'
import LoadingWheel from '../../components/LoadingWheel'
import { Back } from '../../icons'

const getOrderImageDownloadURLs = firebase.functions().httpsCallable('getOrderImageDownloadURLs');
const getOrderImagesZIPDownloadURL = firebase.functions().httpsCallable('getOrderImagesZIPDownloadURL');
const sendImagesToPrinterForOrder = firebase.functions().httpsCallable('sendImagesToPrinterForOrder');

export default class AdminOrderScreen extends Component {
	constructor() {
  	super();

  	this.state = { 
      loading: false,
  		images: [],
  		order: null,
      form: {
      	id: '',
      	name: '',
      	email: '',
      	address: '',
      	zip: '',
      	city: '',
      	state: '',
      	country: '',
      	promo: '',
      	quantity: '',
        tileSize: ''
      }
  	};
  }

  componentDidMount = async () => {
    const isAdmin = await isUserAdmin();

    if (isAdmin) {
      this.loadEntry();
    } else {
      redirectToLogin(this.props.history);
    }
  }

  loadEntry = () => {
    const { match } = this.props;

    this.setState({ loading: true  });

    firebase.firestore().collection('orders').where('__name__', '==' , match.params.id).get().then(results => {
      results.forEach(result => {
        const data = result.data();

        this.setState({
          order: data,
          form: {
            id: data.id,
		      	name: '',
		      	email: data.email,
		      	address: data.address ? data.address.address : '',
		      	zip: data.address ? data.address.zip : '',
		      	city: data.address ? data.address.city : '',
		      	state: data.address ? data.address.state : '',
		      	country: '',
		      	promo: '',
		      	quantity: '',
		        tileSize: data.tileSize
          }
        });

        getOrderImageDownloadURLs({ id: match.params.id, token: data.token }).then(({data}) => {
        	const ids = Object.keys(data)

	        this.setState({
	          loading: false,
	          images: Object.values(data).map((image, index) => {
	            return { 
	              id: ids[index],
	              url: image 
	            }
	          })
	        });
			  }).catch(error => {
			    alert(JSON.stringify(error));
			    this.setState({
		        loading: false
		      });
			  });
      });
    }).catch(error => {
      alert(JSON.stringify(error));
      this.setState({
        loading: false
      });
    });
  }

  onUpdateForm = event => {}

	render() {
		const { loading, order, form, images } = this.state;
		const imagesSent = order && ['imagesSent', 'shipped'].indexOf(order.state) !== -1;

  	return (
    	<div className="panel-screen">
    		<div className="panel-main justify-top">
    			<div className="table-wrapper">
    				<div className="admin-action">
    					<Link to="/admin" className="text-button">
    						<Back />
    						Back
  						</Link>
    					<div className="admin-action-buttons">
    						<Link to="/admin" className="sp-button bordered faded">Cancel</Link>
								<button className="sp-button bordered blue" onClick={() => {
									this.setState({ loading: true });
									sendImagesToPrinterForOrder({
										id: order.id
									}).then(async ({data}) => {
										this.setState({ loading: false });
										alert('The images were uploaded to this folder: ' + data);
									}).catch(error => {
										this.setState({ loading: false });
										alert(error.message ? error.message : JSON.stringify(error));
									});
								}}>
 									{imagesSent ? 'Resend' : 'Send'} to printer
								</button>
 								{
									imagesSent
									? <button className="sp-button bordered blue">	
	 									Images Sent
	 									<span className="r-icon">
		 									<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M6.5 0C2.912 0 0 2.912 0 6.5C0 10.088 2.912 13 6.5 13C10.088 13 13 10.088 13 6.5C13 2.912 10.088 0 6.5 0ZM5.2 9.75L1.95 6.5L2.8665 5.5835L5.2 7.9105L10.1335 2.977L11.05 3.9L5.2 9.75Z" fill="#6EE18E"/>
											</svg>
										</span>
									</button>
									: null
								}
    					</div>
    				</div>
            <div className="section-subtitle">Created</div>
            {
              order
              ? <div className="section-info">
                { moment(order.createdAt.seconds * 1000).format('dddd, MMMM D, YYYY') } at { moment(order.createdAt.seconds * 1000).format('hh:mm:ss A Z') }
              </div>
              : null
            }

            <div className="section-subtitle">ORDER INFORMATION</div>

            <div className="form-section form-section-cols">
	            <div className="formfield">
	            	<label>Order #</label>
	            	<input type="text" value={ form.id } name="id" onChange={ this.onUpdateForm } />
	            </div>
	            <div className="formfield">
	            	<label>Name</label>
	            	<input type="text" value={ form.name } name="name" onChange={ this.onUpdateForm } />
	            </div>
	            <div className="formfield">
	            	<label>Email</label>
	            	<input type="email" value={ form.email } name="email" onChange={ this.onUpdateForm } />
	            </div>
	            <div className="formfield">
	            	<label>Address</label>
	            	<input type="text" value={ form.address } name="address" onChange={ this.onUpdateForm } />
	            </div>
	            <div className="formfield">
	            	<label>Zip</label>
	            	<input type="text" value={ form.zip } name="zip" onChange={ this.onUpdateForm } />
	            </div>
	            <div className="formfield">
	            	<label>City</label>
	            	<input type="text" value={ form.city } name="city" onChange={ this.onUpdateForm } />
	            </div>
	            <div className="formfield">
	            	<label>State</label>
	            	<input type="text" value={ form.state } name="state" onChange={ this.onUpdateForm } />
	            </div>
	            <div className="formfield">
	            	<label>Country</label>
	            	<input type="text" value={ form.country } name="country" onChange={ this.onUpdateForm } />
	            </div>
	            <div className="formfield">
	            	<label>PROMO</label>
	            	<input type="text" value={ form.promo } name="promo" onChange={ this.onUpdateForm } />
	            </div>
	            <div className="formfield">
	            	<label>QUANTITY</label>
	            	<input type="text" value={ form.quantity } name="quantity" onChange={ this.onUpdateForm } />
	            </div>
	            <div className="formfield">
	            	<label>Size</label>
	            	<input type="text" value={ form.tileSize } name="tileSize" onChange={ this.onUpdateForm } />
	            </div>
	            <div className="formfield">
	            	<label>PAYMENT AMT</label>
	            	<input type="text" />
	            </div>
	            <div className="formfield">
	            	<label>PAYMENT ID</label>
	            	<input type="text" />
	            </div>
            </div>

            <div className="gallery-holder">
            	<div className="subtitle-bar">
            		<div className="section-subtitle">ORDER IMAGES</div>
            		<div className="text-button" onClick={() => {
            			this.setState({ loading: true });
            			getOrderImagesZIPDownloadURL({ id: order.id, token: order.token, format: 'pdf' }).then(({data}) => {
				        		window.location.href = data;
				        		this.setState({
							        loading: false
							      });
								  }).catch(error => {
								    alert(JSON.stringify(error));
								    this.setState({
							        loading: false
							      });
								  });
            		}}>Download All</div>
          		</div>
            	<PhotoGrid 
            		images={images} 
          		/>
          	</div>

          	{
              loading
              ? <LoadingWheel />
              : null
            }
    			</div>
    		</div>
			</div>
		)
  }
}
