import firebase from 'firebase'

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyDL2HtcFcE8kscj25lo4MOqSH46yqir_gg",
    authDomain: "sweet-pix-order-portal-2021.firebaseapp.com",
    projectId: "sweet-pix-order-portal-2021",
    storageBucket: "sweet-pix-order-portal-2021.appspot.com",
    messagingSenderId: "363179639511",
    appId: "1:363179639511:web:60e0169e38bb7d097a6692",
    measurementId: "G-LG72CMJ5ZB"
})

export default firebaseApp
