import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { prepend } from '../helpers/cropping'
import * as photosActions from '../actions/photosActions'
import DragAndDropUploader from '../components/DragAndDropUploader'
// import icon from '../img/icon-upload.png'

class UploadScreen extends Component {
  dropRef = React.createRef();
  dragCounter = 0;
  
  constructor() {
  	super();

  	this.state = { 
  		dragging: false
  	};
  }

  componentDidMount() {
    // const div = this.dropRef.current;
    // div.addEventListener('dragenter', this.handleDragIn);
    // div.addEventListener('dragleave', this.handleDragOut);
    // div.addEventListener('dragover', this.handleDrag);
    // div.addEventListener('drop', this.handleDrop);
    
    if (window.ga)
      window.ga('send', 'pageview', '/upload');
  }
  
  componentWillUnmount() {
    // const div = this.dropRef.current;
    // div.removeEventListener('dragenter', this.handleDragIn);
    // div.removeEventListener('dragleave', this.handleDragOut);
    // div.removeEventListener('dragover', this.handleDrag);
    // div.removeEventListener('drop', this.handleDrop);
  }

  onAdd = (e) => {
  	const { target, dataTransfer } = e;
  	const files = dataTransfer ? dataTransfer.files : target.files;
	  const { photos, onChangeHeaderState } = this.props;

    window.fbq('track', 'AddToCart', { count: files.length });

  	for (let i in files) {
  		if (typeof files[i] === 'object') {
        if (['image/jpeg', 'image/png', 'image/raw'].indexOf(files[i].type) !== -1) {
          const photo = prepend(files[i]);
          photos.push(photo);
        }
  		}
  	}

    this.props.photosActions.receivePhotos(photos);
    onChangeHeaderState();
    this.props.history.push('/photos');
  }

  handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  }
  
  handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({dragging: true});
    }
  }
  
  handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.setState({dragging: false});
    }
  }
  
  handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({dragging: false});
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      // e.dataTransfer.clearData();
      this.dragCounter = 0;
      this.onAdd(e);
    }
  }

  render() {
  	// const { dragging } = this.state;
    
    return (
    	<div>
	    	<div className="content image-bkg">
          <DragAndDropUploader />
  			</div>
  		</div>
  	)
  }
}

function mapStateToProps(state, action) {
  return {
    photos: state.photos
  }
}
function mapDispatchToProps(dispatch) {
  return {
    photosActions: bindActionCreators(photosActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UploadScreen));
