import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, Redirect } from 'react-router-dom';
import LoadingWheel from '../components/LoadingWheel';
import axios from 'axios';
import { getEmailValidationRegex } from '../helpers/global';
import { getTotalPrice, getMinQty, getMinQtyPrice, getAdditionalPrice, getShippingCosts, getPromoCodeDiscount } from '../helpers/pricing';
import * as addressActions from '../actions/addressActions';
import * as promoCodeActions from '../actions/promoCodeActions';
import { API_URL2 } from '../config.js';
import icon from '../img/icon-cc.png';
import iconClose from '../img/icon-close.png';

class CheckoutScreen extends Component {
	constructor() {
		super();

		this.countries = ['United States', 'Canada'];
		this.state = {
			address: {
				name: '',
				email: '',
				address: '',
				company: '',
				city: '',
				state: '',
				country: '',
				zip: '',
				code: ''
			},
	        errors: {
	            name: false,
				email: false,
				address: false,
				city: false,
				state: false,
				country: false,
				zip: false,
				code: false
	        },
	        loading: false,
	        close: false,
	        valid: false
		};
	}

	componentDidMount() {
		const { photos, address } = this.props;

		if (photos.length < 2) {
			this.props.history.push('/photos');
		}
		if (address) {
			this.setState({ address: Object.assign(this.state.address, address) });
		}

		if (window.ga) {
			window.ga('send', 'pageview', '/checkout');
			window.ga('send', 'event', { eventCategory: 'Checkout', eventAction: 'entered' });
		}
		
		document.getElementsByTagName('body')[0].className = 'checkout';
	}

	componentWillUnmount() {
		const body = document.getElementsByTagName('body')[0];
		body.className = body.className.replace('checkout', '');
	}

	updateFormModel = (e) => {
	    const { address, errors } = this.state;
	    const { target } = e;
	    const { name, value } = target;

	    address[name] = value;
	    errors[name] = false;

	    const newState = {
	        address: address,
	        errors: errors
	    };

	    if (name === 'code') {
	    	newState.valid = false;
	    }

	    this.setState(newState);
  	}

  	onSubmit = (e) => {
	    e.preventDefault();
	    const { address, errors } = this.state;
	    let hasErrors = false;

	    if (address.name === '') {
	        errors.name = true;
	        hasErrors = true;
	    }

	    if (!getEmailValidationRegex().test(address.email)) {
	        errors.email = true;
	        hasErrors = true;
	    }

	    if (address.address === '') {
	        errors.address = true;
	        hasErrors = true;
	    }

	    if (address.city === '') {
	        errors.city = true;
	        hasErrors = true;
	    }

	    if (address.state === '') {
	        errors.state = true;
	        hasErrors = true;
	    }

	    if (address.country === '') {
	        errors.country = true;
	        hasErrors = true;
	    }

	    if (address.zip === '') {
	        errors.zip = true;
	        hasErrors = true;
	    }

	    this.setState({ errors });

	    if (!hasErrors) {
	    	window.fbq('track', 'InitiateCheckout');
	    	window.ga('send', 'event', { eventCategory: 'ShippingForm', eventAction: 'completed' });
	    	this.props.addressActions.receiveAddress(address);
	        this.props.history.push('/pay');
	    }
	}

	onClose = () => {
		this.setState({ close: true });
	}

	onApply = () => {
		const { address } = this.state;
		const { code } = address;

		this.setState({ loading: true });
		axios.post(API_URL2 + '/validatePromoCode', { code }).then((response) => {
			this.props.promoCodeActions.receivePromoCode(response.data.data);
			this.forceUpdate();
			this.setState({ loading: false, valid: true });
		}).catch((error) => {
			const { errors } = this.state;
			this.setState({
				loading: false,
				errors: Object.assign(errors, { code: error.response.data.error.message ? error.response.data.error.message : 'Code is not valid' })
			});
		});
	}

	render() {
		const { countries } = this;
		const { address, errors, close, loading, valid } = this.state;
  		const { photos, promoCode } = this.props;
  		const minQty = getMinQty();
  		const minQtyPrice = getMinQtyPrice();
  		const existingMinQty = photos.length > minQty ? minQty : photos.length;
  		const additionalQty = photos.length > minQty ? (photos.length - minQty) : 0;
  		const discount = getPromoCodeDiscount(promoCode, photos, getShippingCosts(address.country));

  		if (close) {
			return <Redirect to='/photos' />
  		}

    	return (
    		<div className="content content-modal">
	    		<div className={ 'photo-grid photo-grid-' + photos.length }>
	    			{
	    				photos.map((photo, i) => {
	    					return (<div className="tile" key={i}>
	    								<div className="photo">
	    									{ photo.loading ? <LoadingWheel /> : <img src={ photo.selection } alt="Tile" /> }
	    								</div>
									</div>)
	    				})
	    			}
	    			<div className="add-more"></div>
				</div>

				{
    				loading ? 
    				<LoadingWheel /> 
    				: null
    			}

				<div className="global-modal global-modal-checkout" onClick={ this.onClose }>
					<div className="outer" onClick={ (e) => { e.stopPropagation(); } }>
						<Link to="/photos" className="close">
							<img src={ iconClose } alt="Close" />
						</Link>

						<div className="inner">
							<div className="title">Shipping Address</div>

							<form className="form" onSubmit={ this.onSubmit } noValidate={ true }>
								<div className="formfield">
									<input type="text" 
											placeholder="Full Name" 
											value={address.name} 
											name="name" 
											className={ errors.name ? 'error' : '' } 
											noValidate={ true } 
                                			onChange={ this.updateFormModel }
									/>
								</div>
								<div className="formfield">
									<input type="email" 
											placeholder="Email" 
											value={address.email} 
											name="email" 
											className={ errors.email ? 'error' : '' } 
											noValidate={ true } 
                                			onChange={ this.updateFormModel }
									/>
								</div>
								<div className="formfield">
									<input type="text" 
											placeholder="Street Address Apt/Unit" 
											value={address.address} 
											name="address" 
											className={ errors.address ? 'error' : '' } 
											noValidate={ true } 
                                			onChange={ this.updateFormModel }
									/>
								</div>
								<div className="formfield">
									<input type="text" 
											placeholder="Company (Optional)" 
											value={address.company} 
											name="company" 
											className={ errors.company ? 'error' : '' } 
											noValidate={ true } 
                                			onChange={ this.updateFormModel }
									/>
								</div>
								<div className="formfield">
									<div className="col">
										<input type="text" 
											placeholder="City" 
											value={address.city} 
											name="city" 
											className={ errors.city ? 'error' : '' } 
											noValidate={ true } 
                                			onChange={ this.updateFormModel }
										/>
									</div>
									<div className="col">
										<input type="text" 
												placeholder="State" 
												value={address.state} 
												name="state" 
												className={ errors.state ? 'error' : '' } 
												noValidate={ true } 
	                                			onChange={ this.updateFormModel }
										/>
									</div>
								</div>
								<div className="formfield">
									<div className="col">
										<select value={address.country} name="country" className={ errors.country ? 'error' : '' } onChange={ this.updateFormModel }>
											<option value="">Country</option>
											{
												countries.map((country, i) => {
													return <option key={i} value={country === 'Canada' ? 'CA' : 'US'}>{ country }</option>
												})
											}
										</select>
									</div>
									<div className="col">
										<input type="text" 
												placeholder="Postal Code" 
												value={address.zip} 
												name="zip" 
												className={ errors.zip ? 'error' : '' } 
												noValidate={ true } 
	                                			onChange={ this.updateFormModel }
										/>
									</div>
								</div>
								<div className="formfield">
									<div className="col">
										<input type="text" 
											placeholder="Promo Code" 
											value={address.code} 
											name="code" 
											className={ errors.code ? 'error' : '' } 
											noValidate={ true } 
                                			onChange={ this.updateFormModel }
                                			onBlur={ this.onApply }
										/>
										{
											errors.code
											? <div className="error-msg">{ errors.code }</div>
											: null
										}
										{
											valid
											? <div className="error-msg">Code applied</div>
											: null
										}
									</div>
									<div className="col">
										<span className="fake-button">Apply</span>
									</div>
								</div>
								<dl>
									<dt>{ existingMinQty } { existingMinQty === 1 ? 'tile' : 'tiles' } for ${ minQtyPrice }</dt>
									<dd>${ minQtyPrice }</dd>
									
									{ photos.length > minQty ? <dt>{ additionalQty } additional { additionalQty === 1 ? 'tile' : 'tiles' }</dt> : null }
									{ photos.length > minQty ? <dd>${ getAdditionalPrice(photos) }</dd> : null }
									
									{ promoCode ? <dt>Discount ({ promoCode.type === 3 ? 'Free Shipping' : ( promoCode.type === 2 ? (promoCode.amount + '%') : ('$' + promoCode.amount) ) })</dt> : null }
									{ promoCode ? <dd>{ discount > 0 ? '-' : '' }${ discount }</dd> : null }

									<dt>Shipping</dt>
									<dd>{ getShippingCosts(address.country) > 0 ? ('$' + getShippingCosts(address.country)) : 'FREE' }</dd>

									<dt className="total">Total</dt>
									<dd className="total">${ getTotalPrice(photos, address.country, promoCode).toFixed(2).toString().replace('.00', '') }</dd>
								</dl>
								<button type="submit" className="action-button size-x">PAY NOW</button>
								<figure>
									<img src={ icon } alt="Credit cards" />
								</figure>
							</form>
						</div>
					</div>
				</div>
			</div>
		)
  	}
}

function mapStateToProps(state, action) {
  return {
    photos: state.photos,
    address: state.address,
    promoCode: state.promoCode
  }
}
function mapDispatchToProps(dispatch) {
  return {
  	addressActions: bindActionCreators(addressActions, dispatch),
  	promoCodeActions: bindActionCreators(promoCodeActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckoutScreen);
