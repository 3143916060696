import React, { Component } from 'react'
import moment from 'moment'
import firebase from '../../firebase'
import { Link } from 'react-router-dom'
import { isUserAdmin, redirectToLogin } from '../../firebaseHelpers'
import Checkbox from '../../components/Checkbox'
import LoadingWheel from '../../components/LoadingWheel'
import Combobox from '../../components/Combobox'
import Datepicker from '../../components/Datepicker'
import { Back } from '../../icons'

const createPromoCode = firebase.functions().httpsCallable('createPromoCode');
const modifyPromoCode = firebase.functions().httpsCallable('modifyPromoCode');

export default class AdminPromoCodeScreen extends Component {
	constructor() {
  	super();

  	this.state = { 
  		isEdit: false,
      loading: false,
      currentEntryTitle: '',

      form: {
        code: '',
        size: '',
        startDate: '',
        endDate: '',
        type: 'percentage',
        amount: 0,
        oneTimeUse: false,
        validFor2TilesFree: false,
        validForAllOrders: false,
        validForFreeShipping: false
      }
  	};
  }

  componentDidMount = async () => {
    const isAdmin = await isUserAdmin();
    const { match } = this.props;

    if (isAdmin) {
      if (match.params.id !== 'new') {
        this.setState({
          isEdit: match.params.id,
          loading: true 
        });

        this.loadEntry();
      }
    } else {
      redirectToLogin(this.props.history);
    }
  }

  loadEntry = () => {
    const { match } = this.props;

    firebase.firestore().collection('promoCodes').where('__name__', '==' , match.params.id).get().then(results => {
      results.forEach(result => {
        const data = result.data();

        this.setState({
          currentEntryTitle: data.code,
          form: {
            code: data.code,
            size: data.size,
            startDate: moment(data.startDate.seconds * 1000).format('YYYY-MM-DD'),
            endDate: data.endDate ? moment(data.endDate.seconds * 1000).format('YYYY-MM-DD') : '',
            type: data.type,
            amount: data.amount,
            oneTimeUse: data.oneTimeUse,
            validFor2TilesFree: data.validFor2TilesFree,
            validForAllOrders: data.validForAllOrders,
            validForFreeShipping: data.validForFreeShipping
          },
          loading: false
        });
      });
    }).catch(error => {
      alert(JSON.stringify(error));
      this.setState({
        loading: false
      });
    });
  }

  onUpdateForm = event => {
    const { form } = this.state;
    const { target } = event;
    form[target.name] = target.value;
    this.setState({ form });
  }

  onSave = () => {
    const { form } = this.state;

    this.setState({ loading: true });

    createPromoCode({
      code: form.code,
      size: form.size,
      startDate: moment(form.startDate) / 1000,
      endDate: form.endDate ? moment(form.endDate) / 1000 : '',
      type: form.type,
      amount: parseFloat(form.amount),
      oneTimeUse: form.oneTimeUse,
      validFor2TilesFree: form.validFor2TilesFree,
      validForAllOrders: form.validForAllOrders,
      validForFreeShipping: form.validForFreeShipping
    }).then(result => {
      this.setState({ loading: false });
      this.props.history.push('/admin/promo-codes');
    }).catch(error => {
      this.setState({ loading: false });
      alert(error.message);
    });
  }

  onUpdate = () => {
    const { form } = this.state;

    this.setState({ loading: true });

    modifyPromoCode({
      code: form.code,
      size: form.size,
      startDate: moment(form.startDate) / 1000,
      endDate: form.endDate ? moment(form.endDate) / 1000 : '',
      type: form.type,
      amount: parseFloat(form.amount),
      oneTimeUse: form.oneTimeUse,
      validFor2TilesFree: form.validFor2TilesFree,
      validForAllOrders: form.validForAllOrders,
      validForFreeShipping: form.validForFreeShipping
    }).then(result => {
      this.setState({ loading: false });
      this.props.history.push('/admin/promo-codes');
    }).catch(error => {
      this.setState({ loading: false });
      alert(error.message);
    });
  }

  render() {
    const { isEdit, loading, form, currentEntryTitle } = this.state;

  	return (
    	<div className="panel-screen">
    		<div className="panel-main justify-top">
    			<div className="table-wrapper">
    				<div className="admin-action">
    					<Link to="/admin/promo-codes" className="text-button">
    						<Back />
    						Back
  						</Link>
    					<div className="admin-action-buttons">
    						<Link to="/admin/promo-codes" className="sp-button bordered faded">Cancel</Link>
                {
                  isEdit
                  ? <button className="sp-button" onClick={ this.onUpdate }>Save</button>
                  : <button className="sp-button" onClick={ this.onSave }>Save</button>
                }
    					</div>
    				</div>

            <div className="section-entity">
              {
                isEdit
                ? <>
                  CODE: <strong>{ currentEntryTitle }</strong>
                </>
                : <>NEW CODE</>
              }
  					</div>
            
            <div className="form-section form-section-cols">
	            <div className="formfield">
	            	<label>Code</label>
                <input 
                  type="text" 
                  value={form.code} 
                  name="code" 
                  onChange={this.onUpdateForm} 
                  readOnly={isEdit}
                />
	            </div>
	            <div className="formfield">
	            	<label>Size</label>
	            	<Combobox 
                  name="size"
                  value={form.size}
                  options={[
  	            		{key: '', value: '-- Select --'}, 
  	            		{key: 'all', value: 'ALL'}, 
  	            		{key: '8x8', value: '8x8'}, 
  	            		{key: '12x12', value: '12x12'}
              		]} 
                  onChange={ this.onUpdateForm }
                />
	            </div>
	            <div className="formfield">
	            	<label>Start date</label>
	            	<Datepicker 
                  name="startDate"
                  value={form.startDate}
                  onChange={ this.onUpdateForm }
                />
	            </div>
            	<div className="formfield">
	            	<label>End date</label>
	            	<Datepicker 
                  name="endDate"
                  value={form.endDate}
                  onChange={ this.onUpdateForm }
                />
	            </div>
	            <div className="formfield">
	            	<label>Type</label>
	            	<Combobox 
                  name="type"
                  value={form.type}
                  options={[
  	            		{key: 'percentage', value: 'Percentage'}, 
  	            		{key: 'discount', value: 'Discount Off'}, 
  	            		{key: 'free', value: 'Free'}
              		]} 
                  onChange={ this.onUpdateForm }
                />
	            </div>
	            <div className="formfield">
	            	<label>Amount</label>
	            	<input 
                  type="number" 
                  name="amount"
                  value={form.amount}
                  onChange={ this.onUpdateForm }
                />
	            </div>
	            <div className="formfield">
	            	<label>One-time use</label>
	            	<Combobox 
                  value={form.oneTimeUse ? 'yes' : 'no'}
                  options={[
  	            		{key: 'no', value: 'No'}, 
  	            		{key: 'yes', value: 'Yes'}
              		]} 
                  onChange={event => {
                    form.oneTimeUse = event.target.value === 'yes';
                    this.setState({ form });
                  }}
                />
	            </div>
            </div>

            <div className="section-subtitle">CODE IS VALID FOR</div>
            <div className="checkboxes-list stacked">
	            <Checkbox 
                label="2 tiles free" 
                checked={form.validFor2TilesFree}
                onChange={event => {
                  form.validFor2TilesFree = !form.validFor2TilesFree;
                  this.setState({ form });
                }}
              />
	            <Checkbox 
                label="Valid for all orders" 
                checked={form.validForAllOrders}
                onChange={event => {
                  form.validForAllOrders = !form.validForAllOrders;
                  this.setState({ form });
                }}
              />
	            <Checkbox 
                label="Free shipping" 
                checked={form.validForFreeShipping}
                onChange={event => {
                  form.validForFreeShipping = !form.validForFreeShipping;
                  this.setState({ form });
                }}
              />
            </div>

            {
              loading
              ? <LoadingWheel />
              : null
            }

    			</div>
    		</div>
			</div>
		)
  }
}
