import React, { Component } from 'react'
import axios from 'axios'
import EditorContext from '../contexts/EditorContext'
import firebase from '../firebase'
import { uploadFile } from '../firebaseHelpers'
import { get8x8Price, get12x12Price } from '../helpers/pricing'
import { APP_VERSION } from '../config.js'

const getCustomerAccessibleImageCollections = firebase.functions().httpsCallable('getCustomerAccessibleImageCollections');
const getImageCollectionImages = firebase.functions().httpsCallable('getImageCollectionImages');
const createOrder = firebase.functions().httpsCallable('createOrder');
const addImageToOrder = firebase.functions().httpsCallable('addImageToOrder');
const addImagesToOrder = firebase.functions().httpsCallable('addImagesToOrder');
const removeImageFromOrder = firebase.functions().httpsCallable('removeImageFromOrder');
const getOrderImageDownloadURLs = firebase.functions().httpsCallable('getOrderImageDownloadURLs');
const modifyOrder = firebase.functions().httpsCallable('modifyOrder');
const getOrder = firebase.functions().httpsCallable('getOrder');

const imagesPerPage = 20;
const lowResolutionLimit = 640;
const coefficient = 1.77;

const getImageSize = url => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height
      });
    }
    img.onerror = () => {
      resolve({
        width: 0,
        height: 0
      });
    }
    img.src = url;
  });
}

const getImageContents = image => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      resolve(reader.result);
    }, false);

    reader.readAsDataURL(image);
  });
}

const prepareImage = (finalSize, image, logos) => {
  const { data, width, height, zoom, isFlipped, imageStyle, positionLeft, positionTop } = image;

  return new Promise((resolve, reject) => {
    const img = new Image();

    img.width = width;
    img.height = height;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d', {alpha: false});
      const min = Math.min(img.width, img.height);
      const imageSize = finalSize * zoom / 100;

      canvas.width = finalSize;
      canvas.height = finalSize;

      if (isFlipped) {
        context.translate(finalSize, 0);
        context.scale(-1, 1); 
      }

      const scale = Math.min(img.width, img.height) / imageSize;

      context.drawImage(
        img, 
        (img.width > img.height ? ((img.width - img.height) / 2) : 0) - positionLeft * coefficient * scale / (isFlipped ? -1 : 1),
        (img.height > img.width ? ((img.height - img.width) / 2) : 0) - positionTop * coefficient * scale / 1,
        min, 
        min,
        (finalSize - imageSize) / 2, 
        (finalSize - imageSize) / 2, 
        imageSize, 
        imageSize
      );

      if (imageStyle !== 'none') {
        const imageData = context.getImageData(0, 0, finalSize, finalSize);
        const data = imageData.data;

        for (let i=0; i<data.length; i+=4) {
          const brightness = 0.34 * data[i] + 0.5 * data[i + 1] + 0.16 * data[i + 2];
          data[i] = brightness;
          data[i+1] = brightness;
          data[i+2] = brightness;
        }

        context.putImageData(imageData, 0, 0);
      }

      resolve(canvas.toDataURL());
    };

    img.src = data;
  });
}

const rotate = (image, size, degrees=90) => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.width = size;
    img.height = size;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d', {alpha: false});

      canvas.width = size;
      canvas.height = size;

      context.translate(canvas.width / 2, canvas.height / 2);
      context.rotate(degrees*Math.PI / 180);
      context.drawImage(img,-img.width / 2, -img.width / 2);

      resolve(canvas.toDataURL());
    };

    img.src = image;
  });
}

const generateImage = (state, image) => {
  return new Promise(async (resolve, reject) => {
    const size = 1000;
    const {
      showSettings,
      fontSize, 
      fontFamily, 
      textTransform, 
      align, 
      color, 
      backgroundColor,
      extended,
      logos
    } = state;

    const padding = extended ? 0 : 32 * coefficient;
    const badgeWidth = 331;

    let data = await prepareImage(size, image, logos);
    data = await rotate(data, size, image.angle);

    const img = new Image();
    img.width = size;
    img.height = size;
    img.onload = async () => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d', {alpha: false});

      canvas.width = size;
      canvas.height = size;

      context.drawImage(
        img, 
        0,
        0, 
        size, 
        size, 
        0, 
        0, 
        size, 
        size
      );

      const logo = logos.find(logo => logo.id === image.logo);
      const hasLogo = logo && logo.id;
      const logoLeftSpacing = align === 'left' && hasLogo ? 50 : 0

      if (image.nameBadge && showSettings) {
        const fontFamilyPieces = fontFamily.split(':');
        const leftPosition = align === 'left' ? padding : (align === 'center' ? ((size - badgeWidth * coefficient) / 2) : (size - padding - (badgeWidth + 1) * coefficient));
        const topPosition = size - 35 * coefficient - 78 * coefficient;

        context.beginPath();
        context.fillStyle = backgroundColor;
        context.fillRect(
          leftPosition + logoLeftSpacing, 
          topPosition, 
          badgeWidth * coefficient, 
          80 * coefficient
        );
        context.stroke();

        context.font = (fontSize * coefficient) + 'px ' + fontFamilyPieces[0];
        context.fillStyle = color;
        context.textAlign = 'center';

        const lineHeight = fontSize * 0.75;
        const extraTextMargin = 10;
        const hasThreeLines = image.nameBadgeText2 && image.titleBadge;

        context.fillText(
          textTransform === 'lowercase' ? image.nameBadgeText.toLowerCase() : (textTransform === 'uppercase' ? image.nameBadgeText.toUpperCase() : image.nameBadgeText), 
          leftPosition + (badgeWidth/2) * coefficient + (hasLogo ? 33 * coefficient : 0) + logoLeftSpacing,
          topPosition 
          + 35 * coefficient 
          + (image.titleBadge ? -9 : (image.nameBadgeText2 ? -17 : (lineHeight / 2))) 
          + extraTextMargin * coefficient
          + (hasThreeLines ? -20 : 0)
        );

        if (image.nameBadgeText2) {
          context.font = (fontSize * coefficient) + 'px ' + fontFamilyPieces[0];
          context.fillStyle = color;
          context.textAlign = 'center';
          context.fillText(
            textTransform === 'lowercase' ? image.nameBadgeText2.toLowerCase() : (textTransform === 'uppercase' ? image.nameBadgeText2.toUpperCase() : image.nameBadgeText2), 
            leftPosition + (badgeWidth/2) * coefficient + (hasLogo ? 33 * coefficient : 0) + logoLeftSpacing, 
            topPosition 
            + 43 * coefficient 
            + lineHeight 
            + extraTextMargin * coefficient
            + (hasThreeLines ? -15 : 0)
          );
        }

        if (image.titleBadge) {
          context.font = Math.round(fontSize*coefficient*0.5) + 'px "Helvetica Neue LT W1G"';
          context.fillStyle = color;
          context.textAlign = 'center';
          context.fillText(
            textTransform === 'lowercase' ? image.titleBadgeText.toLowerCase() : (textTransform === 'uppercase' ? image.titleBadgeText.toUpperCase() : image.titleBadgeText), 
            leftPosition + (badgeWidth/2) * coefficient + (hasLogo ? 33 * coefficient : 0) + logoLeftSpacing, 
            topPosition 
            + 40 * coefficient 
            + lineHeight 
            + extraTextMargin * coefficient
            + (hasThreeLines ? 20 : 0)
          );
        }
      }

      if (hasLogo) {
        const logoData = await axios({ url: logo.name, method: 'GET', responseType: 'blob' });
        const logoReader = new FileReader();

        logoReader.addEventListener('load', () => {
          const img = new Image();
          img.src = logoReader.result;
          img.onload = function() {
            context.drawImage(
              img, 
              0,
              0,
              this.width, 
              this.height,
              (align === 'left' ? (padding + logoLeftSpacing) : (align === 'center' ? ((size - badgeWidth * coefficient) / 2) : (size - padding - badgeWidth * coefficient))) - 48,
              size - 50 * coefficient - 72 * coefficient,
              100 * coefficient, 
              100 * coefficient
            );

            const url = canvas.toDataURL();

            canvas.toBlob(blob => {
              resolve({
                blob, 
                url
              });
            });          
          }
        }, false);

        logoReader.readAsDataURL(logoData.data);
      } else {
        const url = canvas.toDataURL();

        canvas.toBlob(blob => {
          resolve({
            blob, 
            url
          });
        });
      }
    };

    img.src = data;
  });
}

localStorage.removeItem('sp_order_progress_25');
localStorage.removeItem('sp_order_progress_26');

export default class EditorProvider extends Component {
    orderProgressKey = 'sp_order_progress_27';
    photoTilesCollectionId = 'BTOVyMMrcpspRAmGJLCF';
    nameBadgeTilesCollectionId = '4j8gDXusidn0CYgCvICo';

    state = {
      showUploadModal: false,
      showLearnMoreModal: false,
      isUserConnected: false,

      size: '8x8',
      showSettings: true,

  		fontSize: 31,
  		fontFamily: 'HelveticaNeueLTStd-BdCn:700',
  		textTransform: 'uppercase',
  		align: 'right',
      color: '#ffffff',
      backgroundColor: '#C00000',
      extended: false,

      collectionsSettings: {},

      images: [],
      lowResolutionImages: [],
      selectedImage: 0,
      collections: [
        {
          menuName: 'Photo Tiles',
          images: [],
          id: this.photoTilesCollectionId
        },
        {
          menuName: 'Name Badge Tiles',
          images: [],
          id: this.nameBadgeTilesCollectionId
        }
      ],
      selectedCollection: this.photoTilesCollectionId,
      selectedCollectionImages: [],
      selectedCollectionImagesPage: 1,

      orderId: '',
      orderToken: '',

      price: {
        subTotal: 0,
        shipping: 0,
        tax: 0,
        total: 0
      },

      loading: false,
      loadingSidebarCollections: true,
      loadingImageCollectionImages: false,

      logos: [],

      previousOrder: null,
      changeImageId: ''
    }

    componentDidMount = async () => {
      const orderProgress = localStorage.getItem(this.orderProgressKey);
      if (orderProgress) {
        const orderProgressData = JSON.parse(orderProgress);
        delete orderProgressData.selectedImage;
        delete orderProgressData.selectedCollection;
        delete orderProgressData.showLearnMoreModal;
        delete orderProgressData.showUploadModal;
        delete orderProgressData.isUserConnected;
        delete orderProgressData.lowResolutionImages;
        delete orderProgressData.collections;

        if (orderProgressData.images) {
          for (let i in orderProgressData.images) {
            orderProgressData.images[i].loading = true;
          }
        }

        this.setState(orderProgressData);
        this.loadOrder(orderProgressData.orderId, orderProgressData.orderToken, orderProgressData);
      }

      this.loadSidebarCollections();

      const innerElement = document.querySelector('.inner');
      window.onscroll = () => {
        const {
          collections, 
          selectedCollection,
          selectedCollectionImages,
          selectedCollectionImagesPage,
          loadingImageCollectionImages
        } = this.state;
        const selectedCollectionObj = collections.find(collection => collection.id === selectedCollection);

        if (window.location.hash.indexOf('#/gallery/') !== -1) {
          if (window.scrollY + window.innerHeight + 400 > innerElement.offsetHeight) {
            if (selectedCollectionObj && selectedCollectionObj.images.length > selectedCollectionImages.length) {
              if (!loadingImageCollectionImages) {
                this.setState({ selectedCollectionImagesPage: selectedCollectionImagesPage+1 });
                localStorage.setItem('mostRecentScrollPosition', window.scrollY);
                this.loadCollectionImages();
              }
            }
          }
        }
      }
    }

    isOrderSpecificCollection(collectionId = '') {
      return [this.photoTilesCollectionId, this.nameBadgeTilesCollectionId].indexOf(collectionId) !== -1;
    }

    loadCollectionImages() {
      const { selectedCollection, selectedCollectionImagesPage } = this.state;
      this.setState({ loadingImageCollectionImages: true });

      const perNextPage = 20;
      const nextPage = selectedCollectionImagesPage - 1;
      const skip = selectedCollectionImagesPage === 1 ? 0 : ((nextPage-1) * perNextPage + imagesPerPage);
      const limit = selectedCollectionImagesPage === 1 ? imagesPerPage : perNextPage;

      getImageCollectionImages({ 
        id: selectedCollection, 
        quality: 'low',
        skip,
        limit
      }).then(results => {
        const { selectedCollectionImages } = this.state;

        for (let i in results.data) {
          selectedCollectionImages.push({
            id: i,
            url: results.data[i].downloadURL,
            name: results.data[i].name
          });
        }

        this.setState({ selectedCollectionImages });
        if (localStorage.getItem('mostRecentScrollPosition')) {
          window.scrollTo(0, localStorage.getItem('mostRecentScrollPosition'));
          localStorage.removeItem('mostRecentScrollPosition');
        }

        setTimeout(() => {
          this.setState({ loadingImageCollectionImages: false });
        }, 100);
      });
    }

    loadOrder(id, token) {
      getOrder({ id, token }).then(({data}) => {
        if (!data.imageMetadata || !data.imageMetadata.metadata) {
          alert('This is order is missing the metadata info so it cannot be reloaded successfully');
          this.setState({
            orderId: id,
            orderToken: token,
            price: data.price
          });
          return;
        }

        const metadata = JSON.parse(data.imageMetadata.metadata);
        const images = metadata.images;

        this.setState({
          orderId: id,
          orderToken: token,
          price: data.price,
          images: images,
          align: metadata.align,
          backgroundColor: metadata.backgroundColor,
          color: metadata.color,
          extended: metadata.extended,
          fontFamily: metadata.fontFamily,
          fontSize: metadata.fontSize,
          size: metadata.size
        });

        if (images.length > 0) {
          if (id && token) {
            this.setState({ loading: true });
            getOrderImageDownloadURLs({ id, token }).then(async ({data}) => {
              this.setState({ loading: false });

              for (let i in images) {
                const imageIndex = i*1;
                const imageId = images[i].id;
                const response = await axios({ url: data[imageId], method: 'GET', responseType: 'blob' });                
                const reader = new FileReader();
                reader.addEventListener('load', async () => {
                  const { images } = this.state;
                  images[imageIndex].loading = false;
                  images[imageIndex].data = reader.result;
                  this.setState({ images });
                }, false);

                reader.readAsDataURL(response.data);
              }
            }).catch(error => {
              this.setState({ loading: false });
            });
          }
        }
        });
    }

    loadSidebarCollections() {
      getCustomerAccessibleImageCollections().then(({data}) => {
        const self = this;
        const collections = data.map(collection => {
          return {
            ...collection,
            loaded: false
          }
        });

        this.setState({ 
          collections,
          loadingSidebarCollections: false 
        });

        for (let i in collections) {
          const image = new Image();
          image.onload = function() {
            const index = collections.findIndex(collection => collection.menuIconDownloadURL === this.src);
            collections[index].loaded = true;
            self.setState({ collections });
          };
          image.src = collections[i].menuIconDownloadURL;
        }
      }).catch(error => {
        alert(JSON.stringify(error));
      });
    }

    getItemPrice() {
      const { size } = this.state;
      
      return size === '8x8' ? get8x8Price() : get12x12Price()
    }

    getImageNameFromFileName(fileName) {
      if (!fileName) {
        return false;
      }

      fileName = fileName.replace(/-/g, ' ')
        .replace('.jpg', '')
        .replace('.JPG', '')
        .replace('.jpeg', '')
        .replace('.JPEG', '')
        .replace('.png', '')
        .replace('.PNG', '');
      const pieces = fileName.split(' ');

      return pieces.length > 1 ? pieces.join(' ') : false;
    }

    getImageNamePieces(name) {
      let first = '';
      let second = '';
      const pieces = name.split(' ');

      for (let i in pieces) {
        if ((first + ' ' + pieces[i]).length < (this.state.fontFamily.toLowerCase().indexOf('libre') !== -1 ? 15 : 20)) {
          first = first + ' ' + pieces[i];
        } else {
          second = second + ' ' + pieces[i];
        }
      }

      return { first, second };
    }

    addImage(image, imageId, imageUploadUrl, width, height, data, orderId, orderToken, state) {
      const { images, selectedCollection, changeImageId } = state;
      const formattedImageName = this.getImageNameFromFileName(image.name);
      const isImageNameFormatted = image.name && formattedImageName && selectedCollection === this.nameBadgeTilesCollectionId;
      const imageName = isImageNameFormatted ? this.getImageNamePieces(formattedImageName) : '';
      const newImage = { 
        id: imageId, 
        collectionId: selectedCollection,
        nameBadge: selectedCollection === this.nameBadgeTilesCollectionId,
        nameBadgeText: imageName && imageName.first ? imageName.first : '',
        nameBadgeText2: imageName && imageName.second ? imageName.second : '',
        titleBadge: false,
        titleBadgeText: '',
        imageStyle: selectedCollection === this.nameBadgeTilesCollectionId ? 'grayscale(100%)' : 'none',
        logo: '',
        angle: 0,
        zoom: 100,
        isFlipped: false,
        positionLeft: 0,
        positionTop: 0,
        approved: false,
        uploaded: false,
        loading: true,
        uploadUrl: imageUploadUrl,
        lowResolution: false,
        width: width,
        height: height,
        data: data
      };

      if (!selectedCollection) {
        alert('Please select a collection first');
        return;
      }

      if (changeImageId) {
        const index = images.findIndex(image => image.id === changeImageId);
        if (index) {
          removeImageFromOrder({ id: orderId, imageId: images[index].id });
          images[index] = newImage;
        }
      } else {
        images.push(newImage);
      }
      
      this.setState({ 
        images,
        changeImageId: '' 
      });
    }

    saveProgress = (alert=false, get=false) => {
      const { orderId, orderToken } = this.state;

      const data = Object.assign({}, this.state);
      data.images = data.images.map(image => {
        return {
          ...image,
          data: ''
        }
      });

      if (get) {
        return data;
      }

      localStorage.setItem(this.orderProgressKey, JSON.stringify(data));

      modifyOrder({
        id: orderId,
        token: orderToken,
        imageMetadata: {
          metadata: JSON.stringify(data)
        }
      });

      if (alert) {
        window.alert('The order progress was saved');
      }
    }

    render() {
        return (
            <EditorContext.Provider
                value={{
                	...this.state,

                	setState: async (updates, regeneratePreviews=false, loadCollectionImages=false) => {
                    if (Object.keys(updates).indexOf('selectedCollection') !== -1) {
                      updates.selectedCollectionImages = [];
                      updates.selectedCollectionImagesPage = 1;
                      this.setState(updates);

                      if (!this.isOrderSpecificCollection(updates.selectedCollection) && loadCollectionImages) {
                        this.loadCollectionImages();
                      }
                    } else {
                      this.setState(updates);
                    }
                	},

                  updateImage: async (id, property, value, skipProgressSave=false) => {
                    const { images } = this.state;
                    const index = images.findIndex(image => image.id === id);

                    if (property === 'position') {
                      images[index].positionLeft = value.x;
                      images[index].positionTop = value.y;
                    } else if (property === 'nameBadgeText2') {
                      images[index][property] = value;
                      // images[index].titleBadge = false;
                    } else {
                      images[index][property] = value;
                    }

                    this.setState({ images });

                    if (!skipProgressSave) {
                      this.saveProgress();
                    }
                  },

                  pushImages: (images, skipSizeCheck=false) => {
                    const { orderId, orderToken, size, changeImageId } = this.state;
                    const callback = async (id, token) => {
                      const finalImages = [];
                      const { orderId, orderToken, collections, selectedCollection } = this.state;
                      images = changeImageId && images.length > 0 ? [images[0]] : images;

                      for (let i in images) {
                        const url = URL.createObjectURL(images[i]);
                        const { width, height } = await getImageSize(url);

                        if (width === 0 && height === 0) {
                          // do nothing
                        } else if (!skipSizeCheck && (height < lowResolutionLimit || width < lowResolutionLimit)) {
                          const { lowResolutionImages } = this.state;
                          lowResolutionImages.push({
                            image: images[i], 
                            url 
                          });
                          this.setState(lowResolutionImages);
                        } else {
                          finalImages.push(images[i]);
                        }
                      }

                      addImagesToOrder({
                        id: orderId, 
                        token: orderToken,
                        imageCollection: collections.find(collection => collection.id === selectedCollection).name,
                        numberOfImages: finalImages.length
                      }).then(async ({data}) => {
                        for (let i in finalImages) {
                          const { width, height } = await getImageSize(URL.createObjectURL(finalImages[i]));
                          const contents = await getImageContents(finalImages[i]);

                          this.addImage(finalImages[i], data.images[i].imageId, data.images[i].uploadURL, width, height, contents, id, token, this.state);
                        }

                        this.setState({
                          price: data.data.price,
                          loading: false
                        });

                        for (let i in data.images) {                          
                          uploadFile(data.images[i].uploadURL, finalImages[i]).then(({config}) => {
                            const { images } = this.state;
                            const imageIndex = images.findIndex(image => image.uploadUrl === config.url);

                            images[imageIndex].loading = false;
                            this.setState({ images });

                            if (imageIndex === 0 && [this.photoTilesCollectionId, this.nameBadgeTilesCollectionId].indexOf(images[imageIndex].collectionId) !== -1) {
                              window.location.hash = '/editor/' + images[imageIndex].collectionId + '/' + images[imageIndex].id;;
                            }
                          });
                        }

                        this.saveProgress();
                      }).catch(error => {
                        this.setState({ loading: false });
                        alert(error.message ? error.message : JSON.stringify(error));
                      });
                    }

                    this.setState({ loading: true });

                    if (orderId && orderToken) {
                      callback(orderId, orderToken);
                      
                    } else {
                      createOrder({ appVersion: 'Web ' + APP_VERSION, tileSize: size }).then(({data}) => {
                        this.setState({
                          orderId: data.id,
                          orderToken: data.token,
                          price: data.price
                        });

                        callback(data.id, data.token);
                      }).catch(error => {
                        alert(JSON.stringify(error));
                      });
                    }
                  },

                  popImage: (id, hideLoaderAfter=false) => {
                    const { images, orderId } = this.state;
                    const index = images.findIndex(image => image.id === id);

                    images.splice(index, 1);
                    this.setState({ images });
                    this.saveProgress();
                    removeImageFromOrder({ id: orderId, imageId: id }).then(({data}) => {
                      const updates = { 
                        price: data.price
                      };

                      if (hideLoaderAfter) {
                        updates.loading = false;
                      }

                      this.setState(updates);
                    }).catch(error => {
                      alert(error.message);
                    });
                  },
                  copyImage: async (id, hideLoaderAfter=false) => {
                    const { orderId, orderToken, images, collections } = this.state;
                    const index = images.findIndex(image => image.id === id);
                    const image = images[index];
                    const newImage = Object.assign({}, image, { loading: true });
                    const imageId = Math.random();
                    newImage.id = imageId;

                    images.splice(index+1, 0, newImage);

                    this.setState({ images });

                    addImageToOrder({
                      id: orderId, 
                      token: orderToken,
                      imageCollection: collections.find(collection => collection.id === newImage.collectionId).name
                    }).then(async ({data}) => {
                      const imageIndex = images.findIndex(image => image.id === imageId);

                      await uploadFile(data.uploadURL, image);

                      images[imageIndex].id = data.imageId;
                      images[imageIndex].uploadUrl = data.uploadURL;
                      images[imageIndex].loading = false;

                      const updates = {
                        images,
                        price: data.data.price
                      };

                      if (hideLoaderAfter) {
                        updates.loading = false;
                      }

                      this.setState(updates);
                      this.saveProgress();
                    }).catch(error => {
                      const imageIndex = images.findIndex(image => image.id === imageId);

                      images[imageIndex].loading = false;

                      this.setState({ images });

                      alert(error.message ? error.message : JSON.stringify(error));
                    });
                    
                  },
                  resetImage: async (id) => {
                    const { images } = this.state;
                    const index = images.findIndex(image => image.id === id);

                    images[index].nameBadge = images[index].collectionId === this.nameBadgeTilesCollectionId;
                    images[index].titleBadge = false;
                    images[index].titleBadgeText = '';
                    images[index].imageStyle = images[index].collectionId === this.nameBadgeTilesCollectionId ? 'grayscale(100%)' : 'none';
                    images[index].logo = '';
                    images[index].angle = 0;
                    images[index].zoom = 100;
                    images[index].isFlipped = false;
                    images[index].positionLeft = 0;
                    images[index].positionTop = 0;

                    this.setState({ images });
                  },
                  resetOrder: previousOrder => {
                    localStorage.removeItem(this.orderProgressKey);

                    this.setState({
                      images: [],
                      orderId: '',
                      orderToken: '',
                      price: {
                        subTotal: 0,
                        shipping: 0,
                        tax: 0,
                        total: 0
                      },
                      previousOrder
                    });
                  },

                  reloadOrder: (id, token) => {
                    let oldData = localStorage.getItem(this.orderProgressKey);
                    if (oldData) {
                      oldData = JSON.parse(oldData);

                      if (oldData.orderId && oldData.orderToken) {
                        oldData.orderId = id;
                        oldData.orderToken = token;

                        localStorage.setItem(this.orderProgressKey, JSON.stringify(oldData));
                      }
                    }

                    this.loadOrder(id, token);
                  },

                  saveProgress: (alert=false) => {
                    this.saveProgress(alert);
                  },
                  removeCartRow: async (collectionId) => {
                    const self = this;
                    const { images, orderId } = this.state;
                    const imagesToBeRemoved = images.filter(image => image.collectionId === collectionId);

                    this.setState({ loading: true });

                    for (let i in imagesToBeRemoved) {
                      await removeImageFromOrder({ id: orderId, imageId: imagesToBeRemoved[i].id }).then(({data}) => {
                        this.setState({
                          price: data.price
                        });
                      }).catch(error => {
                        this.setState({ loading: false });
                        alert(error.message);
                      });
                    }

                    this.setState({ 
                      loading: false,
                      images: images.filter(image => image.collectionId !== collectionId) 
                    });
                    setTimeout(function(){
                      self.saveProgress();
                    }, 1000);
                  },

                  getCollectionCartImagesCount: collectionId => {
                    const { images } = this.state;

                    return images.filter(image => image.collectionId === collectionId).length;
                  },

                  getItemPrice: () => {
                    return this.getItemPrice();
                  },
                  getSubtotalPrice: () => {
                    const { price } = this.state;

                    return price.subTotal ? (price.subTotal / 100) : 0;
                  },
                  getShippingPrice: () => {
                    const { price } = this.state;

                    return price.shipping ? (price.shipping / 100) : 0;
                  },
                  getTaxPrice: () => {
                    const { price } = this.state;

                    return price.tax ? (price.tax / 100) : 0;
                  },
                  getTotalPrice: () => {
                    const { price } = this.state;

                    return price.total ? (price.total / 100) : 0;
                  },

                  getPhotoTilesCollectionId: () => {
                    return this.photoTilesCollectionId;
                  },
                  isPhotoTilesCollection: (collectionId = '') => {
                    return collectionId === this.photoTilesCollectionId;
                  },
                  isNameBadgeTilesCollection: (collectionId = '') => {
                    return collectionId === this.nameBadgeTilesCollectionId;
                  },
                  isOrderSpecificCollection: (collectionId = '') => {
                    return this.isOrderSpecificCollection(collectionId);
                  },

                  getUnapprovedNameBadgeTilesCount: () => {
                    const { images } = this.state;

                    return images.filter(image => !image.approved && image.collectionId === this.nameBadgeTilesCollectionId).length;
                  },

                  generateImage: async (image) => {
                    const data = await generateImage(this.state, image);

                    return data;
                  },

                  loadSidebarCollections: () => {
                    this.loadSidebarCollections();
                  },

                  setLocalData: data => {
                    localStorage.setItem(this.orderProgressKey, data);
                    window.location.href = '/';
                  },
                  getLocalData: () => {
                    return localStorage.getItem(this.orderProgressKey);
                  }
                }}
            >
                {this.props.children}
            </EditorContext.Provider>
        );
    }
}
