import PDFDocument from 'pdfkit-browserify';
import AWS from 'aws-sdk';
import { AWS_BUCKET_REGION, AWS_IDENTITY_POOL_ID, AWS_ALBUM_BUCKET_NAME } from  '../config.js';

AWS.config.update({
  	region: AWS_BUCKET_REGION,
  	credentials: new AWS.CognitoIdentityCredentials({ IdentityPoolId: AWS_IDENTITY_POOL_ID })
});

export function getOrientation(file, callback) {
	return new Promise(resolve => {
	  	const reader = new FileReader();

	  	reader.onload = function(event) {
	    	const view = new DataView(event.target.result);

	    	if (view.getUint16(0, false) !== 0xFFD8) 
	    		return callback(-2);

	    	const length = view.byteLength; 
	    	let offset = 2;

	    	while (offset < length) {
	      		const marker = view.getUint16(offset, false);
	      		offset += 2;

	      		if (marker === 0xFFE1) {
	        		if (view.getUint32(offset += 2, false) !== 0x45786966) {
	          			return callback(-1);
	        		}
	        
	        		const little = view.getUint16(offset += 6, false) === 0x4949;
	        		offset += view.getUint32(offset + 4, little);
	        		const tags = view.getUint16(offset, little);
	        		offset += 2;

	        		for (let i = 0; i < tags; i++)
	          			if (view.getUint16(offset + (i * 12), little) === 0x0112)
	            			return callback(view.getUint16(offset + (i * 12) + 8, little));
	      		} else if ((marker & 0xFF00) !== 0xFF00) 
	  				break;
	      		else 
	      			offset += view.getUint16(offset, false);
	    	}
	    	
	    	return callback(-1);
	  	};

	  	reader.readAsArrayBuffer(file.slice(0, 64 * 1024));
  	});
};

export function parsePresignedUrls(urls) {
	const pieces = [];

	for (let i in urls) {
		let url = urls[i];
		url = url.replace('https://s3.amazonaws.com/sweetpix2/', '');
		url = url.substring(0, url.indexOf('?'));
		pieces.push(url.split('/'));
	}

	return pieces;
}

export function createAlbum(name) {
	return new Promise(resolve => {
		const s3 = new AWS.S3({ apiVersion: '2006-03-01', params: { Bucket: AWS_ALBUM_BUCKET_NAME } });
		const key = encodeURIComponent(name) + '/';
	  
	  	s3.headObject({Key: key}, (err, data) => {
		    if (!err) {
		      	resolve(false);
		    } else if (err.code !== 'NotFound') {
		      	alert('There was an error creating your album: ' + err.message);
		      	resolve(false);
		    } else {
		    	s3.putObject({Key: key}, (err, data) => {
		    		resolve(err ? false : true);
		    	});	
		    }
	  	});
  	});
}

export function upload(photo, name, album) {
	return new Promise(resolve => {
		const canvas = document.createElement('canvas');
	 	const context = canvas.getContext('2d');
	 	const image = new Image();
	 	image.onload = function() {
	 		canvas.width = image.width;
	 		canvas.height = image.height;
	 		context.drawImage(image, 0, 0);
	 		canvas.toBlob((blob2) => {
 				const reader = new FileReader();
			 	reader.onloadend = () => {
					const kPhotoboardSizeInches = 7.852
					const kPhotoboardTrimInches = 0.05
					const kPhotoboardDPI = 72.0
					const pixelSize = kPhotoboardSizeInches * kPhotoboardDPI

					const doc = new PDFDocument({size: [pixelSize, pixelSize]});

					const box = [0, 0, pixelSize, pixelSize];
					const trimInset = kPhotoboardDPI * kPhotoboardTrimInches;
					        
					doc.page.dictionary.data.MediaBox = box;
					doc.page.dictionary.data.ArtBox = box;
					doc.page.dictionary.data.CropBox = box;
					doc.page.dictionary.data.TrimBox = [trimInset, trimInset, pixelSize - trimInset, pixelSize - trimInset];

					doc.image(reader.result, 0, 0, {width: pixelSize, height: pixelSize});

					let buffers = [];

					doc.on('data', buffers.push.bind(buffers));
					doc.on('end', () => {
						let pdfData = Buffer.concat(buffers);

					    const s3 = new AWS.S3({ apiVersion: '2006-03-01', params: { Bucket: AWS_ALBUM_BUCKET_NAME } });

				  		s3.upload({
				    		Key: encodeURIComponent(album) + '/' + name,
				    		ContentType: 'application/pdf',
				    		Body: pdfData
				  		}, (err, data) => {
							resolve(err ? false : true);
						});
					});

					doc.end();
			 	}
			 	reader.readAsDataURL(blob2);
	 		}, 'image/jpeg', 1);
	 	};
	 	image.src = photo.selection;
	});
}

export function rotate(img, width, height, callback) {
	const size = Math.max(width, height);
	const squareCanvas = document.createElement('canvas');
 	const squareContext = squareCanvas.getContext('2d');
 	squareCanvas.width = size; 
 	squareCanvas.height = size;
 
 	const bufferCanvas = document.createElement('canvas');
 	const bufferContext = bufferCanvas.getContext('2d');
 	bufferCanvas.width = height;
 	bufferCanvas.height = width;

    const image = new Image();
    image.onload = () => {
     	squareContext.drawImage(image, (size - width) / 2, (size - height) / 2, width, height);
     	squareContext.save();
     	squareContext.translate(size / 2, size / 2);
     	squareContext.rotate(90 * Math.PI / 180);
     	squareContext.drawImage(squareCanvas, -squareCanvas.width / 2, -squareCanvas.width / 2);
 	   	squareContext.restore();

 	   	const sx = (squareCanvas.width - bufferCanvas.width) / 2;
 	   	const sy = (squareCanvas.height - bufferCanvas.height) / 2;

 	   	bufferContext.drawImage(squareCanvas, sx, sy, squareCanvas.width - sx * 2, squareCanvas.height - sy * 2, 0, 0, height, width);
    	callback(bufferCanvas.toDataURL());
    }
    image.src = img;	
}

export function prepend(src) {
	return {
		id: Math.floor(1000000 + Math.random() * 9000000),
		image: null,
		selection: null,
	    crop_x: 0,
	    crop_y: 0,
	    zoom: 1,
	    aspect: 1,
	    loading: true,
	    warning: false,
		raw: src,
	};
}

export function prepare(photo) {
	return new Promise(resolve => {
		const reader = new FileReader();
		reader.onload = () => {
			const image = new Image();
			image.src = reader.result;
			image.onload = () => {
				const size = Math.min(image.width, image.height);
				const selection = crop(image, size, size, (image.width - size) / 2, (image.height - size) / 2, 1);

				getOrientation(photo.raw, (orientation) => {
					if (orientation === 6) {
						rotate(image.src, image.width, image.height, (newImage) => {
							rotate(selection, size, size, (newSelection) => {
								resolve({
									id: photo.id,
									image: newImage,
									selection: newSelection,
								    crop_x: 0,
								    crop_y: 0,
								    zoom: 1,
								    aspect: 1,
								    loading: false,
						    		raw: photo.raw,
						    		warning: size < 640
								});
							});
						});
					} else {
						resolve({
							id: photo.id,
							image: image.src,
							selection: selection,
						    crop_x: 0,
						    crop_y: 0,
						    zoom: 1,
						    aspect: 1,
						    loading: false,
						    raw: photo.raw,
						    warning: size < 640
						});
					}
				});
			}
		};
		
		if (photo.raw)
			reader.readAsDataURL(photo.raw);
  	});
}

export function update(photo, updates) {
	return new Promise(resolve => {
	    const image = new Image();
		image.src = photo.image;
		image.onload = () => {
			const size = Math.min(image.width, image.height);
			const selection = crop(
				image, 
				size / photo.zoom, 
				size / photo.zoom, 
				image.width * updates.x / 100, 
				image.height * updates.y / 100, 
				1
			);

			resolve(Object.assign(photo, { selection }));
		}
  	});
}

export function crop(imgObj, newWidth, newHeight, startX, startY, ratio) {
 	const tnCanvas = document.createElement('canvas');
 	const tnCanvasContext = tnCanvas.getContext('2d');
 	// tnCanvas.width = newWidth; 
 	// tnCanvas.height = newHeight;
 	tnCanvas.width = 1000; 
 	tnCanvas.height = 1000;
 
 	const bufferCanvas = document.createElement('canvas');
 	const bufferContext = bufferCanvas.getContext('2d');
 	bufferCanvas.width = imgObj.width;
 	bufferCanvas.height = imgObj.height;
 	bufferContext.drawImage(imgObj, 0, 0);
 
 	// tnCanvasContext.drawImage(bufferCanvas, startX ,startY, newWidth * ratio, newHeight * ratio, 0, 0, newWidth, newHeight);
 	tnCanvasContext.drawImage(bufferCanvas, startX ,startY, newWidth * ratio, newHeight * ratio, 0, 0, 1000, 1000);
 
 	return tnCanvas.toDataURL();
}
