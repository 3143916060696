import React, { Component } from 'react'
import moment from 'moment'
import firebase from '../../firebase'
import { isUserAdmin, isUserConnected, redirectToLogin } from '../../firebaseHelpers'
import LoadingWheel from '../../components/LoadingWheel'

export default class AdminReportsScreen extends Component {
  constructor() {
  	super();

  	this.state = { 
  		entries: [],
      loading: false,
      from: '',
      to: ''
  	};
  }

  componentDidMount = async () => {
    const isAdmin = await isUserAdmin();
    const isUser = await isUserConnected();

    if (isAdmin) {
      this.loadEntries();
    } else if (isUser) {
      this.props.history.push('/account');
    } else {
      redirectToLogin(this.props.history);
    }
  }

  loadEntries() {
    this.setState({ loading: true });

    firebase.firestore().collection('orderReports').get().then(results => {
      const entries = [];
      results.forEach(result => {
        entries.push(Object.assign({}, result.data(), { id: result.id }));
      });
      this.setState({ entries, loading: false });
    }).catch(error => {
      this.setState({ loading: false });
      alert(JSON.stringify(error));
    });
  }

  render() {
  	const { loading, from, to } = this.state;
    const entries = this.state.entries.filter(({reportDate}) => {
      return !(
        (from && moment(reportDate.seconds * 1000) < moment(from)) || 
        (to && moment(reportDate.seconds * 1000) > moment(to).add(1, 'd'))
      );
    });

    const values = {
      '8x8': {
        qty: 0,
        amt: 0
      },
      '12x12': {
        qty: 0,
        amt: 0
      },
      total: {
        qty: 0,
        amt: 0
      }
    }

    for (let i in entries) {
      const entry = entries[i];

      if (entry.sizes['8x8']) {
        values['8x8'].qty += entry.sizes['8x8'].quantity;
        values['8x8'].amt += (entry.sizes['8x8'].amount / 10);
      }

      if (entry.sizes['12x12']) {
        values['12x12'].qty += entry.sizes['12x12'].quantity;;
        values['12x12'].amt += (entry.sizes['12x12'].amount / 10);
      }

      values.total.qty += entry.totalQuantity;
      values.total.amt += (entry.totalAmount / 10);
    }

    return (
    	<div className="panel-screen">
    		<div className="panel-main justify-top">
    			<div className="table-wrapper">
            <div className="title-bar">
              <div className="section-title">Reports</div>
              <div className="search-box date-filters">
                <input type="date" value={from} onChange={event => this.setState({ from: event.target.value })} />
                <span>-</span>
                <input type="date" value={to} onChange={event => this.setState({ to: event.target.value })} />
              </div>
              <div></div>
            </div>

            <table className="table">
              <thead>
                <tr>
                  <th>Size</th>
                  <th width="10%">&nbsp;</th>
                  <th width="10%">Qty</th>
                  <th width="10%">Amt</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>8x8</td>
                  <td>&nbsp;</td>
                  <td>{ values['8x8'].qty }</td>
                  <td>${ values['8x8'].amt }</td>
                </tr>
                <tr>
                  <td>12x12</td>
                  <td>&nbsp;</td>
                  <td>{ values['12x12'].qty }</td>
                  <td>${ values['12x12'].amt }</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>Total</td>
                  <td align="right">{ values.total.qty }</td>
                  <td>${ values.total.amt }</td>
                </tr>
                 {
                    [].map(entry => <tr key={ entry.id }>
                      <td>{ entry.id }</td>
                      <td>{ moment(entry.reportDate.seconds * 1000).format('MM/DD/YY') }</td>
                      <td>
                        ${ entry.sizes['8x8'] ? (entry.sizes['8x8'].amount / 100) : 0 }
                        &nbsp;
                        (Qty: { entry.sizes['8x8'] ? entry.sizes['8x8'].quantity : 0 })
                      </td>
                      <td>
                        ${ entry.sizes['12x12'] ? (entry.sizes['12x12'].amount / 100) : 0 }
                        &nbsp;
                        (Qty: { entry.sizes['12x12'] ? entry.sizes['12x12'].quantity : 0 })
                      </td>
                      <td>
                        ${ entry.totalAmount / 100 }
                        &nbsp;
                        (Qty: { entry.totalQuantity })
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
    			</div>
    		</div>

        {
          loading
          ? <LoadingWheel />
          : null
        }
			</div>
		)
  }
}
