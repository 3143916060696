import React, { FunctionComponent, useState } from 'react'
import classNames from 'classnames'
import icon from '../img/icon-upload.png'

type DragAndDropUploaderProps = {
	isStatic?: boolean,
	onUpload?: any
}

const DragAndDropUploader: FunctionComponent<DragAndDropUploaderProps> = ({ isStatic, onUpload }: DragAndDropUploaderProps) => {
	const [dragging, setDragging] = useState(false);
	const [dragCounter, setDragCounter] = useState(0);

	return <div 
		className={classNames('modal modal-upload', { 'modal-static': isStatic })} 
		onDragEnter={event => {
			event.preventDefault();
    		event.stopPropagation();
    		setDragCounter(dragCounter+1);
    		
    		if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
    			setDragging(true);
			}
		}}
		onDragOver={event => {
			event.preventDefault();
    		event.stopPropagation();
		}}
		onDragLeave={event => {
		    event.preventDefault();
		    event.stopPropagation();
		    const newDragCounter = dragCounter-1;
		    setDragCounter(newDragCounter);

		    if (newDragCounter === 0) {
		      setDragging(false);
		    }
		}}
		onDrop={event => {
		    event.preventDefault();
		    event.stopPropagation();

		    setDragging(false);

    		if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      			// event.dataTransfer.clearData();
      			setDragCounter(0);

      			const element = event.target as HTMLInputElement;
      			const dataTransfer: any = event.dataTransfer;
  				const files = Array.from((dataTransfer ? dataTransfer.files : element.files) as FileList);
				onUpload(files.filter(file => ['image/jpeg', 'image/png', 'image/raw'].indexOf(file.type) !== -1));
    		}			
		}}
	>
		{
			dragging
			? <div className="drag-mask"></div>
			: null
		}
		
		<img src={ icon } alt="Upload" />
		<h3>Drag and drop images here</h3>
		<div className="or">or</div>
		<button 
			className="sp-button bordered blue" 
			onClick={event => {
				const element = event.target as HTMLInputElement;
				const parentElement = element.parentElement as HTMLInputElement;
				const input = parentElement.querySelector('.file-uploader') as HTMLInputElement;
				input.click();
			}}>
				Choose files to upload
		</button>
		<input 
			type="file" 
			className="file-uploader" 
			onChange={async (event) => {
				const element = event.target as HTMLInputElement;
				const files = Array.from(element.files as FileList);
				onUpload(files);
			}}
			multiple={true}
			accept="image/jpeg, image/png, image/raw" 
		/>
	</div>
}

export default DragAndDropUploader
