import React, { Component } from 'react';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { Route, Switch, HashRouter } from 'react-router-dom';
import thunk from 'redux-thunk';

import EditorProvider from './providers/EditorProvider';

import './styles/app.sass';
import iconClose from './img/icon-close.png';
import iconCheckmark from './img/icon-checkmark.png';

import Header from './components/Header';
import IosRedirect from './components/IosRedirect';

// import StartScreen from './screens/StartScreen';
import UploadScreen from './screens/UploadScreen';
import PhotosScreen from './screens/PhotosScreen';
import CartScreen from './screens/CartScreen';
import ConfirmationScreen from './screens/ConfirmationScreen';
import OrderPreviewScreen from './screens/OrderPreviewScreen';
import CheckoutScreen from './screens/CheckoutScreen';
import PayScreen from './screens/PayScreen';
import ThanksScreen from './screens/ThanksScreen';

import AuthScreen from './screens/AuthScreen';

import AccountBookmarksScreen from './screens/account/AccountBookmarksScreen';
import AccountOrdersScreen from './screens/account/AccountOrdersScreen';
import AccountOrderScreen from './screens/account/AccountOrderScreen';
import AccountContactScreen from './screens/account/AccountContactScreen';

import AdminOrdersScreen from './screens/admin/AdminOrdersScreen';
import AdminOrderScreen from './screens/admin/AdminOrderScreen';
import AdminCustomersScreen from './screens/admin/AdminCustomersScreen';
import AdminCustomerScreen from './screens/admin/AdminCustomerScreen';
import AdminPromoCodesScreen from './screens/admin/AdminPromoCodesScreen';
import AdminPromoCodeScreen from './screens/admin/AdminPromoCodeScreen';
import AdminImageCollectionsScreen from './screens/admin/AdminImageCollectionsScreen';
import AdminImageCollectionScreen from './screens/admin/AdminImageCollectionScreen';
import AdminOrganizationsScreen from './screens/admin/AdminOrganizationsScreen';
import AdminOrganizationScreen from './screens/admin/AdminOrganizationScreen';
import AdminReportsScreen from './screens/admin/AdminReportsScreen';

import EditorScreen from './screens/EditorScreen';
import GeneratorScreen from './screens/GeneratorScreen';
import GalleryScreen from './screens/GalleryScreen';
import SideMenu from './components/SideMenu';
import AuthModal from './components/AuthModal';
import CollectionsSideBar from './components/CollectionsSideBar';
import ConfiguratorSideBar from './components/ConfiguratorSideBar';

import rootReducer from './reducers/rootReducer';
import { getMinQtyPrice, getAdditionalItemPrice } from './helpers/pricing';

const middlewares = [
  thunk,
];

const store = createStore(
  rootReducer,
  applyMiddleware(...middlewares)
)

export default class App extends Component {
  constructor() {
    super();

    this.state = { 
      headerState: 1,
      showInfo: false,
      navigationStarted: false,
      showAuthModal: false,
      authModalState: 'login'
    };
  }

  componentDidMount() {
    const { host, protocol } = window.location;
    const { userAgent } = navigator;

    const handleZoom = () => {
      if (window.innerWidth < 1450) {
        if (userAgent.indexOf('Firefox') !== -1) {
          document.body.style.transformOrigin = '0 0';
          document.body.style.transform = 'scale(0.72)';
          document.body.style.width = '138.5%';
          document.body.style.height = '138.5%';
        } else {
          document.body.style.zoom = '72%';
        }    
      } else {
        document.body.removeAttribute('style');
      }
    }

    // handleZoom();
    // window.addEventListener('resize', function(event) {
    //   handleZoom();
    // }, true);

    if (userAgent.toLowerCase().indexOf('mobile') !== -1 && userAgent.toLowerCase().indexOf('iphone') === -1) {
      window.location.href = 'https://v1.sweetpixapp.com';
    }

    if (host === 'order.sweetpixapp.com' && protocol === 'http:') {
      window.location.href = 'https://' + host;
    } else if (!this.navigationStarted && window.location.hash !== '#/') {
      //window.location.hash = '/';
    }

    document.querySelector('body').onclick = event => {
      if (event.target.classList.contains('custom-ui-mask')) {
        event.target.parentElement.parentElement.classList.toggle('active');
      } else {
        const comboboxes = document.querySelectorAll('.field-select.custom-ui');
        for (let i in comboboxes) {
          if (typeof comboboxes[i] === 'object') {
            comboboxes[i].classList.remove('active');
          }
        }
      }
    }
  }

  onChangeHeaderState = () => {
    this.setState({ headerState: this.state.headerState * 2 });
  }

  onToggleShowInfo = () => {
    this.setState({ showInfo: !this.state.showInfo });
  }

  onToggleAuthModal = (flag = 'auto', defaultState = 'login') => {
    const { showAuthModal } = this.state;

    if (flag === 'auto') {
      this.setState({ 
        showAuthModal: !showAuthModal,
        authModalState: defaultState
      });
    } else {
      this.setState({ 
        showAuthModal: flag,
        authModalState: defaultState
      });
    }
  }

  render() {
    const { headerState, showInfo, showAuthModal, authModalState } = this.state;

    return (
      <Provider store={store}>
        <EditorProvider>
        <HashRouter>
          <div className="app">
            <Header 
              headerState={headerState} 
              onToggleShowInfo={this.onToggleShowInfo} 
              onToggleAuthModal={this.onToggleAuthModal}
            />
            <IosRedirect />

            <div className="inner">
              <SideMenu />
              <CollectionsSideBar onToggleAuthModal={this.onToggleAuthModal} />
              <ConfiguratorSideBar onToggleAuthModal={this.onToggleAuthModal} />
              { 
                showAuthModal 
                ? <AuthModal 
                  onToggleAuthModal={() => this.setState({ showAuthModal: !showAuthModal })} 
                  defaultState={authModalState}
                /> 
                : null 
              }
              <main>
                <Switch>
                  <Route path='/' exact component={props => <GalleryScreen {...props} onToggleAuthModal={this.onToggleAuthModal} onNavigate={() => this.setState({ navigationStarted: true })} />} />
                  <Route path='/upload' exact component={props => <UploadScreen {...props} onChangeHeaderState={this.onChangeHeaderState} />} />
                  <Route path='/photos' exact component={props => <PhotosScreen {...props} onChangeHeaderState={this.onChangeHeaderState} />} />
                  <Route path='/cart' exact component={props => <CartScreen {...props} onToggleAuthModal={this.onToggleAuthModal} />} />
                  <Route path='/confirmation' exact component={ConfirmationScreen} />
                  <Route path='/order-confirmation/:id/:token' exact component={OrderPreviewScreen} />
                  <Route path='/checkout' exact component={CheckoutScreen} />
                  <Route path='/pay' exact component={PayScreen} />
                  <Route path='/thanks' exact component={ThanksScreen} />

                  <Route path='/gallery/:id' exact component={props => <GalleryScreen {...props} onToggleAuthModal={this.onToggleAuthModal} />} />
                  <Route path='/editor/:collectionId/:id' exact component={EditorScreen} />
                  <Route path='/generator/:collectionId/:id' exact component={GeneratorScreen} />
                  { /* <Route path='/admin' exact component={AdminScreen} /> */ }

                  <Route path='/auth' exact component={AuthScreen} />
                  <Route path='/reset-password' exact component={AuthScreen} />

                  <Route path='/account' exact component={AccountBookmarksScreen} />
                  <Route path='/account/orders' exact component={AccountOrdersScreen} />
                  <Route path='/account/orders/:id' exact component={AccountOrderScreen} />
                  <Route path='/account/contact' exact component={AccountContactScreen} />

                  <Route path='/admin' exact component={AdminOrdersScreen} />
                  <Route path='/admin/orders/page/:page' exact component={AdminOrdersScreen} />
                  <Route path='/admin/orders/:id' exact component={AdminOrderScreen} />
                  <Route path='/admin/customers' exact component={AdminCustomersScreen} />
                  <Route path='/admin/customers/:id' exact component={AdminCustomerScreen} />
                  <Route path='/admin/customers/:id/page/:page' exact component={AdminCustomerScreen} />
                  <Route path='/admin/promo-codes' exact component={AdminPromoCodesScreen} />
                  <Route path='/admin/promo-codes/:id' exact component={AdminPromoCodeScreen} />
                  <Route path='/admin/image-collections' exact component={AdminImageCollectionsScreen} />
                  <Route path='/admin/image-collections/:id' exact component={AdminImageCollectionScreen} />
                  <Route path='/admin/organizations' exact component={AdminOrganizationsScreen} />
                  <Route path='/admin/organizations/:id' exact component={AdminOrganizationScreen} />
                  <Route path='/admin/reports' exact component={AdminReportsScreen} />
                </Switch>
              </main>
            </div>
          </div>
        </HashRouter>

        {
          showInfo
          ? (<div className="global-modal global-modal-checkout">
            <div className="outer outer-info">
              <span className="close" onClick={ this.onToggleShowInfo }>
                <img src={ iconClose } alt="Close" />
              </span>

              <div className="inner inner-info">
                <div className="title has-subtitle">HOW IT WORKS</div>
                <div className="subtitle">Pricing &amp; Info</div>
                <div className="big-title">2 tiles are ${ getMinQtyPrice() }</div>
                <div className="middle-title">Each additional tile is ${ getAdditionalItemPrice() }</div>
                <div className="middle-title">Shipping is FREE in US</div>
                <div className="medium-title">All sweet pix are</div>
                <ul>
                  <li>
                    <img src={ iconCheckmark } alt="Checkmark" />
                    8x8 in. 1/2 in. thick
                  </li>
                  <li>
                    <img src={ iconCheckmark } alt="Checkmark" />
                    High Resolution Matte Prints
                  </li>
                  <li>
                    <img src={ iconCheckmark } alt="Checkmark" />
                    Stick without wall damage
                  </li>
                  <li>
                    <img src={ iconCheckmark } alt="Checkmark" />
                    Waterproof &amp; UV resistant
                  </li>
                  <li>
                    <img src={ iconCheckmark } alt="Checkmark" />
                    Fast Delivery
                  </li>
                </ul>
                <span className="close-button" onClick={this.onToggleShowInfo}>Close</span>
              </div>
            </div>
          </div>)
          : null
        }

        { /*
        <footer className="footer">{ 'v.' + APP_VERSION }</footer>
        */ }
        </EditorProvider>
      </Provider>
    );
  }
}
