import React, { Component } from 'react'
import moment from 'moment'
import firebase from '../../firebase'
import { isUserAdmin, redirectToLogin, fetchCustomers, fetchOrganizations } from '../../firebaseHelpers'
import SearchBox from '../../components/SearchBox'
import Checkbox from '../../components/Checkbox'
import LoadingWheel from '../../components/LoadingWheel'
import DeleteButton from '../../components/DeleteButton'
import { Eye, Pen } from '../../icons'
import { Link } from 'react-router-dom'

const searchCustomers = firebase.functions().httpsCallable('searchCustomers');
const deleteCustomer = firebase.functions().httpsCallable('deleteCustomer');
const deleteCustomers = firebase.functions().httpsCallable('deleteCustomers');

export default class AdminCustomersScreen extends Component {
  constructor() {
  	super();

  	this.state = { 
  		entries: [],
      loading: false,
      search: '',
      selectedEntries: [],
      organizations: []
  	};
  }

  componentDidMount = async () => {
    const isAdmin = await isUserAdmin();

    if (isAdmin) {
      this.loadEntries();

      fetchOrganizations().then(results => {
        const organizations = [];
        results.forEach(result => {
          organizations.push(result.data());
        });
        this.setState({ organizations });
      });
    } else {
      redirectToLogin(this.props.history);
    }
  }

  loadEntries() {
    const { search } = this.state;

    this.setState({ loading: true });

    if (search.trim()) {
      searchCustomers({ search }).then(({data}) => {
        this.setState({ entries: data, loading: false });
      }).catch(error => {
        this.setState({ loading: false });
        alert(JSON.stringify(error));
      });
    } else {
      fetchCustomers().then(results => {
        const entries = [];
        results.forEach(result => {
          entries.push(Object.assign({}, result.data(), { id: result.id }));
        });
        this.setState({ entries, loading: false });
      }).catch(error => {
        console.log('error', error);
        this.setState({ loading: false });
        alert(JSON.stringify(error));
      });
    }
  }

  getOrganizationsDisplay = entry => {
    const { organizations } = this.state;

    const selection = organizations.filter(organization => {
      return entry.organizationDomain && organization.domainsLowercase.indexOf(entry.organizationDomain.toLowerCase()) !== -1
    }).map(organization => organization.businessName);

    return selection.join(', ');
  }

  render() {
  	const { entries, search, loading, selectedEntries } = this.state;

    return (
    	<div className="panel-screen">
    		<div className="panel-main justify-top">
    			<div className="table-wrapper">
            <div className="title-bar">
              <div className="section-title">Customers</div>
              <SearchBox 
                value={ search }
                onChange={search => this.setState({ search })}
                onSubmit={() => this.loadEntries()}
              />
              <button 
                className="sp-button bordered faded" 
                onClick={() => {
                  if (selectedEntries.length === 0) {
                    alert('Please select at least one row');
                  } else {
                    this.setState({ loading: true });
                    deleteCustomers({ ids: selectedEntries }).then(result => {
                      this.loadEntries();
                    }).catch(error => {
                      alert(JSON.stringify(error));
                    });
                  }
                }}
              >Delete</button>
            </div>

    				<table className="table">
    					<thead>
    						<tr>
	    						<th className="cell-checkbox">
	    							<Checkbox 
                      checked={ selectedEntries.length === entries.length }
                      onChange={() => {
                        this.setState({ selectedEntries: selectedEntries.length === entries.length ? [] : entries.map(entry => entry.id) });
                      }}
                    />
	    						</th>
	    						<th>Name</th>
	    						<th>Email</th>
	    						<th>Address</th>
	    						<th>Created</th>
	    						<th>Organizations</th>
	    						<th>Last Order</th>
	    						<th className="cell-action">Actions</th>
    						</tr>
    					</thead>
    					<tbody>
    						{
    							entries.map(entry => <tr key={ entry.id }>
    									<td className="cell-checkbox">
    										<Checkbox 
                          checked={ selectedEntries.indexOf(entry.id) !== -1 }
                          onChange={() => {
                            if (selectedEntries.indexOf(entry.id) === -1) {
                              selectedEntries.push(entry.id);
                            } else {
                              selectedEntries.splice(selectedEntries.indexOf(entry.id), 1);
                            }

                            this.setState({ selectedEntries });
                          }}
                        />
    									</td>
    									<td>
                        <Link to={ '/admin/customers/' + entry.id } className="text-button">
                          { entry.fullName }
                        </Link>
    									</td>
    									<td>{ entry.email }</td>
                      <td></td>
    									<td>{ moment((entry.createdAt.seconds ? entry.createdAt.seconds : entry.createdAt._seconds) * 1000).format('MM/DD/YY') }</td>
    									<td>{ this.getOrganizationsDisplay(entry) }</td>
    									<td>{ entry.lastOrderId }</td>
    									<td className="cell-action">
    										<Link to={ '/admin/customers/' + entry.id } className="text-button">
                          <Eye />
  											</Link>
  											<Link to={ '/admin/customers/' + entry.id } className="text-button">
                          <Pen />
												</Link>
												<DeleteButton onClick={() => {
                          this.setState({ loading: true });
                          deleteCustomer({ id: entry.id }).then(result => {
                            this.loadEntries();
                          }).catch(error => {
                            alert(JSON.stringify(error));
                          });
                        }} />
    									</td>
    								</tr>
    						)}

                {
                  !loading && entries.length === 0
                  ? <tr>
                    <td colSpan="8">
                      <em>No customers yet...</em>
                    </td>
                  </tr>
                  : null
                }
    					</tbody>
    				</table>
    			</div>
    		</div>

        {
          loading
          ? <LoadingWheel />
          : null
        }
			</div>
		)
  }
}
