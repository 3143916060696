import React from 'react';

export default function Switcher({ isActive, onToggle }) {
	return <div className="switcher" onClick={onToggle}>
  	<span>Off</span>
  	<div className={ isActive ? 'active' : '' }>
  		<div></div>
  	</div>
  	<span>On</span>
  </div>;
}
