import React, { FunctionComponent } from 'react'
import { Magnifier } from '../icons'

type SearchBoxProps = {
	value: string,
	onChange: any,
	onSubmit: any
}

const SearchBox: FunctionComponent<SearchBoxProps> = ({ value, onChange, onSubmit }: SearchBoxProps) => <div className="search-box">
		<input 
			type="text" 
			placeholder="Search" 
			value={ value } 
			onChange={event => {
				onChange(event.target.value)
			}}
			onKeyUp={event => {
				if (event.keyCode === 13) {
					onSubmit();
				}
			}}
		/>
		<button onClick={ () => onSubmit() }>
			<Magnifier />
		</button>
	</div>

export default SearchBox
