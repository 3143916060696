import React, { Component } from 'react'
import firebase from '../../firebase'
import { isUserAdmin, isUserConnected, redirectToLogin } from '../../firebaseHelpers'
import LoadingWheel from '../../components/LoadingWheel'
import SearchBox from '../../components/SearchBox'
import OrdersTable from '../../components/OrdersTable'

const searchOrders = firebase.functions().httpsCallable('searchOrders');
const deleteOrders = firebase.functions().httpsCallable('deleteOrders');

export default class AdminOrdersScreen extends Component {
  constructor() {
  	super();

  	this.state = { 
  		entries: [],
      loading: false,
      search: '',
      selectedEntries: []
  	};
  }

  componentDidMount = async () => {
    const isAdmin = await isUserAdmin();
    const isUser = await isUserConnected();

    if (isAdmin) {
      this.loadEntries();
    } else if (isUser) {
      this.props.history.push('/account');
    } else {
      redirectToLogin(this.props.history);
    }
  }

  loadEntries() {
    const { search } = this.state;

    this.setState({ loading: true });

    if (search.trim()) {
      searchOrders({ search }).then(({data}) => {
        this.setState({ entries: data, loading: false });
      }).catch(error => {
        this.setState({ loading: false });
        alert(JSON.stringify(error));
      });
    } else {
      firebase.firestore().collection('orders').where('isProcessed', '==', true).where('deletedAt', '==', null).orderBy('processedAt', 'desc').get().then(results => {
        const entries = [];
        results.forEach(result => {
          entries.push(Object.assign({}, result.data(), { id: result.id }));
        });
        this.setState({ entries, loading: false });
      }).catch(error => {
        this.setState({ loading: false });
        alert(JSON.stringify(error));
      });
    }
  }

  render() {
  	const { entries, search, loading, selectedEntries } = this.state;

    return (
    	<div className="panel-screen">
    		<div className="panel-main justify-top">
    			<div className="table-wrapper">
            <div className="title-bar">
              <div className="section-title">Orders</div>
              <SearchBox 
                value={ search }
                onChange={search => this.setState({ search })}
                onSubmit={() => this.loadEntries()}
              />
              <button 
                className="sp-button bordered faded" 
                onClick={() => {
                  if (selectedEntries.length === 0) {
                    alert('Please select at least one row');
                  } else {
                    this.setState({ loading: true });
                    deleteOrders({ ids: selectedEntries }).then(result => {
                      this.loadEntries();
                    }).catch(error => {
                      alert(JSON.stringify(error));
                    });
                  }
                }}
              >Delete</button>
            </div>
    				<OrdersTable 
              entries={entries} 
              loading={loading} 
              onDeleteStart={() => this.setState({ loading: true })}
              onDeleteSuccess={() => {
                this.loadEntries();
              }}
              onDeleteError={() => this.setState({ loading: false })}
              onChangeSelection={selectedEntries => this.setState({ selectedEntries })}
            />
            {/* <TablePagination totalPages={12} /> */}
    			</div>
    		</div>

        {
          loading
          ? <LoadingWheel />
          : null
        }
			</div>
		)
  }
}
