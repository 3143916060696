import React, { Component } from 'react'
import EditorContext from '../contexts/EditorContext'
import PhotoGrid from '../components/PhotoGrid'
import Combobox from '../components/Combobox'
import Checkbox from '../components/Checkbox'
import InfoModal from '../components/InfoModal'
import ErrorModal from '../components/ErrorModal'
import LoadingWheel from '../components/LoadingWheel'
import EmptyCartWarning from '../components/EmptyCartWarning'
import DeleteImageConfirmation from '../components/DeleteImageConfirmation'
import { getConnectedUser, uploadFile } from '../firebaseHelpers'
import { Close } from '../icons'
import paymentMethods from '../img/payment-methods.png'
import braintree from 'braintree-web-drop-in'
import BraintreeDropin from 'braintree-dropin-react'
import { BRAINTREE_TOKEN, APP_VERSION } from '../config.js'
import firebase from '../firebase'

const modifyOrder = firebase.functions().httpsCallable('modifyOrder');
const processOrder = firebase.functions().httpsCallable('processOrder');
const sendOrderEmails = firebase.functions().httpsCallable('sendOrderEmails');
const getOrderImageUploadURL = firebase.functions().httpsCallable('getOrderImageUploadURL');
const createCustomer = firebase.functions().httpsCallable('createCustomer');
const updateCustomer = firebase.functions().httpsCallable('updateCustomer');

class CartScreen extends Component {
  constructor() {
    super();

    this.state = {
      showCouponCodeForm: false,
      couponCode: '',

      checkoutForm: {
        name: '',
        email: '',
        company: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        createAccount: false
      },
      checkoutErrors: {
        name: false,
        email: false,
        company: false,
        address: false,
        city: false,
        state: false,
        zip: false,
        country: false
      },

      loading: false,
      user: null,
      infoModalMessage: '',
      errorModalMessage: '',

      imageForDeleteId: '',
      showRequestSupport: false
    };
  }

  componentDidMount = async () => {
    const connectedUser = await getConnectedUser();

    this.setState({ user: connectedUser });

    if (connectedUser) {
      firebase.firestore().collection('customers').where('__name__', '==' , connectedUser.uid).get().then(results => {
        results.forEach(result => {
          const data = result.data();
          const address = data.contactInfo && data.contactInfo.addresses && data.contactInfo.addresses.length > 0 ? data.contactInfo.addresses[0] : null;
          this.setState({
            checkoutForm: {
              name: data.fullName,
              email: data.email,
              company: address && address.companyName ? address.companyName : '',
              address: address && address.address ? address.address : '',
              city: address && address.city ? address.city : '',
              state: address && address.state ? address.state : '',
              zip: address && address.zip ? address.zip : '',
              country: address && address.country ? (address.country.toLowerCase() === 'canada' ? 'CA' : 'US') : ''
            }
          });
        });
      });
    }
  }

  onUpdateForm = event => {
    const { checkoutForm, checkoutErrors } = this.state;
    const { target } = event;
    const { name, value } = target;
    
    checkoutForm[name] = value;
    checkoutErrors[name] = false;

    this.setState({ 
      checkoutForm,
      checkoutErrors
    });
  }

  handlePaymentMethod = async ({nonce}) => {
    const { context } = this;
    const { images, orderId, orderToken } = context;
    const { checkoutForm, checkoutErrors, couponCode, user } = this.state;
    const { name, email, company, address, city, state, zip, country } = checkoutForm;
    const { history } = this.props;
    let hasErrors = false;

    if (context.getUnapprovedNameBadgeTilesCount() > 0) {
      this.setState({ errorModalMessage: 'Please approve all the name badge tiles before submitting the cart' });
      return;
    }

    if (name.trim() === '') {
      checkoutErrors.name = true;
      hasErrors = true;
    }

    if (email.trim() === '') {
      checkoutErrors.email = true;
      hasErrors = true;
    }

    if (address.trim() === '') {
      checkoutErrors.address = true;
      hasErrors = true;
    }

    if (city.trim() === '') {
      checkoutErrors.city = true;
      hasErrors = true;
    }

    if (state.trim() === '') {
      checkoutErrors.state = true;
      hasErrors = true;
    }

    if (zip.trim() === '') {
      checkoutErrors.zip = true;
      hasErrors = true;
    }

    if (country.trim() === '') {
      checkoutErrors.country = true;
      hasErrors = true;
    }

    if (hasErrors) {
      this.setState({ checkoutErrors });
    } else {
      this.setState({ infoModalMessage: 'Processing payment. Please keep this browser window open.' });

      modifyOrder({
        id: orderId,
        token: orderToken,
        fullName: name,
        email: email,
        address: {
          companyName: company,
          address: address,
          unitOrSuite: '1',
          city: city,
          state: state,
          zip: zip,
          country: country
        },
        promoCode: couponCode,
        appVersion: 'Web ' + APP_VERSION,
        tileSize: context.size
      }).then(({data}) => {
        context.setState({ price: data.price });
        const orderId = context.orderId;

        processOrder({ 
          id: orderId,
          token: orderToken,
          paymentData: {
            nonce,
            amount: data.price.total,
            sandbox: false
          }
        }).then(async ({data}) => {
          let unuploadedImageIndex = context.images.findIndex(image => !image.uploaded);
          while (unuploadedImageIndex !== -1) {
            const unuploadedImage = context.images[unuploadedImageIndex];
            this.setState({ infoModalMessage: 'Uploading image ' + (unuploadedImageIndex+1) +  ' of ' + images.length + '. Please keep this browser window open.' });

            const { data } = await getOrderImageUploadURL({ id: orderId, token: orderToken, imageId: unuploadedImage.id });
            context.updateImage(unuploadedImage.id, 'uploadUrl', data, true);

            const { blob } = await context.generateImage(unuploadedImage);
            await uploadFile(data, blob);
            context.updateImage(unuploadedImage.id, 'uploaded', true, true);

            unuploadedImageIndex = context.images.findIndex(image => !image.uploaded);
          }

          this.setState({ infoModalMessage: 'Completing your order. Please keep this browser window open.' });

          if (user) {
              firebase.firestore().collection('customers').where('__name__', '==' , user.uid).get().then(results => {
              let customer = null;

              results.forEach(result => {
                customer = result.data();
              });

              if (customer) {
                updateCustomer({
                  contactInfo: {
                    addresses: [{
                      address: address,
                      companyName: company,
                      state: state,
                      city: city,
                      zip: zip,
                      country: country
                    }]
                  }
                });
              } else {
                createCustomer({ fullName: email }).then(result => {
                  updateCustomer({
                    contactInfo: {
                      addresses: [{
                        address: address,
                        companyName: company,
                        state: state,
                        city: city,
                        zip: zip,
                        country: country
                      }]
                    }
                  });
                });
              }
            });
          }

          sendOrderEmails({
            id: orderId,
            token: orderToken
          }).then(async ({data}) => {
            this.setState({ infoModalMessage: '' });
            context.resetOrder(data);
            history.push('/confirmation');
          }).catch(error => {
            this.setState({ 
              infoModalMessage: '',
              errorModalMessage: error.message ? error.message : JSON.stringify(error)
            });
          });
        }).catch(error => {
          this.setState({ 
            infoModalMessage: '',
            errorModalMessage: error.message ? error.message : JSON.stringify(error)
          });
        });
      }).catch(error => {
        this.setState({ 
          infoModalMessage: '',
          errorModalMessage: error.message ? error.message : JSON.stringify(error)
        });
      });
    }
  }

  updateOrder = () => {
    const { checkoutForm, couponCode } = this.state;
    const { name, email, company, address, city, state, zip, country } = checkoutForm;

    this.setState({ loading: true });
    modifyOrder({
      id: this.context.orderId,
      token: this.context.orderToken,
      fullName: name,
      email: email,
      address: {
        companyName: company,
        address: address,
        unitOrSuite: '1',
        city: city,
        state: state,
        zip: zip,
        country: country
      },
      promoCode: couponCode,
      appVersion: 'Web ' + APP_VERSION,
      tileSize: this.context.size
    }).then(({data}) => {
      this.context.setState({ price: data.price });
      this.setState({
        loading: false,
        showCouponCodeForm: false
      });
    }).catch(error => {
      this.setState({ loading: false });
      alert(error.message ? error.message : JSON.stringify(error));
    });
  }

  render() {
    const { 
      showCouponCodeForm, 
      checkoutForm, 
      checkoutErrors, 
      loading, 
      user, 
      infoModalMessage,
      errorModalMessage,
      imageForDeleteId,
      couponCode,
      showRequestSupport
    } = this.state;
    const { onToggleAuthModal, history } = this.props;

    return <div className="panel-screen">
      <div className="panel-main">
        <div className="full-panel">
          <EditorContext.Consumer>
            {context => (
              <div className="order-summary">
                {
                  !context.images.length
                  ? <EmptyCartWarning />  
                  : null
                }

                <div className="large-title mb-xl">YOUR ORDER</div>

                <table className="order-table" cellPadding="0" cellSpacing="0">
                  <thead>
                    <tr>
                      <th>QTY</th>
                      <th align="left">DESCRIPTION</th>
                      <th>SIZE</th>
                      <th>PER TILE</th>
                      <th>TOTAL</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      context.collections.map(collection => {
                        const qty = context.getCollectionCartImagesCount(collection.id);

                        if (!qty) {
                          return null;
                        }

                        return (
                          <tr key={collection.id}>
                            <td align="center">{ qty }</td>
                            <td>{ collection.name }</td>
                            <td align="center">{ context.size } in.</td>
                            <td align="center">${ context.getItemPrice() }</td>
                            <td align="center">${ qty * context.getItemPrice() }</td>
                            <td>
                              <div className="row-remove" onClick={() => context.removeCartRow(collection.id)}>
                                <Close />
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    }
                  </tbody>
                  <tfoot>
                    <tr>
                      <td align="center">{ context.images.length }</td>
                      <td>Total Tiles</td>
                      <td align="center"></td>
                      <td align="center">Subtotal</td>
                      <td align="center">${ context.getSubtotalPrice() }</td>
                    </tr>
                  </tfoot>
                </table>

                {
                  context.collections.map(collection => {
                    const qty = context.getCollectionCartImagesCount(collection.id);
                    const imageIds = context.images.filter(image => image.collectionId === collection.id).map(image => image.id);
                    const previews = [];

                    for (let i in context.images) {
                      if (imageIds.indexOf(context.images[i].id) !== -1) {
                        previews.push({
                          id: i,
                          preview: context.images[i]
                        });
                      }
                    }

                    if (!qty) {
                      return null;
                    }

                    return (
                      <div className="photo-section" key={collection.id}>
                        <div className="photo-section-title">
                          <div>{collection.name} ({qty})</div>
                          <button className="sp-button bordered blue" onClick={() => context.setState({ showUploadModal: true })}>+ Add more</button>
                        </div>
                        <PhotoGrid 
                          images={previews} 
                          showOverlay={true}
                          showDeleteButton={true}
                          showCopyButton={true}
                          onCopy={({preview}) => {
                            context.setState({ loading: true });
                            context.copyImage(preview.id, true);
                          }}
                          onDelete={({preview}) => this.setState({ imageForDeleteId: preview.id })}
                          onClick={({preview}) => {
                            history.push('/editor/' + preview.collectionId + '/' + preview.id);
                          }}
                        />
                      </div>
                    );
                  })
                }
              </div>
            )}
          </EditorContext.Consumer>

          <div className="checkout-form">
            <div className="large-title">Checkout</div>
            
            <div className="form-group">
              <div className="formfield">
                <label>Full name</label>
                <input 
                  type="text" 
                  name="name" 
                  value={checkoutForm.name} 
                  onChange={e => this.onUpdateForm(e)} 
                  className={ checkoutErrors.name ? 'error' : '' }
                />
              </div>
              <div className="formfield">
                <label>Email</label>
                <input 
                  type="email" 
                  name="email" 
                  value={checkoutForm.email} 
                  onChange={e => this.onUpdateForm(e)} 
                  className={ checkoutErrors.email ? 'error' : '' }
                />
              </div>
            </div>
            <div className="form-group">
              <div className="form-group-title">
                <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 9.5C6.33696 9.5 5.70107 9.23661 5.23223 8.76777C4.76339 8.29893 4.5 7.66304 4.5 7C4.5 6.33696 4.76339 5.70107 5.23223 5.23223C5.70107 4.76339 6.33696 4.5 7 4.5C7.66304 4.5 8.29893 4.76339 8.76777 5.23223C9.23661 5.70107 9.5 6.33696 9.5 7C9.5 7.3283 9.43534 7.65339 9.3097 7.95671C9.18406 8.26002 8.99991 8.53562 8.76777 8.76777C8.53562 8.99991 8.26002 9.18406 7.95671 9.3097C7.65339 9.43534 7.3283 9.5 7 9.5ZM7 0C5.14348 0 3.36301 0.737498 2.05025 2.05025C0.737498 3.36301 0 5.14348 0 7C0 12.25 7 20 7 20C7 20 14 12.25 14 7C14 5.14348 13.2625 3.36301 11.9497 2.05025C10.637 0.737498 8.85652 0 7 0Z" fill="#434343"/>
                </svg>
                <span>Shipping Address</span>
              </div>
              <div className="formfield">
                <label>Company</label>
                <input 
                  type="text" 
                  name="company" 
                  value={checkoutForm.company} 
                  onChange={e => this.onUpdateForm(e)} 
                />
              </div>
              <div className="formfield">
                <label>Address</label>
                <input 
                  type="text" 
                  name="address" 
                  value={checkoutForm.address} 
                  onChange={e => this.onUpdateForm(e)} 
                  className={ checkoutErrors.address ? 'error' : '' }
                />
              </div>
              <div className="formfield">
                <label>City</label>
                <input 
                  type="text" 
                  name="city" 
                  value={checkoutForm.city} 
                  onChange={e => this.onUpdateForm(e)} 
                  className={ checkoutErrors.city ? 'error' : '' }
                />
              </div>
              <div className="formfield">
                <label>State / prov.</label>
                <input 
                  type="text" 
                  name="state" 
                  value={checkoutForm.state} 
                  onChange={e => this.onUpdateForm(e)} 
                  className={ checkoutErrors.state ? 'error' : '' }
                />
              </div>
              <div className="formfield">
                <label>Postal code</label>
                <input 
                  type="text" 
                  name="zip" 
                  value={checkoutForm.zip} 
                  onChange={e => this.onUpdateForm(e)} 
                  className={ checkoutErrors.zip ? 'error' : '' }
                />
              </div>
              <div className="formfield">
                <label>Country</label>
                <Combobox 
                  value={checkoutForm.country}
                  options={[
                    {key: '', value: ''}, 
                    {key: 'US', value: 'United States'}, 
                    {key: 'CA', value: 'Canada'}
                  ]} 
                  name="country"
                  customUI={true}
                  onChange={e => this.onUpdateForm(e)}
                  className={ checkoutErrors.country ? 'error' : '' }
                />
              </div>
              {
                user
                ? null
                : <div className="create-account">
                  <Checkbox 
                    checked={checkoutForm.createAccount}
                    label="Create Account" 
                    onChange={event => {
                      checkoutForm.createAccount = !checkoutForm.createAccount;
                      this.setState({ checkoutForm });
                    }}
                  />

                  <span className="text-button" onClick={() => onToggleAuthModal()}>Login</span>
                </div>
              }
            </div>

            {
              showCouponCodeForm
              ? <div className="form-group form-group-code">
                <span>Enter code:</span>
                <form onSubmit={event => event.preventDefault()}>
                  <input type="text" value={couponCode} onChange={e => this.setState({ couponCode: e.target.value })} />
                  <button className="sp-button" onClick={() => this.updateOrder()}>
                    Apply
                  </button>
                </form>
              </div>
              : <div className="form-group form-group-code">
                <span className="clickable" onClick={() => this.setState({ showCouponCodeForm: true })}>
                  {
                    couponCode
                    ? ('Coupon code: ' + couponCode)
                    : 'Have a coupon code?'
                  }
                </span>
              </div>
            }

            <div className="form-group">
              <dl className="totals">
                <dt>Subtotal</dt>
                <dd>
                  ${ this.context.getSubtotalPrice() }
                </dd>
                <dt>Shipping</dt>
                <dd>
                  ${ this.context.getShippingPrice() }
                </dd>
                <dt>Tax</dt>
                <dd>
                  ${ this.context.getTaxPrice() }
                </dd>
                {
                  this.context.price && this.context.price.promoCodeDiscountString
                  ? <>
                    <dt className="discount-line">Discount</dt>
                    <dd className="discount-line">
                      -{ this.context.price.promoCodeDiscountString }
                    </dd>
                  </>
                  : null
                }
                <dt className="total-line">Total</dt>
                <dd className="total-line">
                  ${ this.context.getTotalPrice() }
                </dd>
              </dl>
            </div>

            <div className="form-group-title">
              <svg width="27" height="16" viewBox="0 0 27 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.5125 4H2.6125V2H23.5125V4ZM23.5125 14H2.6125V8H23.5125V14ZM23.5125 0H2.6125C1.16256 0 0 0.89 0 2V14C0 14.5304 0.275245 15.0391 0.765183 15.4142C1.25512 15.7893 1.91962 16 2.6125 16H23.5125C24.2054 16 24.8699 15.7893 25.3598 15.4142C25.8498 15.0391 26.125 14.5304 26.125 14V2C26.125 0.89 24.9494 0 23.5125 0Z" fill="#434343"/>
              </svg>
              <span>Payment details (secure)</span>
            </div>

            {
              this.context.price && this.context.price.promoCodeDiscountString && this.context.price.total === 0
              ? <button className="checkout-button" onClick={this.handlePaymentMethod}>
                Place order
              </button>
              : <BraintreeDropin
                braintree={braintree}
                options={{
                  locale: 'en_US',
                  vaultManager: false
                }}
                authorizationToken={BRAINTREE_TOKEN}
                handlePaymentMethod={this.handlePaymentMethod}
                onError={this.onError}
                renderSubmitButton={({onClick, isDisabled, text}) => {
                  return <button 
                    onClick={onClick}
                    disabled={isDisabled}
                    className="checkout-button">
                      Place order
                  </button>
                }}              
              />
            }
            
            {/*
            <div className="formfield">
              <label>Credit card</label>
              <input type="text" />
            </div>
            <div className="formfield formfield-cc2">
              <label>Expiration</label>
              <input type="text" />
              <label>CVV</label>
              <input type="text" />
            </div>
            */}

            <figure className="payment-methods">
              <img src={ paymentMethods } alt="Payment methods" />
            </figure>

            <div className="shipping-cta">Fast shipping turn around!</div>
            <div className="shipping-estimate">
              <span>Order arrives in 5 - 7 days.</span>
              <svg width="26" height="19" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.0909 17.1364C21.0718 17.1364 21.8636 16.3445 21.8636 15.3636C21.8636 14.3827 21.0718 13.5909 20.0909 13.5909C19.11 13.5909 18.3182 14.3827 18.3182 15.3636C18.3182 16.3445 19.11 17.1364 20.0909 17.1364ZM21.8636 6.5H18.9091V9.45455H24.18L21.8636 6.5ZM5.90909 17.1364C6.89 17.1364 7.68182 16.3445 7.68182 15.3636C7.68182 14.3827 6.89 13.5909 5.90909 13.5909C4.92818 13.5909 4.13636 14.3827 4.13636 15.3636C4.13636 16.3445 4.92818 17.1364 5.90909 17.1364ZM22.4545 4.72727L26 9.45455V15.3636H23.6364C23.6364 17.3255 22.0527 18.9091 20.0909 18.9091C18.1291 18.9091 16.5455 17.3255 16.5455 15.3636H9.45455C9.45455 17.3255 7.87091 18.9091 5.90909 18.9091C3.94727 18.9091 2.36364 17.3255 2.36364 15.3636H0V2.36364C0 1.05182 1.05182 0 2.36364 0H18.9091V4.72727H22.4545ZM2.36364 2.36364V13H3.26182C3.91182 12.2791 4.85727 11.8182 5.90909 11.8182C6.96091 11.8182 7.90636 12.2791 8.55636 13H16.5455V2.36364H2.36364ZM4.72727 7.68182L6.5 5.90909L8.27273 7.68182L12.4091 3.54546L14.1818 5.31818L8.27273 11.2273L4.72727 7.68182Z" fill="#BBBBBB"/>
              </svg>
            </div>

            <div className="centered-button-holder">
              <span className="text-button" onClick={() => this.setState({showRequestSupport: true})}>Request support</span>
            </div>
          </div>
        </div>
      </div>

      {
        showRequestSupport
        ? <div className="modal-overlay">
          <div className="modal-content">
            <div className="module-inner">
              <div className="below-headline mt-0">
                Copy this code and email it to <a href="mailto:support@sweetpixapp.com">support@sweetpixapp.com</a>
              </div>
              <textarea id="request-support-textarea" value={this.context.getLocalData()}></textarea>
              <div className="centered-button-holder spaced">
                <span className="text-button" onClick={() => {
                  document.getElementById('request-support-textarea').select();
                  document.execCommand('copy');
                }}>Copy code</span>
                <span className="text-button" onClick={() => this.setState({showRequestSupport: false})}>Close</span>
              </div>
            </div>
          </div>
        </div>
        : null
      }

      {
        loading
        ? <LoadingWheel />
        : null
      }

      {
        infoModalMessage
        ? <InfoModal message={infoModalMessage} />
        : null
      }

      {
        errorModalMessage
        ? <ErrorModal message={errorModalMessage} onRetry={() => {
          this.setState({ errorModalMessage: '' });
        }} />
        : null
      }

      {
        imageForDeleteId
        ? <DeleteImageConfirmation 
          onCancel={() => this.setState({ imageForDeleteId: '' })}
          onDelete={() => {
            this.context.setState({ loading: true });
            this.context.popImage(imageForDeleteId, true);
            this.setState({ imageForDeleteId: '' })
          }}
        />
        : null
      }
    </div>
  }
}

CartScreen.contextType = EditorContext;

export default CartScreen;
