export function get8x8Price() {
	return 11;
}

export function get12x12Price() {
	return 25;
}

export function getMinQty() {
	return 2;
}

export function getMinQtyPrice() {
	return 25;
}

export function getAdditionalItemPrice() {
	return 11;
}

export function getAdditionalPrice(photos) {
	const minQty = getMinQty();
	const additionalItemPrice = getAdditionalItemPrice();
	
	return (photos.length - minQty) * additionalItemPrice;
}

export function getShippingCosts(country) {
	return country && country === 'CA' ? 9.98 : 0;
}

export function getPromoCodeDiscount(promoCode, photos, shippingCosts) {
	const tilesPrice = getTilesPrice(photos);

	if (promoCode && promoCode.type) {
		if ((promoCode.valid4OrMore && photos.length > 3) || !promoCode.valid4OrMore) {
			if (promoCode.type === 1) {
				return promoCode.amount;
			} else if (promoCode.type === 2) {
				return tilesPrice * promoCode.amount / 100;
			} else if (promoCode.type === 3) {
				return shippingCosts;
			}
		}
	}

	return 0;
}

export function getTilesPrice(photos) {
	let price = 0;
	const minQty = getMinQty();
	const minQtyPrice = getMinQtyPrice();

	if (photos.length > 0) {
		price = minQtyPrice;

		if (photos.length > minQty) {
			price += getAdditionalPrice(photos);
		}
	}

	return price;
}

export function getTotalPrice(photos, country, promoCode) {
	let price = getTilesPrice(photos);

	if (promoCode && promoCode.type && promoCode.type === 3)
		return getShippingCosts(country);

	if (country)
		price += getShippingCosts(country) * 1;

	if (promoCode && promoCode.type)
		price -= getPromoCodeDiscount(promoCode, photos, getShippingCosts(country));

	return price;
}
