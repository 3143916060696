import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import firebase from '../../firebase'
import { getConnectedUser, redirectToLogin } from '../../firebaseHelpers'
import EditorContext from '../../contexts/EditorContext'
import Checkbox from '../../components/Checkbox'
import { Eye, Pen } from '../../icons'

export default class AccountBookmarksScreen extends Component {
	constructor() {
  	super();

  	this.state = { 
  		entries: [],
      loading: false,
      selectedEntries: [],
      user: null
  	};
  }

  componentDidMount = async () => {
    const connectedUser = await getConnectedUser();

    this.setState({ user: connectedUser });

    if (connectedUser) {
      this.loadEntries(connectedUser);
    } else {
      redirectToLogin(this.props.history);
    }
  }

  loadEntries(user=null) {
    if (!user) {
      user = this.state.user;
    }

    this.setState({ loading: true });

    firebase.firestore().collection('orders').where('email', '==', user.email).where('deletedAt', '==', null).where('isProcessed', '==', false).orderBy('createdAt', 'desc').get().then(results => {
      const entries = [];
      results.forEach(result => {
        entries.push(Object.assign({}, result.data(), { id: result.id }));
      });

      this.setState({ entries, loading: false });
    }).catch(error => {
      console.log('error', error);
      this.setState({ loading: false });
      alert(JSON.stringify(error));
    });
  }

  render() {
  	const { entries, loading } = this.state;

    return (
    	<div className="panel-screen">
    		<div className="panel-main justify-top">
    			<div className="table-wrapper">
    				<div className="section-title">CURRENT ORDER</div>

    				<EditorContext.Consumer>
          		{context => (
          			<>
	          			<table className="table">
			    					<thead>
			    						<tr>
				    						<th className="cell-checkbox">
				    							<Checkbox />
				    						</th>
				    						<th>Qty</th>
				    						<th>Order label</th>
				    						<th>Created</th>
				    						<th>Size</th>
				    						<th>Status</th>
				    						<th></th>
				    						<th className="cell-action">Actions</th>
			    						</tr>
			    					</thead>
			    					<tbody>
			    						{
			    							entries.map(entry => <tr key={entry.id}>
		    									<td className="cell-checkbox">
		    										<Checkbox />
		    									</td>
		    									<td>
		    										{ entry.imageCollections.map(collection => collection.images.length).reduce((a, b) => a + b, 0) }
		    									</td>
		    									<td>
		                        <Link to={ '/account/orders/' + entry.id } className="text-button">
			                        { entry.id }
			                      </Link>
		                      </td>
		    									<td>{ moment().format('MM/DD/YY') }</td>
		    									<td>{ entry.tileSize }</td>
		    									<td>Pending</td>
		    									<td className="cell-helper">
		    										<span className="text-button" onClick={() => {
		    											context.reloadOrder(entry.id, entry.token);
		    											this.props.history.push('/cart');
		    										}}>Finish Order</span>
		    									</td>
		    									<td className="cell-action">
		    										<Link to={ '/account/orders/' + entry.id } className="text-button">
			                        <Eye />
			                      </Link>
		    										<Link to="/" className="text-button">
		                          <Pen />
														</Link>
		    									</td>
		    								</tr>
			    						)}
			    					</tbody>
			    				</table>

			    				{
			              !loading && entries.length === 0
			              ? <div>
			                <em>No orders yet</em>
			              </div>
			              : null
			            }
		            </>
        			)}
      			</EditorContext.Consumer>
    			</div>
    		</div>
			</div>
		)
  }
}
