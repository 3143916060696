import React, { FunctionComponent } from 'react'

type DatepickerProps = {
	name: string,
	value: any,
	onChange: any
}

const Datepicker: FunctionComponent<DatepickerProps> = ({ name, value, onChange }: DatepickerProps) => <div className="datepicker">
		<input 
			name={name}
			type="date" 
			value={value}
			onChange={ event => onChange(event) }
		/>
	</div>

export default Datepicker
