import React, { Component } from 'react';

export default class LoadingPanel extends Component {
	render() {
		const { percent } = this.props;

		return (
			<div className="loading-panel">
				<div className="loading-panel-inside">
					<div className="title">Uploading photos...</div>
					<div className="subtitle">Please wait.</div>
					<div className="loading-bar">
						<div className="loading-bar-progress" style={{ width: percent + '%' }}></div>
					</div>
					<div className="info">Your images are uploading. Do not close this window until uploading is complete.</div>
				</div>
			</div>
		)
  	}
}

LoadingPanel.defaultProps = {
	percent: 0
};
