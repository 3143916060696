import React from 'react';

export default function Copy() {
  return <svg width="23" height="26" viewBox="0 0 23 26" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g filter="url(#filter0_d)">
				<path d="M11.0062 14.2168H5.19235C4.7518 14.2168 4.32929 14.0422 4.01778 13.7314C3.70626 13.4205 3.53125 12.9989 3.53125 12.5594V2.61451C3.53125 2.17492 3.70626 1.75333 4.01778 1.44249C4.32929 1.13166 4.7518 0.957031 5.19235 0.957031H15.159V2.61451H5.19235V12.5594H11.0062V10.9019L14.3284 13.3881L11.0062 15.8743V14.2168ZM17.6506 17.5318V5.92945H8.51456V10.9019H6.85346V5.92945C6.85346 5.48986 7.02847 5.06828 7.33998 4.75744C7.6515 4.44661 8.07401 4.27198 8.51456 4.27198H17.6506C18.0912 4.27198 18.5137 4.44661 18.8252 4.75744C19.1367 5.06828 19.3117 5.48986 19.3117 5.92945V17.5318C19.3117 17.9714 19.1367 18.3929 18.8252 18.7038C18.5137 19.0146 18.0912 19.1892 17.6506 19.1892H8.51456C8.07401 19.1892 7.6515 19.0146 7.33998 18.7038C7.02847 18.3929 6.85346 17.9714 6.85346 17.5318V15.8743H8.51456V17.5318H17.6506Z" fill="white"/>
			</g>
			<defs>
				<filter id="filter0_d" x="0.53125" y="0.957031" width="21.7805" height="24.2322" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
					<feFlood floodOpacity="0" result="BackgroundImageFix"/>
					<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
					<feOffset dy="3"/>
					<feGaussianBlur stdDeviation="1.5"/>
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
				</filter>
			</defs>
		</svg>
}
