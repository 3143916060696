import React from 'react'
import EditorContext from '../contexts/EditorContext'
import { Close } from '../icons'
import image from '../img/learn-more.png'

export default function LearnMoreModal() {
	return (
		<div className="modal-overlay">
			<EditorContext.Consumer>
        {context => (
					<div className="modal-content height-l">
						<span className="close-btn" onClick={() => context.setState({ showLearnMoreModal: false })}>
			  			<Close />
		  			</span>
						<div className="module-inner">
							<figure>
								<img src={image} alt="Modal artwork" width="300" />
							</figure>
							<div className="headline">NAMING YOUR AGENTS</div>
							<div className="below-headline">
								Adding multiple images at once, ensure each image is named with the person’s First and Last name.
								For example <strong>"Ashley-Jones.jpg"</strong>
							</div>
							<div className="buttons">
								<button className="sp-button" onClick={() => context.setState({ showLearnMoreModal: false })}>
									OK, GOT IT!
								</button>
							</div>
							<div className="note">Acceptable file types: jpg, png, pdf, tiff, gif</div>
						</div>
					</div>
				)}
      </EditorContext.Consumer>
		</div>
	)
}
