import React, { Component } from 'react'
import moment from 'moment'
import firebase from '../../firebase'
import { isUserAdmin, redirectToLogin } from '../../firebaseHelpers'
import SearchBox from '../../components/SearchBox'
import Checkbox from '../../components/Checkbox'
import LoadingWheel from '../../components/LoadingWheel'
import DeleteButton from '../../components/DeleteButton'
import { Eye, Pen } from '../../icons'
import { Link } from 'react-router-dom'

const searchPromoCodes = firebase.functions().httpsCallable('searchPromoCodes');
const deletePromoCode = firebase.functions().httpsCallable('deletePromoCode');

export default class AdminPromoCodesScreen extends Component {
  constructor() {
  	super();

  	this.state = {
      entries: [],
      loading: false,
      search: ''
    };
  }

  componentDidMount = async () => {
    const isAdmin = await isUserAdmin();

    if (isAdmin) {
      this.loadEntries();
    } else {
      redirectToLogin(this.props.history);
    }
  }

  loadEntries() {
    const { search } = this.state;

    this.setState({ loading: true });

    if (search.trim()) {
      searchPromoCodes({ search }).then(({data}) => {
        this.setState({ entries: data, loading: false });
      }).catch(error => {
        this.setState({ loading: false });
        alert(JSON.stringify(error));
      });
    } else {
      firebase.firestore().collection('promoCodes').orderBy('createdAt', 'desc').get().then(results => {
        const entries = [];
        results.forEach(result => {
            entries.push(Object.assign({}, result.data(), { id: result.id }));
        });
        this.setState({ entries, loading: false });
      }).catch(error => {
        this.setState({ loading: false });
        alert(JSON.stringify(error));
      });
    }
  }

  render() {
  	const { entries, search, loading } = this.state;

    return (
    	<div className="panel-screen">
    		<div className="panel-main justify-top">
    			<div className="table-wrapper">
            <div className="title-bar">
              <div className="section-title">Promo Codes</div>
              <SearchBox 
                value={ search }
                onChange={search => this.setState({ search })}
                onSubmit={() => this.loadEntries()}
              />
              <div className="title-bar-buttons">
                <button className="sp-button bordered faded">Delete</button>
                <Link className="sp-button bordered blue" to="/admin/promo-codes/new">+ New Promo</Link>
              </div>
            </div>

    				<table className="table">
    					<thead>
    						<tr>
	    						<th className="cell-checkbox">
	    							<Checkbox />
	    						</th>
	    						<th>ID</th>
	    						<th>Code</th>
	    						<th>Type</th>
	    						<th>One-time Use</th>
	    						<th>Used</th>
	    						<th>Valid</th>
	    						<th>Start Date</th>
	    						<th>End Date</th>
	    						<th className="cell-action">Actions</th>
    						</tr>
    					</thead>
    					<tbody>
    						{
    							entries.map(entry => <tr key={ entry.id }>
    									<td className="cell-checkbox">
    										<Checkbox />
    									</td>
    									<td>{ entry.id }</td>
    									<td>
    										<Link to={ '/admin/promo-codes/' + entry.id } className="text-button">
                          { entry.code }
                        </Link>
    									</td>
    									<td>{ entry.type }</td>
    									<td>{ entry.oneTimeUse ? 'Yes' : 'No' }</td>
    									<td></td>
    									<td></td>
    									<td>{ moment(entry.startDate.seconds * 1000).format('MM/DD/YY') }</td>
    									<td>{ entry.endDate ? moment(entry.endDate.seconds * 1000).format('MM/DD/YY') : '-' }</td>
    									<td className="cell-action">
    										<Link to={ '/admin/promo-codes/' + entry.id } className="text-button">
                          <Eye />
  											</Link>
  											<Link to={ '/admin/promo-codes/' + entry.id } className="text-button">
                          <Pen />
												</Link>
												<DeleteButton onClick={() => {
                          this.setState({ loading: true });
                          deletePromoCode({ code: entry.code }).then(result => {
                            this.loadEntries();
                          }).catch(error => {
                            alert(JSON.stringify(error));
                          });
                        }} />
    									</td>
    								</tr>
    						)}

                {
                  !loading && entries.length === 0
                  ? <tr>
                    <td colSpan="10">
                      <em>No promo codes yet...</em>
                    </td>
                  </tr>
                  : null
                }
    					</tbody>
    				</table>
    			</div>
    		</div>

        {
          loading
          ? <LoadingWheel />
          : null
        }
			</div>
		)
  }
}

