import React, { Component } from 'react'
import EditorContext from '../contexts/EditorContext'
import PhotoGrid from '../components/PhotoGrid'
import LoadingWheel from '../components/LoadingWheel'
import firebase from '../firebase'

const getOrderImageDownloadURLs = firebase.functions().httpsCallable('getOrderImageDownloadURLs');

class ConfirmationScreen extends Component {
  constructor() {
    super();

    this.state = {
      order: null,
      images: [],
      loading: true
    };
  }

  componentDidMount() {
    this.loadEntry();
  }

  loadEntry = () => {
    const { previousOrder } = this.context;

    if (previousOrder) {
      this.setState({ 
        loading: true,
        order: previousOrder
      });

      getOrderImageDownloadURLs({ id: previousOrder.id, token: previousOrder.token }).then(({data}) => {
        const ids = Object.keys(data)

        this.setState({
          loading: false,
          images: Object.values(data).map((image, index) => {
            return { 
              id: ids[index],
              url: image 
            }
          })
        });
      }).catch(error => {
        alert(JSON.stringify(error));
        this.setState({
          loading: false
        });
      });
    }
  }

  render() {
    const { order, images, loading } = this.state;

    if (!order) {
      return null;
    }

    return <div className="panel-screen">
      <div className="panel-main">
        <div className="full-panel">
          <div className="order-summary centered">
            <div className="large-title mb-xl">CONFIRMATION</div>
            <div className="order-message">
              <div className="order-number">ORDER #: { order.id }</div>
              <div>
                <strong>Thank you for your order!</strong>
                We’ve captured your images and will begin to process your order. We'll send you a tracking email as soon as it's on its way.
              </div>
            </div>
            <div className="small-title">SHIPPING TO</div>
            <div className="shipping-address">
              <strong>{ order.fullName }</strong>
              <br/>
              { order.address.address }
              <br/>
              { order.address.city }, { order.address.state }, { order.address.zip }, { order.address.country }
              <br/>
              <a href={ 'mailto:' + order.email } target="_blank" rel="noopener noreferrer">{ order.email }</a>
            </div>
            <div className="small-title mb-m">ORDER SUMMARY</div>
            <table className="order-table" cellPadding="0" cellSpacing="0">
              <thead>
                <tr>
                  <th>QTY</th>
                  <th align="left">DESCRIPTION</th>
                  <th>SIZE</th>
                  <th className="width-s">PER TILE</th>
                  <th className="width-s" align="right">TOTAL</th>
                </tr>
              </thead>
              <tbody>
                {
                  order.imageCollections.map((collection, index) => (
                      <tr key={index}>
                        <td align="center">{ collection.images.length }</td>
                        <td>{ collection.name }</td>
                        <td align="center">{ order.tileSize } in.</td>
                        <td align="center">${ order.price.imageCollections[index].tilePrice / 100 }</td>
                        <td align="right">${ order.price.imageCollections[index].totalPrice / 100 }</td>
                      </tr>
                    )
                  )
                }
              </tbody>
              <tfoot>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="regular-text">Subtotal</td>
                  <td className="regular-text" align="right">${ order.price.subTotal / 100 }</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="regular-text">Shipping</td>
                  <td className="regular-text" align="right">${ order.price.shipping / 100 }</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="regular-text">Tax</td>
                  <td className="regular-text" align="right">${ order.price.tax / 100 }</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>TOTAL</td>
                  <td align="right">${ order.price.total / 100 }</td>
                </tr>
              </tfoot>
            </table>

            {
              order.imageCollections.map((collection, index) => (
                <div className="photo-section" key={index}>
                  <div className="photo-section-title">
                    <div>{collection.name} ({collection.images.length})</div>
                  </div>
                  <PhotoGrid 
                    images={images.filter(image => collection.images.indexOf(image.id) !== -1)} 
                  />
                </div>
              ))
            }
          </div>
        </div>
      </div>

      {
        loading
        ? <LoadingWheel />
        : null
      }
    </div>
  }
}

ConfirmationScreen.contextType = EditorContext;

export default ConfirmationScreen;
