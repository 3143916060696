import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as photosActions from '../actions/photosActions';
import iconClose from '../img/icon-close.png';
import iconCheckmark from '../img/icon-checkmark-disc.png';
import LoadingWheel from '../components/LoadingWheel';

class ThanksScreen extends Component {
	componentDidMount() {
		const { photos } = this.props;

		if (photos.length < 1) {
			this.props.history.push('/photos');
		}

		if (window.ga)
			window.ga('send', 'pageview', '/thanks');
		
		document.getElementsByTagName('body')[0].className = 'checkout';
	}

	componentWillUnmount() {
		const body = document.getElementsByTagName('body')[0];
		body.className = body.className.replace('checkout', '');
	}

  	onClose = () => {
  		this.props.photosActions.receivePhotos([]);
		this.props.history.push('/upload');
	}

	render() {
  		const { photos } = this.props;

  		return (
    		<div className="content content-modal">
    			<div className={ 'photo-grid photo-grid-' + photos.length }>
	    			{
	    				photos.map((photo, i) => {
	    					return (<div className="tile" key={i}>
	    								<div className="photo">
	    									{ photo.loading ? <LoadingWheel /> : <img src={ photo.selection } alt="Tile" /> }
	    								</div>
									</div>)
	    				})
	    			}
	    			<div className="add-more"></div>
				</div>

				<div className="global-modal global-modal-checkout" onClick={ this.onClose }>
					<div className="outer outer-thanks" onClick={ (e) => { e.stopPropagation(); } }>
						<div className="inner inner-thanks">
							<span onClick={ this.onClose } className="close">
								<img src={ iconClose } alt="Close" />
							</span>
							<figure>
								<img src={ iconCheckmark } alt="Checkmark" />
							</figure>
							<div className="big-title">
								Thank you <br/>
								for your order!
							</div>
			    			<p>
				    			Your order has been successfully accepted <br/>
				    			and a confirmation email has been sent. Your <br/>
				    			images are being processed and your tiles <br/>
				    			should arrive in 3 - 5 days.
			    			</p>
			    			<p>
				    			If you need help or have any questions <br/>
				    			<a href="mailto:support@sweetpixapp.com">support@sweetpixapp.com</a>
			    			</p>
							<span onClick={ this.onClose } className="close-button">Close</span>
						</div>
					</div>
				</div>
			</div>
		)
  	}
}

function mapStateToProps(state, action) {
  return {
    photos: state.photos,
    address: state.address
  }
}
function mapDispatchToProps(dispatch) {
  return {
  	photosActions: bindActionCreators(photosActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThanksScreen);
