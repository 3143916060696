import React from 'react'

export default function ErrorModal({ message, onRetry }) {
	return (
		<div className="modal-overlay">
			<div className="modal-content">
				<div className="module-inner">
					<figure>
						<svg width="85" height="74" viewBox="0 0 85 74" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M39.0359 1.99999C40.5755 -0.666675 44.4245 -0.666667 45.9641 2L83.6362 67.25C85.1758 69.9167 83.2513 73.25 80.1721 73.25H4.82789C1.74868 73.25 -0.175807 69.9167 1.36379 67.25L39.0359 1.99999Z" fill="#E96071"/>
							<circle cx="42.345" cy="57.5432" r="3.345" fill="#FAFAFA"/>
							<rect x="39.2227" y="29" width="6.244" height="22.746" fill="#FAFAFA"/>
						</svg>
					</figure>
					<div className="headline mb-0">Uh oh... Something went wrong.</div>
					<div className="below-headline mt-0 mb-3">{message}</div>
					<div className="buttons">
						<button className="sp-button bordered w5" onClick={() => onRetry()}>Retry</button>
						<a href="mailto:support@sweetpixapp.com" className="sp-button bordered w5">Need Help</a>
					</div>
				</div>
			</div>
		</div>
	)
}
