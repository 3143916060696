import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import firebase from '../../firebase'
import { getConnectedUser, redirectToLogin } from '../../firebaseHelpers'
import PhotoGrid from '../../components/PhotoGrid'
import LoadingWheel from '../../components/LoadingWheel'
import { Back } from '../../icons'

const getOrderImageDownloadURLs = firebase.functions().httpsCallable('getOrderImageDownloadURLs');

export default class AdminOrderScreen extends Component {
	constructor() {
  	super();

  	this.state = { 
      loading: false,
  		images: [],
  		order: null
  	};
  }

  componentDidMount = async () => {
    const connectedUser = await getConnectedUser();

    this.setState({ user: connectedUser });

    if (connectedUser) {
      this.loadEntry();
    } else {
      redirectToLogin(this.props.history);
    }
  }

  loadEntry = () => {
    const { match } = this.props;

    this.setState({ loading: true  });

    firebase.firestore().collection('orders').where('__name__', '==' , match.params.id).get().then(results => {
      results.forEach(result => {
        const data = result.data();

        this.setState({ order: data });

        getOrderImageDownloadURLs({ id: match.params.id, token: data.token }).then(({data}) => {
			    this.setState({
		        loading: false,
		        images: Object.values(data).map(image => {
		        	return {
		        		id: image,
		        		downloadURL: image
		        	}
		        })
		      });
			  }).catch(error => {
			    alert(JSON.stringify(error));
			    this.setState({
		        loading: false
		      });
			  });
      });
    }).catch(error => {
      alert(JSON.stringify(error));
      this.setState({
        loading: false
      });
    });
  }

  onUpdateForm = event => {}

	render() {
		const { loading, order, images } = this.state;

  	return (
    	<div className="panel-screen">
    		<div className="panel-main justify-top">
    			<div className="table-wrapper">
    				<div className="admin-action">
    					<Link to="/account/orders" className="text-button">
    						<Back />
    						Back
  						</Link>
    				</div>

            {
              order
              ? <>
	              <div className="section-subtitle">ORDER INFORMATION</div>
		            <div className="form-section form-section-cols">
			            <div className="formfield">
			            	<label>
			            		<strong>Order</strong>
		            		</label>
			            	<span className="info-element">
			            		<strong>#{order.id}</strong>
		            		</span>
			            </div>
			            <div className="formfield">
			            	<label>Date</label>
			            	<span className="info-element">
			            		{ moment(order.createdAt.seconds * 1000).format('dddd, MMMM D, YYYY') } at { moment(order.createdAt.seconds * 1000).format('hh:mm:ss A Z') }
			            	</span>
			            </div>
			            <div className="formfield">
			            	<label>Name</label>
			            	<span className="info-element">{order.fullName}</span>
			            </div>
			            <div className="formfield">
			            	<label>Email</label>
			            	<span className="info-element">{order.email}</span>
			            </div>
			            <div className="formfield">
			            	<label>Address</label>
			            	<span className="info-element">{order.address.address ? order.address.address : ''}</span>
			            </div>
			            <div className="formfield">
			            	<label>City, State</label>
			            	<span className="info-element">
			            		{order.address.city ? order.address.city : ''}, {order.address.state ? order.address.state : ''}
			            	</span>
			            </div>
			            <div className="formfield">
			            	<label>Zip</label>
			            	<span className="info-element">{order.address.zip ? order.address.zip : ''}</span>
			            </div>
			            <div className="formfield">
			            	<label>Country</label>
			            	<span className="info-element">{order.address.country ? order.address.country : ''}</span>
			            </div>
			            <div className="formfield">
			            	<label>Size</label>
			            	<span className="info-element">{order.tileSize}</span>
			            </div>
			            <div className="formfield">
			            	<label>PAYMENT AMT</label>
			            	<span className="info-element">${order.price.total/100}</span>
			            </div>
		            </div>
              </>              
              : null
            }

            <div className="gallery-holder">
            	<div className="subtitle-bar">
            		<div className="section-subtitle">ORDER IMAGES ({images.length})</div>
          		</div>
            	<PhotoGrid 
            		images={images} 
          		/>
          	</div>

          	{
              loading
              ? <LoadingWheel />
              : null
            }
    			</div>
    		</div>
			</div>
		)
  }
}
