import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import BraintreeDropin from 'braintree-dropin-react';
import braintree from 'braintree-web-drop-in';
import axios from 'axios';
import moment from 'moment';
import LoadingWheel from '../components/LoadingWheel';
import LoadingPanel from '../components/LoadingPanel';
import UploadFailedModal from '../components/UploadFailedModal';
import { upload, createAlbum, parsePresignedUrls } from '../helpers/cropping';
import { getTotalPrice } from '../helpers/pricing';
import { APP_VERSION, API_URL2, BRAINTREE_TOKEN } from '../config.js';
import iconBack from '../img/icon-back.png';

const renderSubmitButton = ({onClick, isDisabled, text}) => {
  	return (
    	<button
      		onClick={onClick}
      		disabled={isDisabled}
      		className="size-x action-button"
    	>{text.toUpperCase()}</button>
  	)
}

class PayScreen extends Component {
	instance;
  
  	constructor() {
	  	super();

	  	this.state = { 
	  		loading: false,
	  		percent: 0,
	  		fail: false,
	  		close: false
	  	};
  	}

	componentDidMount() {
		const { photos, promoCode } = this.props;

		if (photos.length < 2) {
			this.props.history.push('/photos');
		} else if (promoCode && promoCode.type && promoCode.type === 3) {
			this.handlePaymentMethod();
		}

		if (window.ga)
			window.ga('send', 'pageview', '/pay');
		
		document.getElementsByTagName('body')[0].className = 'checkout';
	}

	componentWillUnmount() {
		const body = document.getElementsByTagName('body')[0];
		body.className = body.className.replace('checkout', '');
	}

	setPercent = (step, position = 0) => {
		const { photos } = this.props;

		// 1 - createUser
		// 2 - processPayment
		// 3 - newOrder
		// 4,0 - createAlbum
		// 4,1 - uploadImage (1)
		// 4,x - uploadImage (x)
		// 5 - createShipStationOrder
		// 6 - sendEmails

		const max = photos.length + 6;
		const progress = step + (step < 4 ? 0 : (step > 4 ? photos.length : position)) * 1;
		let percent = Math.round(100 * progress / max);

		this.setState({ percent });
  	}

	handlePaymentMethod = async (payload) => {
		const { photos, address, promoCode } = this.props;
		const password = Math.random().toString(36).substring(2);
		const price = getTotalPrice(photos, address.country, promoCode).toFixed(2).toString().replace('.00', '');
		const regularPrice = getTotalPrice(photos, address.country).toFixed(2).toString().replace('.00', '');
		let client = '';
		const callback = (data) => {
			const { transaction } = data;
			const customerName = address.name;
			const orderName = Math.floor(100 + Math.random() * 900) + '-' + Math.floor(100 + Math.random() * 900) + '-' + Math.floor(1000 + Math.random() * 9000);

		 	axios.post(API_URL2 + '/newOrder', {
				email: address.email, 
				name: customerName, 
				address: address.address,
				zip: address.zip,
				city: address.city,
				state: address.state,
				gift: false,
				promoCode: promoCode && promoCode.code ? promoCode.code : '',
				paymentId: orderName,
				numberOfPictures: photos.length,
				paypalPaymentAmount: price * 100,
				paypalTransactionId: transaction.id,
				paypalPaymentId: ''
			}, { auth: {
			    username: client,
			    password: password
			} }).then(async (response) => {
				this.setPercent(3);
		  		const albumCreated = await createAlbum(client);
		  		this.setPercent(4);
		  		const presignedURLs = response.data.data.presignedURLs;
		  		const urls = parsePresignedUrls(presignedURLs);
		  		const cleanUrls = [];
		  		for (let i in presignedURLs) {
		  			cleanUrls.push(presignedURLs[i].substring(0, presignedURLs[i].indexOf("?")));
		  		}
		  		
		  		if (albumCreated) {
		  			for (let i in photos) {
						const result = await upload(photos[i], urls[i][1] + '/' + urls[i][2], client);

						if (!result) {
							this.setState({ fail: true });
							break;
						}
						
						this.setPercent(4, i*1+1);

						if (i*1 === photos.length - 1) {
							axios.post(API_URL2 + '/createShipStationOrder', {
						   		orderDate: moment().toISOString(),
								customerUsername: address.email,
								customerEmail: address.email,
								paymentDate: moment().toISOString(),
								billTo: {
									postalCode: address.zip,
									state: address.state,
									country: address.country,
									name: customerName,
									street1: address.address,
									city: address.city,
									company: address.company
								},
								orderStatus: 'awaiting_shipment',
								items: [{  
									quantity: 1,
									unitPrice: price,
									name: 'Sweet Pix: ' + orderName,
									Sku: orderName
								}],
								shippingAmount: 0,
								orderNumber: orderName,
								amountPaid: price,
								shipTo: {
									postalCode: address.zip,
									state: address.state,
									country: address.country,
									name: customerName,
									street1: address.address,
									city: address.city,
									company: address.company
								},
								advancedOptions: {
									customField1: photos.length
								}
							}).then((r) => {
								this.setPercent(5);
								axios.post(API_URL2 + '/sendEmails', {
								    imageURLs: cleanUrls,
								    orderNumber: orderName,
								    customerEmail: address.email,
								    regularPrice: '$' + regularPrice,
								    subtotalPrice: '$' + price,
								    shippingPrice: 'FREE',
								    finalPrice: '$' + price,
								    promoCode: promoCode && promoCode.code ? promoCode.code : '',
									promoCodeDiscount: promoCode && promoCode.type && promoCode.amount ? (promoCode.type === 3 ? 'Free Shipping' : (promoCode.type === 2 ? ('-' + promoCode.amount + '%') : ('-$' + promoCode.amount))) : '',
								    shippingUserName: address.name,
								    shippingAddress: address.address,
								    shippingCity: address.city,
								    shippingState: address.state,
								    shippingZip: address.zip,
								    appVersion: 'Web ' + APP_VERSION,
								    paypalTransactionId: transaction.id
								 }).then(() => {
								 	this.setPercent(6);
								 	this.props.history.push('/thanks');
								 }).catch(() => {
								 	alert('Emails sending failed');
								 	// this.props.history.push('/thanks');
								 });
							}).catch(() => {
							 	alert('ShipStation call failed');
							 	this.props.history.push('/thanks');
							 });
						}
		  			}
		  		} else {
		  			alert('Album creation failed');
		  			this.setState({ loading: false });
		  		}
			}).catch(() => {
				this.setState({ loading: false });
				alert('Order creation failed');
			});
		}
   	 	
		this.setState({ loading: true, percent: 0 });

		axios.post(API_URL2 + '/createUser', { password }).then((response) => {
			client = response.data.data.id;
			this.setPercent(1);

			if (payload && payload.nonce) {
				axios.post(API_URL2 + '/processPayment', {
				// axios.post(API_URL2 + '/processPaymentSandbox', {
					nonce: payload.nonce, 
					amount: price
				}).then((response) => {
					const { data } = response.data;
					window.fbq('track', 'Purchase', {value: price, currency: 'USD'});
					window.ga('send', 'event', { eventCategory: 'Payment', eventAction: 'completed' });
					this.setPercent(2);

					if (data && data.success) {
						callback(data);
					} else {
						this.setState({ loading: false });
						alert('Payment failed');
					}
				});
			} else {
				callback({ transaction: { id: 'PAID' } });
			}
  		}).catch(() => {
			this.setState({ loading: false });
			alert('User creation failed');
		});
  	}

  	onError = (error) => {
  		alert(JSON.stringify(error));
  	}

  	onClose = () => {
		this.setState({ close: true });
	}

	render() {
		const { loading, percent, close, fail } = this.state;
  		const { photos, promoCode } = this.props;

  		if (close) {
			return <Redirect to='/photos' />
  		}

    	return (
    		<div className="content content-modal">
    			<div className={ 'photo-grid photo-grid-' + photos.length }>
	    			{
	    				photos.map((photo, i) => {
	    					return (<div className="tile" key={i}>
	    								<div className="photo">
	    									{ photo.loading ? <LoadingWheel /> : <img src={ photo.selection } alt="Tile" /> }
	    								</div>
									</div>)
	    				})
	    			}
	    			<div className="add-more"></div>
				</div>

    			{
    				loading ? 
    				<LoadingPanel percent={percent} /> 
    				: (<div className="global-modal global-modal-checkout" onClick={ this.onClose }>
						<div className="outer" onClick={ (e) => { e.stopPropagation(); } }>
							<div className="inner">
								<Link to="/checkout" className="close">
									<img src={ iconBack } alt="Back" />
								</Link>
								{
									promoCode && promoCode.type && promoCode.type === 3
									? null
									: <BraintreeDropin
						          		braintree={braintree}
							          	options={{
							            	locale: 'en_US',
							            	vaultManager: false
							          	}}
							          	authorizationToken={BRAINTREE_TOKEN}
							          	handlePaymentMethod={this.handlePaymentMethod}
							          	onError={this.onError}
							          	renderSubmitButton={renderSubmitButton}
							        />
								}
							</div>
						</div>
					</div>)
    			}

    			{ fail ? <UploadFailedModal onRetry={ () => this.setState({ fail: false }) } /> : null }
			</div>
		)
  	}
}

function mapStateToProps(state, action) {
  return {
    photos: state.photos,
    address: state.address,
    promoCode: state.promoCode
  }
}
function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PayScreen);
