import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import firebase from '../../firebase'
import moment from 'moment'
import { getConnectedUser, redirectToLogin } from '../../firebaseHelpers'
import Checkbox from '../../components/Checkbox'
import { Eye } from '../../icons'

export default class AccountOrdersScreen extends Component {
  constructor() {
  	super();

  	this.state = { 
  		entries: [],
      loading: false,
      selectedEntries: [],
      user: null
  	};
  }

  componentDidMount = async () => {
    const connectedUser = await getConnectedUser();

    this.setState({ user: connectedUser });

    if (connectedUser) {
      this.loadEntries(connectedUser);
    } else {
      redirectToLogin(this.props.history);
    }
  }

  loadEntries(user=null) {
    if (!user) {
      user = this.state.user;
    }

    this.setState({ loading: true });

    firebase.firestore().collection('orders').where('user_id', '==', user.uid).where('deletedAt', '==', null).where('isProcessed', '==', true).orderBy('createdAt', 'desc').get().then(results => {
      const entries = [];
      results.forEach(result => {
        entries.push(Object.assign({}, result.data(), { id: result.id }));
      });

      this.setState({ entries, loading: false });
    }).catch(error => {
      console.log('error', error);
      this.setState({ loading: false });
      alert(JSON.stringify(error));
    });
  }

  render() {
  	const { entries, loading } = this.state;

    return (
    	<div className="panel-screen">
    		<div className="panel-main justify-top">
    			<div className="table-wrapper">
            <div className="title-bar">
              <div className="section-title">Order History</div>
              <div className="text-button">Export</div>
            </div>

    				<table className="table">
    					<thead>
    						<tr>
	    						<th className="cell-checkbox">
	    							<Checkbox />
	    						</th>
	    						<th>Order #</th>
                  <th>Qty</th>
	    						<th>Date</th>
	    						<th>Size</th>
	    						<th>Total</th>
	    						<th className="cell-action">Actions</th>
    						</tr>
    					</thead>
    					<tbody>
    						{
    							entries.map(entry => <tr key={entry.id}>
  									<td className="cell-checkbox">
  										<Checkbox />
  									</td>
  									<td>
                      <Link to={ '/account/orders/' + entry.id } className="text-button">
                        { entry.id }
                      </Link>
                    </td>
                    <td></td>
  									<td>{ moment(entry.createdAt.seconds * 1000).format('MM/DD/YY') }</td>
  									<td>{ entry.tileSize }</td>
  									<td></td>
  									<td className="cell-action">
                      <Link to={ '/account/orders/' + entry.id } className="text-button">
                        <Eye />
                      </Link>
  									</td>
  								</tr>
    						)}
    					</tbody>
    				</table>

            {
              !loading && entries.length === 0
              ? <div>
                <em>No orders yet</em>
              </div>
              : null
            }
    			</div>
    		</div>
			</div>
		)
  }
}
