import React from 'react';
import { Link } from 'react-router-dom'
import { Close, Image } from '../icons'

export default function EmptyCartWarning() {
	return (
		<div className="modal-overlay">
			<div className="modal-content">
				<Link to="/" className="close-btn">
	  			<Close />
  			</Link>
				<div className="module-inner">
					<figure>
						<svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
							<circle cx="37" cy="37" r="37" fill="#87CEC3"/>
							<path d="M29.9 46.4C28.085 46.4 26.6165 47.885 26.6165 49.7C26.6165 51.515 28.085 53 29.9 53C31.715 53 33.2 51.515 33.2 49.7C33.2 47.885 31.715 46.4 29.9 46.4ZM20 20V23.3H23.3L29.24 35.8235L27.0125 39.866C26.7485 40.328 26.6 40.8725 26.6 41.45C26.6 43.265 28.085 44.75 29.9 44.75H49.7V41.45H30.593C30.362 41.45 30.1805 41.2685 30.1805 41.0375L30.23 40.8395L31.715 38.15H44.0075C45.245 38.15 46.334 37.4735 46.895 36.4505L52.802 25.742C52.934 25.511 53 25.2305 53 24.95C53 24.0425 52.2575 23.3 51.35 23.3H26.9465L25.3955 20H20ZM46.4 46.4C44.585 46.4 43.1165 47.885 43.1165 49.7C43.1165 51.515 44.585 53 46.4 53C48.215 53 49.7 51.515 49.7 49.7C49.7 47.885 48.215 46.4 46.4 46.4Z" fill="#FAFAFA"/>
						</svg>
					</figure>
					<div className="headline">NO IMAGES IN YOUR CART</div>
					<div className="below-headline">You need to upload images before you can checkout.</div>
					<div className="buttons">
						<Link to="/" className="sp-button bordered blue">
							<span className="l-icon">
								<Image />
							</span>
							Add Images
						</Link>
					</div>
					<div className="note">Note: Sweet Pix is not resposible for images that print blurry.</div>
				</div>
			</div>
		</div>
	)
}
