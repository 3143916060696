import React from 'react'
import { Card, Upload } from '../icons'
import LoadingWheel from './LoadingWheel'

export default function InfoModal({ message }) {
	const pieces = message.split('.');
	const headline = pieces[0].trim().toUpperCase();
	const belowHeadline = pieces[1].trim();
	const isPayment = message.toLowerCase().indexOf('payment') !== -1;

	return (
		<div className="modal-overlay">
			<div className="modal-content">
				<div className="module-inner">
					<figure className="module-icon">
						{
							isPayment
							? <Card />
							: <Upload />
						}
					</figure>
					<div className="headline mb-0">{headline}</div>
					<div className="below-headline mt-0 mb-3">{belowHeadline}</div>
					<LoadingWheel width="large" inline={true} />
				</div>
			</div>
		</div>
	)
}
