import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import firebase from '../../firebase'
import { isUserAdmin, redirectToLogin, fetchImageCollections } from '../../firebaseHelpers'
import Checkbox from '../../components/Checkbox'
import LoadingWheel from '../../components/LoadingWheel'
import OrdersTable from '../../components/OrdersTable'
import { Back } from '../../icons'

const updateCustomer = firebase.functions().httpsCallable('updateCustomer');

export default class AdminCustomerScreen extends Component {
	constructor() {
  	super();

  	this.state = { 
      loading: false,
  		orders: [],
      collections: [],
      oldCollectionsSelection: [],
      selectedCollections: [],
      customer: null,
      form: {
        fullName: '',
        email: '',
        companyName: '',
        address: '',
        state: '',
        city: '',
        zip: '',
        country: '',
        unitOrSuite: ''
      }
  	};
  }

  componentDidMount = async () => {
    const isAdmin = await isUserAdmin();

    if (isAdmin) {
      this.loadEntry();

      fetchImageCollections().then(results => {
        const collections = [];
        results.forEach(result => {
            collections.push(Object.assign({}, result.data(), { id: result.id }));
        });
        this.setState({ collections });
      });
    } else {
      redirectToLogin(this.props.history);
    }
  }

  loadEntry = () => {
    const { match } = this.props;

    this.setState({ loading: true });

    firebase.firestore().collection('customers').where('__name__', '==' , match.params.id).get().then(results => {
      results.forEach(result => {
        const data = result.data();
        const address = data.contactInfo && data.contactInfo.addresses && data.contactInfo.addresses.length > 0 ? data.contactInfo.addresses[0] : null;
        const oldCollectionsSelection = [];
        const accessibleImageCollections = data.accessibleImageCollections ? data.accessibleImageCollections : [];

        for (let i in accessibleImageCollections) {
          if (accessibleImageCollections[i]) {
            oldCollectionsSelection.push(i);
          }
        }
        
        this.setState({
          customer: data,
          selectedCollections: Object.values(oldCollectionsSelection),
          oldCollectionsSelection,
          form: {
            fullName: data.fullName,
            email: data.email,
            companyName: address ? address.companyName : '',
            address: address ? address.address : '',
            state: address ? address.state : '',
            city: address ? address.city : '',
            zip: address ? address.zip : '',
            country: address ? address.country : '',
            unitOrSuite: address ? address.unitOrSuite : '',
          },
          loading: false
        });
      });
    }).catch(error => {
      alert(JSON.stringify(error));
      this.setState({
        loading: false
      });
    });
  }

  onUpdateForm = event => {
    const { form } = this.state;
    const { target } = event;
    form[target.name] = target.value;
    this.setState({ form });
  }

  onUpdate = () => {
    const { customer, form, selectedCollections, oldCollectionsSelection } = this.state;
    const { match } = this.props;
    const data = {
      customerId: match.params.id,
      fullName: form.fullName,
      companyName: form.companyName
    };

    if (!customer.contactInfo) {
      customer.contactInfo = {
        address: [],
        contacts: []
      };
    }

    customer.contactInfo.addresses[0] = {
      companyName: form.companyName,
      address: form.address,
      state: form.state,
      city: form.city,
      zip: form.zip,
      country: form.country,
      unitOrSuite: form.unitOrSuite ? form.unitOrSuite : '-'
    };

    data.contactInfo = customer.contactInfo;
    data.addAccessibleImageCollections = selectedCollections;
    data.removeAccessibleImageCollections = oldCollectionsSelection.filter(col => selectedCollections.indexOf(col) === -1);


    this.setState({ loading: true });

    updateCustomer(data).then(result => {
      this.setState({ loading: false });
      this.props.history.push('/admin/customers');
    }).catch(error => {
      this.setState({ loading: false });
      alert(error.message);
    });
  }

  render() {
  	const { loading, customer, collections, selectedCollections, orders, form } = this.state;

  	return (
    	<div className="panel-screen">
    		<div className="panel-main justify-top">
    			<div className="table-wrapper">
    				<div className="admin-action">
    					<Link to="/admin/customers" className="text-button">
    						<Back />
    						Back
  						</Link>
    					<div className="admin-action-buttons">
    						<Link to="/admin/customers" className="sp-button bordered faded">Cancel</Link>
                <button className="sp-button" onClick={ this.onUpdate }>Save</button>
    					</div>
    				</div>
            <div className="section-subtitle">Created</div>
            {
              customer
              ? <div className="section-info">
                { moment(customer.createdAt.seconds * 1000).format('dddd, MMMM d, YYYY') } at { moment(customer.createdAt.seconds * 1000).format('hh:mm:ss A Z') }
              </div>
              : null
            }

            <div className="section-subtitle">CONTACT INFORMATION</div>

            <div className="form-section form-section-cols">
	            <div className="formfield">
	            	<label>Name</label>
                <input type="text" value={ form.fullName } name="fullName" onChange={ this.onUpdateForm } />
	            </div>
	            <div className="formfield">
	            	<label>Email</label>
                <input type="email" value={ form.email } name="email" onChange={ this.onUpdateForm } />
	            </div>
	            <div className="formfield">
	            	<label>Company</label>
	            	<input type="text" value={ form.companyName } name="companyName" onChange={ this.onUpdateForm } />
	            </div>
            </div>

            <div className="section-subtitle">PRIMARY ADDRESS</div>
            <div className="form-section form-section-cols">
                <div className="formfield">
	            	<label>Address</label>
                <input type="text" value={ form.address } name="address" onChange={ this.onUpdateForm } />
	            </div>
	            <div className="formfield">
	            	<label>ZIP</label>
                <input type="text" value={ form.zip } name="zip" onChange={ this.onUpdateForm } />
	            </div>
	            <div className="formfield">
	            	<label>City</label>
                <input type="text" value={ form.city } name="city" onChange={ this.onUpdateForm } />
	            </div>
	            <div className="formfield">
	            	<label>State</label>
                <input type="text" value={ form.state } name="state" onChange={ this.onUpdateForm } />
	            </div>
	            <div className="formfield">
	            	<label>Country</label>
                <input type="text" value={ form.country } name="country" onChange={ this.onUpdateForm } />
	            </div>
              <div className="formfield">
                <label>Unit #</label>
                <input type="text" value={ form.unitOrSuite } name="unitOrSuite" onChange={ this.onUpdateForm } />
              </div>
            </div>

            {
              collections.length > 0
              ? <>
                <div className="section-subtitle">ACCESSIBLE IMAGE COLLECTIONS</div>
                <div className="checkboxes-list">
                  {
                    collections.map(collection => <div key={collection.id}>
                      <Checkbox 
                        checked={ selectedCollections.indexOf(collection.id) !== -1 }
                        label={collection.name} 
                        onChange={() => {
                          if (selectedCollections.indexOf(collection.id) === -1) {
                            selectedCollections.push(collection.id);
                          } else {
                            selectedCollections.splice(selectedCollections.indexOf(collection.id), 1);
                          }

                          this.setState({ selectedCollections });
                        }}
                      />
                    </div>)
                  }
                </div>
              </>
              : null
            }

            {
              orders.length > 0
              ? <>
                <div className="section-subtitle">ORDER ARCHIVE</div>
                <OrdersTable entries={orders} />
              </>
              : null
            }

            {
              loading
              ? <LoadingWheel />
              : null
            }
    			</div>
    		</div>
		</div>
	)
  }
}
