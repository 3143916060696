import React from 'react';
import { Close } from '../icons'
import DragAndDropUploader from './DragAndDropUploader'
import EditorContext from '../contexts/EditorContext'

export default function UploadModal() {
	return (
		<div className="modal-overlay">
			<EditorContext.Consumer>
        {context => (
      		<div className="modal-content modal-content-upload">
						<span className="close-btn" onClick={() => context.setState({ showUploadModal: false, changeImageId: '' })}>
			  			<Close />
		  			</span>
						<div className="module-inner">
							<DragAndDropUploader 
				        isStatic={true} 
				        onUpload={files => {
				        	context.pushImages(files);
				          context.setState({ showUploadModal: false });
				        }}
				      />
						</div>
					</div>
	      )}
      </EditorContext.Consumer>
		</div>
	)
}
