import React, { Component } from 'react'
import firebase from '../../firebase'
import { Link } from 'react-router-dom'
import EditorContext from '../../contexts/EditorContext'
import { isUserAdmin, redirectToLogin, uploadFile, fetchOrganizations } from '../../firebaseHelpers'
import Checkbox from '../../components/Checkbox'
import LoadingWheel from '../../components/LoadingWheel'
import PhotoGrid from '../../components/PhotoGrid'
import DragAndDropUploader from '../../components/DragAndDropUploader'
import { Back } from '../../icons'

const createImageCollection = firebase.functions().httpsCallable('createImageCollection');
const modifyImageCollection = firebase.functions().httpsCallable('modifyImageCollection');
const addImagesToImageCollection = firebase.functions().httpsCallable('addImagesToImageCollection');
const removeImageFromImageCollection = firebase.functions().httpsCallable('removeImageFromImageCollection');
const getImageCollectionMenuIconImageURL = firebase.functions().httpsCallable('getImageCollectionMenuIconImageURL');
const getImageCollectionImageURLs = firebase.functions().httpsCallable('getImageCollectionImageURLs');
const updateMenuIcon = firebase.functions().httpsCallable('updateMenuIcon');
const imagesPerPage = 20;

class AdminImageCollectionScreen extends Component {
	constructor() {
  	super();

  	this.state = {
      isEdit: false,
      loading: false,
      showUploadBox: false,
      currentEntryTitle: '',

      form: {
        isPublic: true,
        name: '',
        menuName: ''
      },

      logo: null,
  		images: [],
      organizations: [],
      currentPage: 1,
      imageCollection: null,
      hasAccess: []
  	};
  }

  componentDidMount = async () => {
    const isAdmin = await isUserAdmin();
    const { match } = this.props;

    if (isAdmin) {
      fetchOrganizations().then(results => {
        const organizations = [];
        results.forEach(result => {
          organizations.push(Object.assign({}, result.data(), { id: result.id, checked: false }));
        });
        this.setState({ organizations });

        if (match.params.id !== 'new') {
          this.setState({
            isEdit: match.params.id,
            images: [],
            loading: true 
          });

          this.loadEntry();
        }
      }).catch(error => {
        alert(JSON.stringify(error));
      });

      const innerElement = document.querySelector('.inner');
      window.onscroll = () => {
        const {
          imageCollection,
          images,
          currentPage,
          loading
        } = this.state;

        if (window.location.hash.indexOf('#/admin/image-collections/') !== -1) {
          if (window.scrollY + window.innerHeight > innerElement.offsetHeight) {
            if (imageCollection && imageCollection.images.length > images.length) {
              if (!loading) {
                this.setState({ currentPage: currentPage+1 });
                this.loadImages(imageCollection.id);
              }
            }
          }
        }
      }
    } else {
      redirectToLogin(this.props.history);
    }
  }

  loadEntry = () => {
    const { match } = this.props;

    firebase.firestore().collection('imageCollections').where('__name__', '==' , match.params.id).get().then(results => {
      results.forEach(result => {
        const { organizations } = this.state;
        const data = Object.assign({}, result.data(), { id: result.id });

        for (let i in organizations) {
          if (data.hasAccess[organizations[i].id]) {
            organizations[i].checked = true;
          }
        }

        this.setState({
          currentEntryTitle: data.name,
          form: {
            name: data.name,
            menuName: data.menuName,
            isPublic: data.isPublic
          },
          organizations,
          imageCollection: data,
          hasAccess: Object.keys(data.hasAccess).filter(id => data.hasAccess[id])
        });

        getImageCollectionMenuIconImageURL({ id: data.id }).then(logo => {
          this.setState({ logo: logo.data });
        }).catch(error => {
          this.setState({ loading: false });
          alert(error.message);
        });

        this.loadImages(data.id);
      });
    }).catch(error => {
      alert(JSON.stringify(error));
      this.setState({
        loading: false
      });
    });
  }

  loadImages = id => {
    const { currentPage, images } = this.state;

    this.setState({ loading: true });

    getImageCollectionImageURLs({ 
      id, 
      quality: 'low',
      skip: (currentPage-1) * imagesPerPage,
      limit: imagesPerPage
    }).then(results => {
      for (let i in results.data) {
        images.push({
          id: i,
          url: results.data[i]
        });
      }

      this.setState({ 
        images,
        loading: false
      });
    }).catch(error => {
      this.setState({ loading: false });
      alert(error.message);
    });
  }

  onUpdateForm = event => {
    const { form } = this.state;
    const { target } = event;
    form[target.name] = target.value;
    this.setState({ form });
  }

  onSave = () => {
    const { form, organizations } = this.state;

    this.setState({ loading: true });

    createImageCollection({
      isPublic: form.isPublic,
      name: form.name,
      menuName: form.menuName,
      hasAccess: organizations.filter(organization => organization.checked).map(organization => organization.id)
    }).then(result => {
      this.setState({ loading: false });
      this.props.history.push('/admin/image-collections');
    }).catch(error => {
      this.setState({ loading: false });
      alert(error.message);
    });
  }

  onUpdate = () => {
    const { form, organizations, isEdit, hasAccess } = this.state;

    this.setState({ loading: true });

    modifyImageCollection({
      ...form,
      id: isEdit,
      addAccess: organizations.filter(organization => organization.checked && hasAccess.indexOf(organization.id) === -1).map(organization => organization.id),
      removeAccess: organizations.filter(organization => !organization.checked && hasAccess.indexOf(organization.id) !== -1).map(organization => organization.id)
    }).then(result => {
      this.setState({ loading: false });
      this.props.history.push('/admin/image-collections');
    }).catch(error => {
      this.setState({ loading: false });
      alert(error.message);
    });
  }

  onUpload = files => {
    const self = this;
    const { isEdit } = this.state;
    this.setState({ loading: true });

    const names = files.map(({name}) => name);

    addImagesToImageCollection({
      id: isEdit,
      names
    }).then(async (result) => {
      for (let i in files) {
        await uploadFile(result.data.images[i].uploadURL, files[i]);
      }

      setTimeout(function(){
        self.loadImages(isEdit);  
      }, 1000);
    }).catch(error => {
      this.setState({ loading: false });
      alert(error.message);
    });
  }

  onAddMore = () => {
    const { isEdit, showUploadBox } = this.state;

    if (!isEdit) {
      alert('Please save the image collection first');
    } else {
      this.setState({ showUploadBox: !showUploadBox })
    }
  }

  render() {
  	const { images, isEdit, loading, form, currentEntryTitle, showUploadBox, organizations, logo, hasAccess } = this.state;

    return (
    	<div className="panel-screen">
    		<div className="panel-main justify-top">
    			<div className="table-wrapper">
    				<div className="admin-action">
    					<Link to="/admin/image-collections" className="text-button">
    						<Back />
    						Back
  						</Link>
    					<div className="admin-action-buttons">
                <Link to="/admin/image-collections" className="sp-button bordered faded">Cancel</Link>
                {
                  !this.context.isOrderSpecificCollection(isEdit)
                  ? <>
                    {
                      isEdit
                      ? <button className="sp-button" onClick={ this.onUpdate }>Save</button>
                      : <button className="sp-button" onClick={ this.onSave }>Save</button>
                    }
                  </>
                  : null
                }
              </div>
    				</div>

            <div className="section-entity">
              {
                isEdit
                ? <>
                  COLLECTION: <strong>{ currentEntryTitle }</strong>
                </>
                : <>NEW COLLECTION</>
              }
            </div>

            <div className="form-section form-section-cols">
              <div className="formfield formfield-inline">
                <Checkbox 
                  label="Public" 
                  checked={form.isPublic} 
                  onChange={() => {
                    form.isPublic = true;
                    this.setState({ form });
                  }}
                />
                <Checkbox 
                  label="Private" 
                  checked={!form.isPublic} 
                  onChange={() => {
                    form.isPublic = false;
                    this.setState({ form });
                  }}
                />
              </div>
	            <div className="formfield">
	            	<label>Name</label>
                <input type="text" value={ form.name } name="name" onChange={ this.onUpdateForm } />
	            </div>
	            <div className="formfield">
	            	<label>Menu name</label>
	            	<input type="text" value={ form.menuName } name="menuName" onChange={ this.onUpdateForm } />
	            </div>
	            <div className="formfield label-aligned">
	            	<label>Has access</label>
                <div className="multi-checkbox">
                  {
                    organizations.map((organization, i) => <Checkbox 
                      key={organization.id}
                      label={organization.businessName} 
                      checked={organization.checked}
                      onChange={event => {
                        organizations[i].checked = !organizations[i].checked;
                        this.setState({ organizations });
                      }} 
                    />)
                  }
                </div>
	            </div>
	            <div className="formfield label-aligned">
	            	<label>Menu icon</label>
	            	<div className="upload-control">
	            		<div className="upload-control-box">
		            		<figure>
                      {
                        logo
                        ? <img src={logo} alt="Collection logo" />
                        : <svg width="45" height="41" viewBox="0 0 45 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M44.7578 36.9033L38.8562 30.9531L43.2356 28.8094C43.5354 28.6647 43.7156 28.3504 43.6954 28.015C43.679 27.6796 43.4577 27.3901 43.1452 27.2823L35.9996 24.804C35.9996 16.3362 35.9996 9.03837 35.9996 0.827628C35.9996 0.368589 35.6302 0 35.1787 0H0.820874C0.365581 0 0 0.368589 0 0.827628C0 10.5752 0 19.8093 0 29.827C0 30.2823 0.365581 30.6546 0.820874 30.6546H28.4966L31.3898 39.1337C31.5005 39.448 31.7883 39.6674 32.1165 39.6885C32.4492 39.7133 32.7609 39.5272 32.9045 39.2249L35.0344 34.8094L40.9323 40.7603C41.0885 40.9133 41.2979 41 41.5154 41C41.5565 41 41.6014 40.9962 41.6425 40.9879C41.6672 40.9834 41.6918 40.9797 41.7165 40.9714C41.7449 40.9668 41.7741 40.9593 41.8025 40.9465C41.8107 40.9465 41.8189 40.9427 41.8272 40.9382C41.9258 40.8967 42.0163 40.8349 42.0941 40.7603L44.7578 38.0747C44.9095 37.9216 45 37.7106 45 37.4912C44.9993 37.2719 44.9095 37.0609 44.7578 36.9033ZM1.64175 1.65526H34.3571V18.4641L25.9143 9.95641C25.6063 9.64586 25.0606 9.64586 24.7525 9.95641L13.3306 21.4686L9.50957 17.6199C9.20156 17.3093 8.6558 17.3093 8.34779 17.6199L1.641 24.3773V1.65526H1.64175ZM1.64175 28.9994V26.72L8.93092 19.3746L12.7519 23.2271C13.06 23.5377 13.6012 23.5377 13.9092 23.2271L25.3319 11.7112L34.3571 20.8068V24.2334L26.8832 21.6389C26.5632 21.5274 26.2223 21.6306 26.013 21.8703C26.0093 21.8703 26.0093 21.8703 26.0048 21.8741C25.779 22.0889 25.6885 22.4243 25.7955 22.7387L27.9299 28.9994H1.64175ZM41.5154 39.0018L35.3798 32.8157C35.1906 32.625 34.9245 32.5428 34.6613 32.5843C34.3989 32.6295 34.1769 32.7991 34.058 33.0395L32.2974 36.7021L29.8677 29.5843C29.8595 29.5557 29.8512 29.5308 29.8393 29.5059L27.8731 23.7374L40.7319 28.1981L37.1038 29.9733C36.8615 30.0893 36.6933 30.317 36.6522 30.5815C36.6074 30.8461 36.6933 31.1152 36.878 31.3059L43.0181 37.492L41.5154 39.0018Z" fill="#BBBBBB"/>
                        </svg>
                      }
										</figure>
										<div>40 x 40px</div>
									</div>
	            		<div className="text-button">Upload</div>
                  <input 
                    type="file" 
                    onChange={event => {
                      if (!isEdit) {
                        alert('Please save the image collection first');
                      } else {
                        const files = Array.from(event.target.files);

                        this.setState({ loading: true });

                        for (let i in files) {
                          const file = files[i];

                          updateMenuIcon({
                            imageCollectionId: isEdit
                          }).then(result => {
                            uploadFile(result.data.uploadURL, file).then(response => {
                              this.setState({ loading: false });
                              window.location.reload();
                            }).catch(error => {
                              alert(JSON.stringify(error));
                            });
                          }).catch(error => {
                            this.setState({ loading: false });
                            alert(error.message);
                          });
                        }
                      }
                    }}
                    accept="image/jpeg, image/png, image/raw" 
                  />
	            	</div>
	            </div>
            </div>

            {
              !this.context.isOrderSpecificCollection(isEdit)
              ? <div className="gallery-holder">
                <div className="subtitle-bar">
                  <div className="section-subtitle">COLLECTION IMAGES</div>
                  <button className="sp-button bordered blue" onClick={ this.onAddMore }>
                    {
                      showUploadBox
                      ? 'Cancel'
                      : '+ Add More'
                    }
                  </button>
                </div>
                {
                  showUploadBox
                  ? <DragAndDropUploader 
                    isStatic={true} 
                    onUpload={this.onUpload}
                  />
                  : null
                }
                <PhotoGrid 
                  images={images} 
                  showLink={true}
                  showDeleteButton={true}
                  onDelete={logo => {
                    const { isEdit } = this.state;

                    this.setState({ loading: true });

                    removeImageFromImageCollection({
                      id: isEdit,
                      imageId: logo.id
                    }).then(result => {
                      this.loadEntry();
                    }).catch(error => {
                      this.setState({ loading: false });
                      alert(error.message);
                    });
                  }}
                />
              </div>
              : null
            }

            {
              loading
              ? <LoadingWheel />
              : null
            }
    			</div>
    		</div>
			</div>
		)
  }
}

AdminImageCollectionScreen.contextType = EditorContext;

export default AdminImageCollectionScreen;
