import React, { FunctionComponent } from 'react'
import { Trash } from '../icons'

type DeleteButtonProps = {
	onClick: any	
}

const DeleteButton: FunctionComponent<DeleteButtonProps> = ({ onClick }: DeleteButtonProps) => <span className="text-button" onClick={() => {
	if (window.confirm('Are you sure?')) {
		onClick();
	}
}}>
        <Trash />
	</span>

export default DeleteButton
