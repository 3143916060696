import React, { FunctionComponent } from 'react'

type CheckboxProps = {
	label?: string,
	checked?: boolean,
	onChange: any
}

const Checkbox: FunctionComponent<CheckboxProps> = ({ label, checked, onChange }: CheckboxProps) => <div className="field-checkbox">
  	<label>
			<input type="checkbox" checked={ checked } onChange={ event => onChange(event) } />
			<span>{ label }</span>
		</label>
	</div>

export default Checkbox
