import React, { Component } from 'react';

export default class UploadFailedModal extends Component {
	render() {
		return (
			<div className="upload-failed">
				<div className="upload-failed-inside">
					<div className="title">Uh oh, upload failed!</div>
					<div className="subtitle">Please try again.</div>
					<button className="action-button" onClick={ this.props.onRetry }>RETRY</button>
					<div className="info">
						There seemed to be a problem while uploading your images.
						Please tap Retry to upload your images again.
					</div>
				</div>
			</div>
		)
  	}
}
