import initialState from './initialState';
import { RECEIVE_ADDRESS } from '../actions/actionTypes';

export default function address(state = initialState.address, action) {
  let newState;

  switch (action.type) {
    case RECEIVE_ADDRESS:
      newState = action.address;

      return newState;

    default:
      return state;
  }
}
